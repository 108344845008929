import React, { useEffect, useState } from 'react'
import {
    ActionBody,
    RenderHeader,
    Columnz,
    DataTablez,
    TimeBody,
    StatusBody,
    useGetParams,
    Dialogz,
    FormUpdate,
    InputForm,
    InputTextareaForm,
    InputSwitchForm,
    DropdownForm,
    HeaderListForm,
} from '@/components'
import { Dropdownz, GridForm, Inputz } from '@/components'
import { status } from '@/constants'
import { Button } from 'primereact/button'
import { useParams } from 'react-router-dom'
import { useCountAsset, useDetailAsset, useListAsset } from '../utils'
import { addAssetApi, deleteAssetApi, updateAssetApi } from '../api'
import { removePropObject } from '@/utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    const listCategoryData = [
        { id: 1, name: 'Thiết bị điện' },
        { id: 2, name: 'Đồ gia dụng' },
        { id: 3, name: 'Phòng cháy chữa cháy' },
        { id: 4, name: 'Đồ điện tử' },
        { id: 5, name: 'Đồ Thông Cống' },
        { id: 6, name: 'Thiết bị thông minh' },
    ]

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập từ khóa"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.category}
                options={listCategoryData}
                onChange={(e) => setFilter({ ...filter, category: e.target.value })}
                placeholder="Chọn danh mục"
            />
        </GridForm>
    )
}

export default function Asset() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListAsset({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountAsset({ status: undefined, ...params, first: undefined }) || 0
    const [visible, setVisible] = useState(false)

    const { id } = useParams()
    const assetDetail = useDetailAsset(id)
    const [infos, setInfos] = useState({
        name: '',
        category: '',
        type_maintain: '',
        time_maintain: '',
        description: '',
    })
    useEffect(() => {
        if (assetDetail.id) setInfos({ ...infos, ...assetDetail, status: assetDetail.status === 0 ? false : true })
    }, [assetDetail])

    const handleData = () => {
        let info = { ...infos }
        if (Number(id)) info = { ...removePropObject(info, assetDetail), id: id }
        return info
    }

    const category = [
        { id: 1, name: 'Đồ gia dụng' },
        { id: 2, name: 'Phòng cháy chữa cháy' },
        { id: 3, name: 'Đồ điện tử ' },
        { id: 4, name: 'Đồ thông cống' },
        { id: 5, name: 'Thiết bị thông minh' },
    ]

    const RenderDialog = () => {
        return (
            <Dialogz title={'Tài sản'} visible={visible} setVisible={setVisible}>
                <FormUpdate
                    checkId={Number(id)}
                    // title="Tài sản"
                    handleData={handleData}
                    route="/asset_management"
                    actions={{ add: addAssetApi, update: updateAssetApi }}
                    refreshObjects={[setInfos]}
                >
                    <InputForm
                        id="name"
                        value={infos.name}
                        onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                        label="Tên tài sản (*)"
                        required
                    />
                    <DropdownForm
                        id="category"
                        value={infos.bankicategoryng_method}
                        optionLabel="name"
                        options={category}
                        onChange={(e) => setInfos({ ...infos, category: e.target.value })}
                        label="Danh mục"
                    />
                    <InputForm
                        id="type_maintain"
                        value={infos.type_maintain}
                        onChange={(e) => setInfos({ ...infos, type_maintain: e.target.value })}
                        label="Kiểu bảo trì"
                    />
                    <InputForm
                        id="time_maintain"
                        value={infos.time_maintain}
                        onChange={(e) => setInfos({ ...infos, time_maintain: e.target.value })}
                        label="Thời gian bảo trì"
                    />
                    <InputTextareaForm
                        id="description"
                        value={infos.description}
                        onChange={(e) => setInfos({ ...infos, description: e.target.value })}
                        label="Ghi chú"
                    />
                </FormUpdate>
            </Dialogz>
        )
    }
    return (
        <>
            <RenderDialog />
            <HeaderListForm title="Thông tin tài sản" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Tài sản"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/asset_management"
                setVisibledDialog={setVisible}
                actionsInfo={{ deleteAction: deleteAssetApi }}
            >
                <Columnz field="asset" header="Tài sản" />
                <Columnz field="categogy" header="Danh mục" />
                <Columnz field="typeMaintain" header="Kiểu bảo trì" />
                <Columnz field="timeMaintain" header="Thời gian bảo trì" />
                <Columnz field="note" header="Ghi chú" />
            </DataTablez>
        </>
    )
}
