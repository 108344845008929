import React, { useState } from 'react'
import {
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    RenderHeader,
    useGetParams,
    ActionBody,
    Dialogz,
    HeaderListForm,
} from '@/components'
import { status } from '@/constants'
import { useCountExcessMoney, useListExcessMoney } from '../utils'
import { TabPanel, TabView } from 'primereact/tabview'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-3">
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn tòa nhà"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn căn hộ"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn dịch vụ"
            />
        </GridForm>
    )
}
export default function ExcessMoney() {
    const initParam = useGetParams()
    const [visible, setVisible] = useState(false)
    const [params, setParams] = useState(initParam)
    const data = useListExcessMoney({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountExcessMoney({ status: undefined, ...params, first: undefined }) || 0
    return (
        <div className="card">
            <Dialogz header="Phân bổ tiền thừa" visible={visible} setVisible={setVisible}></Dialogz>
            <HeaderListForm title="Quản lý tiền thừa" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                route="/excess_money"
                title="tiền thừa"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
            >
                <Columnz field="" header="STT" />
                <Columnz field="" header="Căn hộ" />
                <Columnz field="" header="Mã căn hộ" />
                <Columnz field="" header="Tòa" />
                <Columnz field="" header="Khách hàng" />
                <Columnz field="" header="Tổng" />
                <Columnz field="" header="Dịch vụ" />
                <Columnz field="" header="Tiền thừa hiện tại" />

                <Columnz
                    header="Hành động"
                    body={(e) =>
                        ActionBody({
                            id: e.id,
                            route: '/excess_money',
                            setVisibledDialog: setVisible,
                            params,
                            setParams,
                        })
                    }
                />
            </DataTablez>
        </div>
    )
}
