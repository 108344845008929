import { TabPanel, TabView } from 'primereact/tabview'
import React from 'react'
import ManagementDocs from './ManagementDocs'
import ApartmentDocs from './ApartmentDocs'

export default function DocsManagement() {
    return (
        <div className="card">
            <TabView>
                <TabPanel header="Quản lý tài liệu ban quản lý">
                    <ManagementDocs />
                </TabPanel>
                <TabPanel header="Quản lý tài liệu căn hộ">
                    <ApartmentDocs />
                </TabPanel>
            </TabView>
        </div>
    )
}
