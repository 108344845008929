import React, { useState } from 'react'
import { RenderHeader, Columnz, DataTablez, useGetParams, HeaderListForm } from '@/components'
import { useCountReport, useListReport } from '../utils'
import { Column } from 'primereact/column'
import { Row } from 'primereact/row'
import { ColumnGroup } from 'primereact/columngroup'

export default function Report() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListReport({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountReport({ status: undefined, ...params, first: undefined }) || 0

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header="Thời gian" field="times" rowSpan={2} />
                <Column header="Cổng giám sát" colSpan={4} />
                <Column header="Cổng Kiểm sát" colSpan={4} />
                <Column header="Ghi chú" rowSpan={2} />
                <Column header="Action" rowSpan={2}></Column>
            </Row>
            <Row>
                <Column header="Hình ảnh" />
                <Column header="Loại xe" />
                <Column header="Biển kiểm soát" />
                <Column header="Mã thẻ" />
                <Column header="Hình ảnh" />
                <Column header="Loại xe" />
                <Column header="Biển kiểm soát" />
                <Column header="Mã thẻ" />
            </Row>
        </ColumnGroup>
    )

    return (
        <div className="card">
            <HeaderListForm title="Báo cáo quản lý bãi xe" />
            <DataTablez
                value={data}
                title="bãi xe"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/report"
                headerColumnGroup={headerGroup}
            >
                <Columnz field="times" />
                <Columnz field="" />
                <Columnz field="typeVehicle" />
                <Columnz field="licenseplates" />
                <Columnz field="Idcard" />
                <Columnz field="" />
                <Columnz field="typeVehicle" />
                <Columnz field="licenseplates1" />
                <Columnz field="Idcard" />
                <Columnz field="note" />
            </DataTablez>
        </div>
    )
}
