import React, { useEffect, useState } from 'react'
import {
    RenderHeader,
    TimeBody,
    Columnz,
    StatusBody,
    DataTablez,
    ActionBody,
    Dropdownz,
    GridForm,
    Inputz,
    InputForm,
    FormUpdate,
    Dialogz,
    InputTextareaForm,
    HeaderListForm,
} from '@/components'
import { useGetParams } from '@/components'
import { status } from '@/constants'
import { useCountAssetCategory, useDetailAssetCategory, useListAssetCategory } from '../utils'
import { addAssetCategoryApi, deleteAssetCategoryApi, updateAssetCategoryApi } from '../api'
import { useParams } from 'react-router-dom'
import { removePropObject } from '@/utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập từ khóa"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
        </GridForm>
    )
}

export default function AssetCategory() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListAssetCategory({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountAssetCategory({ status: undefined, ...params, first: undefined }) || 0
    const [visible, setVisible] = useState(false)

    const { id } = useParams()
    const assetCategoryDetail = useDetailAssetCategory(id)
    const [infos, setInfos] = useState({
        name: '',
    })
    useEffect(() => {
        if (assetCategoryDetail.id)
            setInfos({ ...infos, ...assetCategoryDetail, status: assetCategoryDetail.status === 0 ? false : true })
    }, [assetCategoryDetail])

    const handleData = () => {
        let info = { ...infos }
        if (Number(id)) info = { ...removePropObject(info, assetCategoryDetail), id: id }
        return info
    }

    const RenderDialog = () => {
        return (
            <Dialogz title={'Danh mục tài sản'} visible={visible} setVisible={setVisible}>
                <FormUpdate
                    checkId={Number(id)}
                    // title="Danh mục tài sản"
                    handleData={handleData}
                    route="/asset_management"
                    actions={{ add: addAssetCategoryApi, update: updateAssetCategoryApi }}
                    refreshObjects={[setInfos]}
                >
                    <InputForm
                        id="name"
                        value={infos.name}
                        onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                        label="Tên danh mục"
                        required
                        placeholder="Thiết bị điện"
                    />
                </FormUpdate>
            </Dialogz>
        )
    }

    return (
        <>
            <RenderDialog />
            <HeaderListForm title="Danh mục tài sản" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Danh mục tài sản"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/asset_management"
                setVisibledDialog={setVisible}
                actionsInfo={{ deleteAction: deleteAssetCategoryApi }}
            >
                <Columnz field="id" header="ID" />
                <Columnz field="category" header="Danh mục" />
                <Columnz field="note" header="Ghi chú" />
            </DataTablez>
        </>
    )
}
