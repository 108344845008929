import React, { useState } from 'react'
import {
    Calendarz,
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    RenderHeader,
    useGetParams,
    Inputz,
    Dialogz,
    HeaderListForm,
} from '@/components'
import { status } from '@/constants'
import { useCountBankTreasuryBook, useListBankTreasuryBook } from '../utils'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'
import { Column } from 'primereact/column'
import { TabPanel, TabView } from 'primereact/tabview'
import CreateReceipt from '@/modules/debts/customer_list/screens/CreateReceipt'
import { useNavigate } from 'react-router-dom'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz placeholder="Mã chứng từ" type="number" />

            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn tòa nhà"
            />
            <Calendarz
                value={filter.status}
                onChange={(e) => setFilter({ ...filter, status: e.value })}
                className="lg:col-6"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn căn hộ"
            />
        </GridForm>
    )
}
export default function BankTreasuryBook() {
    const navigate = useNavigate()
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListBankTreasuryBook({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountBankTreasuryBook({ status: undefined, ...params, first: undefined }) || 0
    const moreOptions = [
        { label: 'Lập phiếu thu', icon: 'pi pi-plus', command: () => navigate('/') },
        { label: 'Lập phiếu thu khác', icon: 'pi pi-plus', command: () => navigate('/') },
        { label: 'Lập phiếu chi', icon: 'pi pi-plus', command: () => navigate('/') },
        { label: 'Lập phiếu chi khác', icon: 'pi pi-plus', command: () => navigate('/') },
    ]

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header="#" field="" rowSpan={2} />
                <Column header="Căn hộ" field="" rowSpan={2} />
                <Column header="Ngày tháng chứng từ" field="" rowSpan={2} />
                <Column header="Số hiệu chứng từ" colSpan={2} />
                <Column header="Diễn giải" field="" rowSpan={2} />
                <Column header="Số tiền" colSpan={2} />
                <Column header="Người thu" field="" rowSpan={2} />
            </Row>
            <Row>
                <Column header="Thu" />
                <Column header="Chi" />
                <Column header="Biển kiểm soát" />
                <Column header="Mã thẻ" />
            </Row>
        </ColumnGroup>
    )
    return (
        <div className="card">
            <HeaderListForm title="Sổ quỹ ngân hàng" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="sổ quỹ ngân hàng"
                headerColumnGroup={headerGroup}
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/bank_treasury_book"
                headerInfo={{ moreOptions }}
            >
                <Columnz field="" />
                <Columnz field="" />
                <Columnz field="" />
                <Columnz field="" />
                <Columnz field="" />
                <Columnz field="" />
                <Columnz field="" />
                <Columnz field="" />
            </DataTablez>
        </div>
    )
}
