import {
    ActionBody,
    Calendarz,
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    HeaderListForm,
    RenderHeader,
    useGetParams,
} from '@/components'
import { status } from '@/constants'
import { useState } from 'react'
import { deleteRatedServiceApi } from '../api'
import { useCountRatedService, useListRatedService } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-3">
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn trạng thái"
            />
            <Calendarz
                value={filter.status}
                className="lg:col-6"
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                showIcon
            />
        </GridForm>
    )
}

const RatedServices = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListRatedService({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountRatedService({ status: undefined, ...params, first: undefined }) || 0

    return (
        <div className="card">
            <HeaderListForm title="Đánh giá dịch vụ" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Đánh giá"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/rated_service"
                actionsInfo={{ deleteAction: deleteRatedServiceApi }}
            >
                <Columnz field="name" header="Tên tòa nhà" />
                <Columnz field="address" header="Mã" />
                <Columnz field="phone" header="Tình trạng" />
            </DataTablez>
        </div>
    )
}

export default RatedServices
