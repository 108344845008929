import { CalendarForm, Columnz, DataTablez, Dialogz, DropdownForm, Dropdownz, FormUpdate, GridForm, HeaderListForm, InputForm, InputSwitchForm, InputTextareaForm, Inputz, RenderHeader, useGetParams } from '@/components'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { removePropObject } from '@/utils'
import { addTransportationApi, deleteTransportationApi, updateTransportationApi } from '../api'
import { useCountTransportation, useDetailTransportation, useListTransportation } from '../utils'


const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo tên"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />

        </GridForm>
    )
}

const Transportation = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListTransportation({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountTransportation({ status: undefined, ...params, first: undefined }) || 0
    const [visible, setVisible] = useState(false)

    const { id } = useParams()
    const transportationDetail = useDetailTransportation(id)
    const [infos, setInfos] = useState({
        name: '',
        service: '',
        time: '',
        condition: '',
        month_promotion: '',
    })

    useEffect(() => {
        if (transportationDetail.id) setInfos({ ...infos, ...transportationDetail, status: transportationDetail.status === 0 ? false : true })
    }, [transportationDetail])

    const handleData = () => {
        let info = { ...infos }
        if (Number(id)) info = { ...removePropObject(info, transportationDetail), id: id }
        return info
    }

    const RenderDialog = () => {
        return (
            <Dialogz title={'Khuyến Mãi'} visible={visible} setVisible={setVisible}>
                <FormUpdate
                    checkId={Number(id)}
                    // title="Khuyến mãi"
                    handleData={handleData}
                    route="/vehicle_manage"
                    actions={{ add: addTransportationApi, update: updateTransportationApi }}
                    refreshObjects={[setInfos]}
                >
                    <InputForm
                        id="name"
                        value={infos.name}
                        onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                        label="Tên danh mục"
                        required
                    />
                    <InputTextareaForm
                        id="service"
                        value={infos.service}
                        onChange={(e) => setInfos({ ...infos, service: e.target.value })}
                        label="Mô tả"
                        required
                    />
                    <h2>Cấu hình</h2>
                    <DropdownForm
                        id="time"
                        value={infos.time}
                        onChange={(e) => setInfos({ ...infos, time: e.target.value })}
                        showIcon
                        label="Loại giá"
                    />
                    <InputForm
                        id="time"
                        value={infos.time}
                        onChange={(e) => setInfos({ ...infos, time: e.target.value })}
                        showIcon
                        label="Đơn giá"
                    />
                    <DropdownForm
                        id="time"
                        value={infos.time}
                        onChange={(e) => setInfos({ ...infos, time: e.target.value })}
                        showIcon
                        label="Loại xe"
                    />
                    <CalendarForm
                        id="time"
                        value={infos.time}
                        onChange={(e) => setInfos({ ...infos, time: e.target.value })}
                        showIcon
                        label="Ngày áp dụng tính phí"
                    />
                    <InputForm
                        id="time"
                        value={infos.time}
                        onChange={(e) => setInfos({ ...infos, time: e.target.value })}
                        showIcon
                        label="Mã thu"
                    />
                    <InputForm
                        id="time"
                        value={infos.time}
                        onChange={(e) => setInfos({ ...infos, time: e.target.value })}
                        showIcon
                        label="Ngày chuyển đổi"
                    />
                    <InputForm
                        id="time"
                        value={infos.time}
                        onChange={(e) => setInfos({ ...infos, time: e.target.value })}
                        showIcon
                        label="Ngày tính phí dịch vụ"
                    />
                    <InputForm
                        id="time"
                        value={infos.time}
                        onChange={(e) => setInfos({ ...infos, time: e.target.value })}
                        showIcon
                        label="Ngày thanh toán"
                    />
                    <DropdownForm
                        id="time"
                        value={infos.time}
                        onChange={(e) => setInfos({ ...infos, time: e.target.value })}
                        showIcon
                        label="Đối tượng thu phí"
                    />
                    <InputSwitchForm
                        id="time"
                        value={infos.time}
                        onChange={(e) => setInfos({ ...infos, time: e.target.value })}
                        showIcon
                        label="Trạng thái"
                    />

                </FormUpdate>
            </Dialogz>

        )
    }

    return (
        <>
            <RenderDialog />
            <HeaderListForm title="Phương tiện" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Khuyến mãi"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/vehicle_manage"
                setVisibledDialog={setVisible}
                actionsInfo={{ deleteAction: deleteTransportationApi }}
            >
                <Columnz field="name" header="Tên danh mục" />
                <Columnz field="phone" header="Mô tả" />
                <Columnz field="address" header="Trạng thái" />
                <Columnz field="address" header="Ngày bắt đầu tính phí" />
                <Columnz field="address" header="Loại giá" />
            </DataTablez>
        </>
    )
}

export default Transportation
