import {
    Body,
    Columnz,
    DataTablez,
    Dialogz,
    DropdownForm,
    Dropdownz,
    FormUpdate,
    GridForm,
    Inputz,
    useGetParams,
} from '@/components'
import { statusApartment } from '@/constants'
import { useState } from 'react'
import { deleteApartmentApi } from '../api'
import { useCountApartment, useListApartment, useListOwner } from '../utils'
import { getArrId } from '@/modules/permissions/permission/screens/ListUser'
import { useListBuildingV2 } from '../../building_management/utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '', floor: '' })
    const buildings = useListBuildingV2()
    const owners = useListOwner()

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo tên"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Inputz
                value={filter.floor}
                placeholder="Tầng"
                onChange={(e) => setFilter({ ...filter, floor: e.target.value })}
            />
            <Dropdownz
                value={filter.owner_id}
                onChange={(e) => setFilter({ ...filter, owner_id: e.target.value })}
                options={owners}
                optionLabel="full_name"
                placeholder="Chủ hộ"
            />
            <Dropdownz
                value={filter.place}
                onChange={(e) => setFilter({ ...filter, place: e.target.value })}
                options={buildings}
                placeholder="Chọn tòa nhà"
            />
            <Dropdownz
                value={filter.status}
                options={statusApartment}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn trạng thái"
            />
        </GridForm>
    )
}

const ListApartment = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const [visible, setVisible] = useState(false)
    const [selectedProducts, setSelectedProducts] = useState([])
    const data = useListApartment({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountApartment({ status: undefined, ...params, first: undefined }) || 0
    const [buildingId, setBuildingId] = useState(false)

    const items = [
        {
            label: 'Xóa',
            icon: 'pi pi-trash',
            title: 'căn hộ',
            actionType: 'xóa',
            action: async (listId) => await deleteApartmentApi({ listId: getArrId(listId) }),
        },
        {
            label: 'Thêm vào nhóm',
            icon: 'pi pi-plus',
            command: () => setVisible(true),
        },
    ]

    return (
        <>
            <Dialogz header="Thêm vào nhóm" visible={visible} setVisible={setVisible}>
                <FormUpdate
                    checkId={false}
                    handleData={() => {}}
                    actions={{ add: deleteApartmentApi }}
                    setVisible={setVisible}
                    refreshObjects={[setBuildingId]}
                >
                    <DropdownForm
                        id="building_place_id"
                        value={buildingId}
                        options={[]}
                        onChange={(e) => setBuildingId(e.target.value)}
                        label="Chọn nhóm tòa nhà (*)"
                    />
                </FormUpdate>
            </Dialogz>
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="căn hộ"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/apartment_management"
                headerInfo={{ items }}
                actionsInfo={{
                    deleteAction: deleteApartmentApi,
                    handleDelete: (id) => {
                        return { listId: [id] }
                    },
                }}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                hideParams
            >
                <Columnz field="nameApartment" header="Căn hộ" />
                <Columnz field="code" header="Mã căn hộ" />
                <Columnz field="full_name" header="Chủ hộ" />
                <Columnz field="placeName" header="Tòa" />
                <Columnz field="floor" header="Tầng" />
                <Columnz field="user_count" header="Số người" />
                <Columnz field="vehicles_number" header="Số phương tiện" />
                <Columnz field="phone" header="Tập tin đính kèm" />
                <Columnz body={(e) => Body(statusApartment, e.status)} header="Tình trạng" />
                <Columnz field="phone" header="Nhóm căn hộ" />
            </DataTablez>
        </>
    )
}

export default ListApartment
