import { createSlice } from '@reduxjs/toolkit'
export const usersSlice = createSlice({
    name: 'users',
    initialState: { users: [] },
    reducers: {
        setUsers: (state, action) => {
            state.users = action.payload
        },
        clearUsers: (state) => {
            state.users = []
        },
    },
})
export const { setUsers, clearUsers } = usersSlice.actions
export default usersSlice.reducer
