import {
    ActionBody,
    Columnz,
    DataTablez,
    GridForm,
    HeaderListForm,
    Inputz,
    RenderHeader,
    useGetParams,
} from '@/components'
import { useState } from 'react'
import { deleteFormRequestApi } from '../api'
import { useCountFormRequest, useListFormRequest } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo email, số điện thoại"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
        </GridForm>
    )
}

const FormRequests = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListFormRequest({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountFormRequest({ status: undefined, ...params, first: undefined }) || 0

    return (
        <div className="card">
            <HeaderListForm title="Danh sách form yêu cầu" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Yêu cầu"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/form_request"
                actionsInfo={{ deleteAction: deleteFormRequestApi }}
            >
                <Columnz field="name" header="ID" />
                <Columnz field="address" header="Tiêu đề" />
                <Columnz field="address" header="Ý kiến" />
                <Columnz field="address" header="Căn hộ" />
                <Columnz field="address" header="Tầng" />
                <Columnz field="address" header="Tòa nhà" />
                <Columnz field="address" header="Người viết" />
                <Columnz field="phone" header="Trạng thái" />
            </DataTablez>
        </div>
    )
}

export default FormRequests
