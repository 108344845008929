import React, { useState } from 'react'
import { Calendarz, Columnz, DataTablez, Dropdownz, GridForm, HeaderListForm, useGetParams } from '@/components'
import { status, statusDebt } from '@/constants'
import { useCountReceipt, useListReceipt } from '../utils'
import { deleteReceiptApi, updateReceiptApi } from '../api'
import { TabPanel, TabView } from 'primereact/tabview'
import { confirmDialog } from 'primereact/confirmdialog'
import { setToast } from '@/redux/features'
import { listToast } from '@/constants'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn căn hộ"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn tòa nhà"
            />
            <Calendarz
                value={filter.status}
                onChange={(e) => setFilter({ ...filter, status: e.value })}
                className="lg:col-6"
            />
            <Dropdownz
                options={statusDebt}
                value={filter.statusDebt}
                onChange={(e) => setFilter({ ...filter, statusDebt: e.target.value })}
                placeholder="Chọn tình trạng"
            />
        </GridForm>
    )
}
export default function CustomerList() {
    const navigate = useNavigate()
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListReceipt({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountReceipt({ status: undefined, ...params, first: undefined }) || 0
    const [selectedProducts, setSelectedProducts] = useState([])
    const dispatch = useDispatch()
    const items = [
        {
            label: 'Chuyển trạng thái cần xác nhận',
            command: () => confirm(),
        },
        {
            label: 'Chuyển trạng thái chờ gửi',
            command: () => confirm(),
        },
        {
            label: 'Chờ gửi',
            command: () => confirm(),
        },
    ]

    async function accept(status) {
        const response = await updateReceiptApi({})
        if (response.data.status) {
            dispatch(setToast({ ...listToast[0], detail: 'Chuyển trạng thái thành công!' }))
            if (params && setParams) {
                setParams((pre) => {
                    return { ...pre, render: !pre.render }
                })
            }
        } else dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
    }

    const confirm = (status) => {
        if (!(selectedProducts && selectedProducts[0])) {
            dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chọn căn hộ trước khi đổi trạng thái!' }))
            return
        }
        confirmDialog({
            message: 'Bạn có muốn tiếp tục thay đổi trạng thái?',
            header: 'Building Care',
            icon: 'pi pi-info-circle',
            accept: accept(status),
        })
    }
    const moreOptions = [{ label: 'Lập phiếu thu', icon: 'pi pi-plus', command: () => navigate('/') }]

    return (
        <div className="card">
            <HeaderListForm title="Chi tiết bảng kê, khách hàng" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="bảng kê - khách hàng"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/customer_list"
                headerInfo={{ items, moreOptions }}
                actionsInfo={{ deleteAction: deleteReceiptApi }}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
            >
                <Columnz field="" header="Mã BK" />
                <Columnz field="" header="Tên khách hàng" />
                <Columnz field="" header="Kì BK" />
                <Columnz field="" header="Căn Hộ" />
                <Columnz field="" header="Hạn TT" />
                <Columnz field="" header="Nợ Phát Sinh" />
                <Columnz field="" header="Giảm trừ" />
                <Columnz field="" header="Thành tiền" />
                <Columnz field="" header="Đã thanh toán" />
                <Columnz field="" header="Còn nợ" />
                <Columnz field="" header="Ngày lập" />
                <Columnz field="" header="Ngày duyệt" />
                <Columnz field="" header="Trạng thái" />
            </DataTablez>
        </div>
    )
}
