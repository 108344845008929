import {
    ActionBody,
    Calendarz,
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    HeaderListForm,
    Inputz,
    RenderHeader,
    useGetParams,
} from '@/components'
import { status } from '@/constants'
import { useState } from 'react'
import { deletePaymentApi } from '../api'
import { useCountPayment, useListPayment } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-3">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập từ khóa"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn tòa nhà"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn căn hộ"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Kì bảng kê"
            />
            <Calendarz />
            <Calendarz />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Trạng thái"
            />
        </GridForm>
    )
}

const Payments = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListPayment({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountPayment({ status: undefined, ...params, first: undefined }) || 0

    return (
        <div className="card">
            <HeaderListForm title="Danh sách bảng kê" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Bảng kê"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/payment"
                actionsInfo={{ deleteAction: deletePaymentApi }}
            >
                <Columnz field="name" header="Mã BK" />
                <Columnz field="address" header="Tên khách hàng" />
                <Columnz field="phone" header="Kỳ BK" />
                <Columnz field="phone" header="Căn hộ" />
                <Columnz field="phone" header="Hạn TT" />
                <Columnz field="phone" header="Nợ phát sinh" />
                <Columnz field="phone" header="Giảm trừ" />
                <Columnz field="phone" header="Thành tiền" />
                <Columnz field="phone" header="Đã thanh toán" />
                <Columnz field="phone" header="Còn nợ" />
                <Columnz field="phone" header="Ngày lập" />
                <Columnz field="phone" header="Ngày duyệt" />
                <Columnz field="phone" header="Trạng thái" />
                <Columnz field="phone" header="Chi tiết" />
            </DataTablez>
        </div>
    )
}

export default Payments
