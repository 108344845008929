export const listToast = [
    { severity: 'success', summary: 'Successful' },
    { severity: 'error', summary: 'Error' },
    { severity: 'warn', summary: 'Warning' },
    { severity: 'info', summary: 'Info' },
]

export const toolTypes = [
    { name: 'Theo công ty', id: 'company' },
    { name: 'Theo tòa nhà', id: 'building' },
]

export const status = [
    { name: 'Active', id: 1 },
    { name: 'Inactive', id: 0 },
]

export const units = [
    { name: 'Công ty', id: 1 },
    { name: 'Dự án', id: 2 },
    { name: 'Phòng ban', id: 3 },
]

export const statusApartment = [
    { name: 'Để không', id: 0 },
    { name: 'Cho thuê', id: 1 },
    { name: 'Muốn cho thuê', id: 2 },
    { name: 'Đang ở', id: 3 },
    { name: 'Mới bàn giao', id: 4 },
    { name: 'Đang cải tạo', id: 5 },
]

export const statusDebt = [
    { name: 'Trạng thái', id: 1 },
    { name: 'Chờ xác nhận', id: 2 },
    { name: 'Chờ gửi', id: 3 },
    { name: 'Quá hạn', id: 4 },
    { name: 'Đã thanh toán', id: 5 },
    { name: 'Đang thanh toán', id: 6 },
]

export const typeImport = [
    { name: 'Import cư dân', id: 1 },
    { name: 'Import giao dịch việt QR', id: 2 },
]

export const banking = [
    { id: 1, name: 'Việt Nam thịnh vượng' },
    { id: 2, name: 'Đầu tư và phát triển ' },
    { id: 3, name: 'Công thương Việt Name' },
    { id: 4, name: 'Quân Đội' },
    { id: 5, name: 'Kĩ Thương' },
    { id: 6, name: 'NN&PT Nông thôn Việt Nam' },
]

export const statusBuilding = [
    { id: 0, name: 'Đóng' },
    { id: 1, name: 'Mở' },
]

export const relationshipOwner = [
    { id: 0, name: 'Chủ hộ' },
    { id: 1, name: 'Vợ chồng' },
    { id: 2, name: 'Con' },
    { id: 3, name: 'Bố, mẹ' },
    { id: 4, name: 'Anh, chị, em' },
    { id: 5, name: 'Khác' },
    { id: 6, name: 'Khách thuê' },
    { id: 7, name: 'Chủ hộ cũ' },
    { id: 8, name: 'Cháu' },
]

export const genders = [
    { id: 1, name: 'Nam' },
    { id: 2, name: 'Nữ' },
    { id: 3, name: 'Không xác định' },
]
