import React, { useEffect, useState } from 'react'
import {
    RenderHeader,
    TimeBody,
    Columnz,
    StatusBody,
    DataTablez,
    ActionBody,
    Dropdownz,
    GridForm,
    Inputz,
    InputTextareaForm,
    InputForm,
    Dialogz,
    FormUpdate,
    InputSwitchForm,
    CalendarForm,
    HeaderListForm,
} from '@/components'
import { useGetParams } from '@/components'
import { status } from '@/constants'
import { useCountAssetDetail, useDetailAssetDetail, useListAssetDetail } from '../utils'
import { addAssetDetailApi, deleteAssetDetailApi, updateAssetDetailApi } from '../api'
import { useParams } from 'react-router-dom'
import { removePropObject } from '@/utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    const listCategory = [
        { id: 1, name: 'Thiết bị điện' },
        { id: 2, name: 'Đồ gia dụng' },
        { id: 3, name: 'Phòng cháy chữa cháy' },
        { id: 4, name: 'Đồ điện tử' },
        { id: 5, name: 'Đồ Thông Cống' },
        { id: 6, name: 'Thiết bị thông minh' },
    ]

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-3">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập từ khóa"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.area}
                options={listCategory}
                onChange={(e) => setFilter({ ...filter, area: e.target.value })}
                placeholder="Chọn khu vực"
            />
            <Dropdownz
                value={filter.depart}
                options={listCategory}
                onChange={(e) => setFilter({ ...filter, depart: e.target.value })}
                placeholder="Chọn bộ phận"
            />
        </GridForm>
    )
}

export default function AssetDetail() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListAssetDetail({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountAssetDetail({ status: undefined, ...params, first: undefined }) || 0

    const [visible, setVisible] = useState(false)

    const { id } = useParams()
    const assetDetailDetail = useDetailAssetDetail(id)
    const [infos, setInfos] = useState({
        name: '',
        area: '',
        department: '',
        type_maintain: '',
        time_maintain: '',
        last_maintain: '',
        status: true,
        image: '',
        image_detail: '',
    })
    useEffect(() => {
        if (assetDetailDetail.id)
            setInfos({ ...infos, ...assetDetailDetail, status: assetDetailDetail.status === 0 ? false : true })
    }, [assetDetailDetail])

    const handleData = () => {
        let info = { ...infos }
        if (Number(id)) info = { ...removePropObject(info, assetDetailDetail), id: id }
        return info
    }

    const RenderDialog = () => {
        return (
            <Dialogz title={'Chi tiết tài sản'} visible={visible} setVisible={setVisible}>
                <FormUpdate
                    checkId={Number(id)}
                    // title="Tài sản"
                    handleData={handleData}
                    route="/asset_management"
                    actions={{ add: addAssetDetailApi, update: updateAssetDetailApi }}
                    refreshObjects={[setInfos]}
                >
                    <InputForm
                        id="name"
                        value={infos.name}
                        onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                        label="Tên tài sản (*)"
                        required
                    />
                    <InputForm
                        id="name"
                        value={infos.area}
                        onChange={(e) => setInfos({ ...infos, area: e.target.value })}
                        label="Khu vực"
                        required
                    />
                    <InputForm
                        id="name"
                        value={infos.department}
                        onChange={(e) => setInfos({ ...infos, department: e.target.value })}
                        label="Bộ phận"
                        required
                    />
                    <InputForm
                        id="type_maintain"
                        value={infos.type_maintain}
                        onChange={(e) => setInfos({ ...infos, type_maintain: e.target.value })}
                        label="Kiểu bảo trì"
                    />
                    <CalendarForm
                        id="time_maintain"
                        value={infos.time_maintain}
                        onChange={(e) => setInfos({ ...infos, time_maintain: e.target.value })}
                        label="Thời gian bảo trì"
                    />
                    <CalendarForm
                        id="last_maintain"
                        value={infos.last_maintain}
                        onChange={(e) => setInfos({ ...infos, last_maintain: e.target.value })}
                        label="Thời gian bảo trì lần cuối"
                    />
                    <InputSwitchForm
                        id="status"
                        checked={infos.status}
                        onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                        label="Trạng thái"
                    />
                    <InputForm
                        type="file"
                        id="image"
                        value={infos.image}
                        onChange={(e) => setInfos({ ...infos, image: e.target.value })}
                        label="Hình ảnh"
                    />
                    <InputForm
                        id="image_detail"
                        value={infos.image_detail}
                        onChange={(e) => setInfos({ ...infos, image_detail: e.target.value })}
                        label="Chi tiết hình ảnh"
                    />
                </FormUpdate>
            </Dialogz>
        )
    }

    return (
        <>
            <RenderDialog />
            <HeaderListForm title="Thông tin tòa nhà" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Tài sản"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/asset_management"
                setVisibledDialog={setVisible}
                actionsInfo={{ deleteAction: deleteAssetDetailApi }}
            >
                <Columnz field="asset" header="Tài sản" />
                <Columnz field="typeMaintain" header="Kiểu bảo trì" />
                <Columnz field="timeMaintain" header="Thời gian bảo trì" />
                <Columnz field="buildingName" header="Tòa" />
                <Columnz field="floor" header="Tầng" />
                <Columnz field="area" header="Khu vực" />
                <Columnz field="depart" header="Bộ phận" />
                <Columnz field="price" header="Giá" />
                <Columnz field="image" header="Ảnh" />
                <Columnz field="note" header="ghi chú" />
                <Columnz
                    header="Trạng thái"
                    body={(e) =>
                        StatusBody({
                            e,
                            route: '/asset_management',
                            updateAction: updateAssetDetailApi,
                        })
                    }
                />
                <Columnz
                    header="Actions"
                    body={(e) =>
                        ActionBody({
                            setVisibledDialog: setVisible,
                            id: e.id,
                            route: '/asset_management',
                            deleteAction: deleteAssetDetailApi,
                            params,
                            setParams,
                        })
                    }
                />
            </DataTablez>
        </>
    )
}
