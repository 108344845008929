import { useEffect, useState } from 'react'
import {
    listAssetApi,
    countAssetApi,
    detailAssetApi,
    listAssetAreaApi,
    countAssetAreaApi,
    detailAssetAreaApi,
    listAssetCategoryApi,
    countAssetCategoryApi,
    detailAssetCategoryApi,
    listAssetDetailApi,
    countAssetDetailApi,
    detailAssetDetailApi,
} from '../api'

export const useListAsset = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listAssetApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountAsset = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countAssetApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailAsset = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailAssetApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
//============================================================
export const useListAssetArea = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listAssetAreaApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountAssetArea = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countAssetAreaApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailAssetArea = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailAssetAreaApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}

//============================================================
export const useListAssetCategory = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listAssetCategoryApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountAssetCategory = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countAssetCategoryApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailAssetCategory = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailAssetCategoryApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}

//============================================================
export const useListAssetDetail = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listAssetDetailApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountAssetDetail = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countAssetDetailApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailAssetDetail = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailAssetDetailApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
