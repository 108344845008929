import React, { useState } from 'react'
import { Columnz, DataTablez, Dropdownz, GridForm, HeaderListForm, useGetParams } from '@/components'
import { status } from '@/constants'
import { useCountCompany, useListCompany } from '../utils'
import { TabPanel, TabView } from 'primereact/tabview'
import { deleteCompanyApi } from '../api'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-12">
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn tòa nhà"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn căn hộ"
            />

            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Kì bảng kê"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Dịch vụ"
            />
        </GridForm>
    )
}
export default function ExcessMoneyDetails() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListCompany({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountCompany({ status: undefined, ...params, first: undefined }) || 0

    return (
        <div className="card">
            <HeaderListForm title="Chi tiết tiền thừa" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="chi tiết tiền thừa"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/excess_money_details"
                actionsInfo={{ deleteAction: deleteCompanyApi }}
            >
                <Columnz field="" header="STT" />
                <Columnz field="" header="Ngày tạo" />
                <Columnz field="" header="Mã chứng từ" />
                <Columnz field="" header="Khách Hàng" />
                <Columnz field="" header="Căn hộ" />
                <Columnz field="" header="Kỳ" />
                <Columnz field="" header="Dịch vụ" />
                <Columnz field="" header="Diễn giải" />
                <Columnz field="" header="Tăng trong kì" />
                <Columnz field="" header="Giảm trong kỳ" />
            </DataTablez>
        </div>
    )
}
