import React, { useState, Fragment, useEffect, useRef, memo } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Column, DataTable, InputSwitch, SplitButton } from '@/uiCore'
import { confirmDialog } from 'primereact/confirmdialog'
import { setToast } from '@/redux/features/toast'
import { removeUndefinedProps } from '@/utils'
import moment from 'moment'
import { listToast } from '@/constants'

export const Body = (data, value) => {
    let name = ''
    if (data && data[0] && (value || value === 0)) {
        data.forEach((d) => {
            if (d.user_id) {
                if (d.user_id === value) name = d.name || d.full_name
            } else if (d.id === value) name = d.name || d.title || d.text
        })
    }
    return <Fragment>{name}</Fragment>
}

export const TimeBody = (value, type) => {
    if (value && type === 'date') return <Fragment>{moment(value).format('DD/MM/YYYY')}</Fragment>
    if (value) return <Fragment>{moment(value).format('DD/MM/YYYY HH:mm:ss')}</Fragment>
}

export const StatusBody = ({ e, route, updateAction }) => {
    const dispatch = useDispatch()
    const permission = useSelector((state) => state.myTool).myTool
    const [checked, setChecked] = useState(() => Boolean(e.status))
    const accept = async () => {
        const params = { id: e.id, status: checked ? 0 : 1 }
        const response = await updateAction(params)
        if (response.data.status) {
            setChecked(!checked)
            dispatch(setToast({ ...listToast[0], detail: 'Đổi trạng thái thành công!' }))
        } else {
            dispatch(setToast({ ...listToast[1], detail: 'Bạn không có quyền đổi trạng thái!' }))
        }
    }
    const confirm = () => {
        confirmDialog({
            message: 'Bạn có muốn tiếp tục thay đổi trạng thái?',
            header: 'Building Care',
            icon: 'pi pi-info-circle',
            accept,
        })
    }
    return (
        <InputSwitch
            disabled={!updateAction || !route || !permission.includes(route + '/update')}
            checked={checked}
            onChange={confirm}
        />
    )
}

export const Columnz = (props) => {
    const { ...prop } = props
    return <Column {...prop} />
}

export const useGetParams = () => {
    const location = useLocation()
    const params = {}
    params.page = 1
    params.limit = 20
    params.render = false
    const queryParams = new URLSearchParams(location.search)
    for (let [key, value] of queryParams.entries()) {
        params[key] = Number(value) || value
    }
    params.first = (params.page - 1) * params.limit
    return params
}

export const ActionBody = ({ id, route, deleteAction, params, setParams, setVisibledDialog, handleDelete }) => {
    const permission = useSelector((state) => state.myTool).myTool
    const dispatch = useDispatch()
    const [query, setQuery] = useState(null)

    async function accept() {
        const params = handleDelete ? handleDelete(id) : { id }
        const response = await deleteAction(params)
        if (response.data.status) {
            dispatch(setToast({ ...listToast[0], detail: 'Xóa dữ liệu thành công!' }))
            if (params && setParams) {
                setParams((pre) => {
                    return { ...pre, render: !pre.render }
                })
            }
        } else dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
    }

    const confirm = () => {
        confirmDialog({
            message: 'Bạn có muốn tiếp tục xóa dữ liệu này?',
            header: 'Building Care',
            icon: 'pi pi-info-circle',
            accept,
        })
    }

    useEffect(() => {
        if (params) {
            setQuery(
                '?' +
                    new URLSearchParams(
                        removeUndefinedProps({
                            ...params,
                            page: undefined,
                            limit: undefined,
                            render: undefined,
                            first: undefined,
                        }),
                    ).toString(),
            )
        }
    }, [params])

    return (
        <div className="gap-2 flex justify-content-center">
            {route &&
                permission.includes(route + '/detail/:id') &&
                (setVisibledDialog ? (
                    <Button onClick={() => setVisibledDialog(Number(id))} icon="pi pi-eye" rounded outlined />
                ) : (
                    <Link to={route + '/detail/' + id + (query ? query : '')}>
                        <Button icon="pi pi-pencil" severity="info" rounded outlined />
                    </Link>
                ))}
            {route && deleteAction && permission.includes(route + '/delete') && (
                <Button type="button" rounded outlined icon="pi pi-trash" onClick={confirm} severity="danger" />
            )}
        </div>
    )
}

export const RenderHeader = ({ items, route, setVisibledDialog, moreOptions, selectedProducts, params, setParams }) => {
    const dispatch = useDispatch()
    const permission = useSelector((state) => state.myTool).myTool

    async function accept({ action, actionType, title }) {
        const response = (await action(selectedProducts)) || {}
        if (response && response.data && response.data.status) {
            dispatch(setToast({ ...listToast[0], detail: `${actionType} ${title || 'dữ liệu'} thành công!` }))
            if (params && setParams) {
                setParams((pre) => {
                    return { ...pre, render: !pre.render }
                })
            }
        } else dispatch(setToast({ ...listToast[1], detail: response.data && response.data.mess }))
    }

    const confirm = ({ action, actionType, title }) => {
        if (!(selectedProducts && selectedProducts[0])) {
            dispatch(
                setToast({ ...listToast[1], detail: `Vui lòng chọn ${title || 'dữ liệu'} trước khi ${actionType}!` }),
            )
            return
        }
        confirmDialog({
            message: `Bạn có muốn tiếp tục ${actionType} hàng loạt ${title || 'dữ liệu'}?`,
            header: 'Building Care',
            icon: 'pi pi-info-circle',
            accept: () => accept({ action, actionType, title }),
        })
    }

    const [model, setModel] = useState([])
    useEffect(() => {
        if (items && items[0]) {
            return setModel([
                ...items.map((i) => {
                    if (!i.command)
                        return {
                            ...i,
                            command: () => confirm({ action: i.action, title: i.title, actionType: i.actionType }),
                        }
                    else return { ...i }
                }),
            ])
        }
    }, [selectedProducts])

    if (
        (!items &&
            !setVisibledDialog &&
            !moreOptions &&
            route &&
            !permission.includes(route + '/add') &&
            !permission.includes(route + '/import') &&
            !permission.includes(route + '/export')) ||
        !route
    )
        return undefined

    return (
        <div className="flex flex-wrap justify-content-between gap-2 align-items-center">
            <div className="flex gap-2">
                {model && model[0] && <SplitButton model={model} label="Tác vụ" severity="info" raised />}
                {route &&
                    permission.includes(route + '/add') &&
                    (setVisibledDialog ? (
                        <Button
                            onClick={() => setVisibledDialog(true)}
                            icon="pi pi-plus"
                            label="Thêm mới"
                            size="small"
                            severity="info"
                            raised
                        />
                    ) : (
                        <Link to={route + '/add'}>
                            <Button icon="pi pi-plus" severity="info" label="Thêm mới" size="small" raised />
                        </Link>
                    ))}
                {route && permission.includes(route + '/import') && (
                    <Button icon="pi pi-upload" label="Import" size="small" severity="success" raised />
                )}
                {route && permission.includes(route + '/export') && (
                    <Button icon="pi pi-download" label="Export Excel" size="small" severity="success" raised />
                )}
                {moreOptions &&
                    moreOptions[0] &&
                    moreOptions.map((ops, index) => {
                        return (
                            <Button
                                onClick={() => ops.command()}
                                key={index}
                                icon={ops.icon || 'pi pi-download'}
                                label={ops.label || 'Export'}
                                size="small"
                                severity={ops.severity || 'info'}
                                raised
                            />
                        )
                    })}
            </div>
        </div>
    )
}

export const DataTablez = memo((props) => {
    const {
        params,
        setParams,
        totalRecords,
        title,
        dataKey,
        selectedProducts,
        setSelectedProducts,
        route,
        setVisibledDialog,
        headerInfo,
        actionsInfo,
        hideParams,
        ...prop
    } = props
    const { items, moreOptions } = headerInfo || {}
    const { deleteAction, noDialog, handleDelete } = actionsInfo || {}
    const [loading, setLoading] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()

    const onPage = (event) => {
        setParams({
            ...params,
            first: event.first,
            limit: event.rows,
            page: event.page !== 0 ? event.page + 1 : 1,
        })
    }

    const timeoutRef = useRef()
    useEffect(() => {
        loadLazyData()
    }, [params])

    const loadLazyData = () => {
        setLoading(true)
        timeoutRef.current = setTimeout(() => {
            setLoading(false)
        }, 1000)

        return () => {
            clearTimeout(timeoutRef.current)
        }
    }

    useEffect(() => {
        if (hideParams) return
        const query = {}
        for (let key in params) {
            if (params.hasOwnProperty(key)) {
                const value = params[key]
                if (Array.isArray(value) && !value[0]) {
                } else if (!['rows', 'render', 'first'].includes(key) && !['', undefined, null].includes(value))
                    query[key] = value
            }
        }
        navigate(location.pathname + '?' + new URLSearchParams(query).toString())
    }, [params])

    return (
        <DataTable
            lazy
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            paginator
            rowHover
            header={RenderHeader({
                route,
                setVisibledDialog,
                selectedProducts,
                items,
                moreOptions,
                params,
                setParams,
            })}
            first={params.first}
            rows={params.limit}
            totalRecords={totalRecords}
            rowsPerPageOptions={[20, 50, 100]}
            onPage={onPage}
            dataKey={dataKey ? dataKey : 'id'}
            loading={loading}
            showGridlines
            stripedRows
            emptyMessage={'Không tìm thấy ' + title}
            currentPageReportTemplate="Tổng số: {totalRecords} bản ghi"
            selection={selectedProducts}
            onSelectionChange={(e) => setSelectedProducts(e.value)}
            {...prop}
        >
            {selectedProducts && setSelectedProducts && (
                <Columnz selectionMode="multiple" headerStyle={{ width: '3rem' }} />
            )}
            <Columnz header="#" body={(data, options) => options.rowIndex + 1} style={{ width: '1rem' }} />
            {props.children}
            {actionsInfo && (
                <Columnz
                    header="Actions"
                    body={(e) =>
                        ActionBody({
                            route,
                            params,
                            setParams,
                            id: e.id,
                            deleteAction,
                            setVisibledDialog: noDialog ? undefined : setVisibledDialog,
                            handleDelete,
                        })
                    }
                />
            )}
        </DataTable>
    )
})

export const DataTablezV2 = (props) => {
    const { data, title, dataKey, handleDelete, deleteAction, setData, ...prop } = props
    const dispatch = useDispatch()

    async function accept(id) {
        const params = handleDelete ? handleDelete(id) : { id }
        const response = await deleteAction(params)
        if (response.data.status) {
            dispatch(setToast({ ...listToast[0], detail: 'Xóa dữ liệu thành công!' }))
            if (setData) setData(id)
        } else dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
    }

    const confirm = (id) => {
        confirmDialog({
            message: 'Bạn có muốn tiếp tục xóa dữ liệu này?',
            header: 'Building Care',
            icon: 'pi pi-info-circle',
            accept: () => accept(id),
        })
    }

    const ActionBody = (id) => {
        return (
            <Button type="button" rounded outlined icon="pi pi-trash" onClick={() => confirm(id)} severity="danger" />
        )
    }

    return (
        <DataTable
            value={data}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            paginator
            first="0"
            rows="100"
            currentPageReportTemplate="Tổng số: {totalRecords} bản ghi"
            totalRecords={data.length}
            dataKey={dataKey ? dataKey : 'id'}
            stripedRows
            showGridlines
            emptyMessage={'Không tìm thấy ' + title}
            {...prop}
        >
            <Columnz header="#" body={(data, options) => options.rowIndex + 1} style={{ width: '1rem' }} />
            {props.children}
            {deleteAction && <Columnz header="Actions" body={(e) => ActionBody(e.id)} />}
        </DataTable>
    )
}
