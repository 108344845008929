import { useEffect, useState } from 'react'
import { countFormRequestApi, detailFormRequestApi, listFormRequestApi } from '../api'

export const useListFormRequest = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listFormRequestApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountFormRequest = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countFormRequestApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailFormRequest = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailFormRequestApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
