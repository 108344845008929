import {
    Body,
    Columnz,
    DataTablezV2,
    DropdownForm,
    FormUpdate,
    HeaderListForm,
    InputForm,
    InputNumberForm,
    InputTextareaForm,
} from '@/components'
import { removePropObject } from '@/utils'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { addApartmentApi, deleteResidentOfApartmentApi, updateApartmentApi } from '../api'
import { useDetailApartment } from '../utils'
import { relationshipOwner, statusApartment } from '@/constants'
import { removeUndefinedProps } from '@/utils'
import { useListBuildingV2 } from '../../building_management/utils'
import AddResident from '../../resident_mangement/screen/AddResident'
import { Button } from '@/uiCore'

const initInfos = {
    apartment_name: '',
    floor: '',
    area: '',
    description: '',
    code_electric: '',
    code_water: '',
    status: '',
    code: '',
    code_customer: '',
    name_customer: '',
}

const UpdateApartment = () => {
    const { id } = useParams()
    const apartmentDetail = useDetailApartment(id)
    const [infos, setInfos] = useState(initInfos)
    const [dataResident, setDataResident] = useState([])
    const [dataVehicle, setDataVehicle] = useState([])
    const [visible, setVisible] = useState(false)
    const buildings = useListBuildingV2()

    useEffect(() => {
        if (apartmentDetail.detailApartment)
            setInfos({
                ...infos,
                ...removeUndefinedProps(apartmentDetail.detailApartment),
                apartment_name: apartmentDetail.detailApartment.name || '',
            })
        if (apartmentDetail.residential && apartmentDetail.residential[0])
            setDataResident([...apartmentDetail.residential])
        if (apartmentDetail.vehicles && apartmentDetail.vehicles[0]) setDataVehicle([...apartmentDetail.vehicles])
    }, [apartmentDetail])

    const handleData = () => {
        let info = {
            ...infos,
            floor: Number(infos.floor) || undefined,
            area: Number(infos.area) || undefined,
        }
        if (!info.building_place_id) return 'Vui lòng chọn tòa nhà!'
        if (Number(info.area) >= 1000000) return "Vui lòng chọn diện tích nhỏ hơn 1,000,000 m2"
        if (Number(id)) info = { ...removePropObject(info, apartmentDetail.detailApartment), id: id }
        return info
    }

    return (
        <>
            <AddResident visible={visible} setVisible={setVisible} />
            <FormUpdate
                checkId={Number(id)}
                title="căn hộ"
                handleData={handleData}
                route="/apartment_management"
                actions={{ add: addApartmentApi, update: updateApartmentApi }}
                refreshObjects={[setInfos]}
            >
                <div className="card bg-color">
                    <div className="grid grid-form">
                        <div className="col-12 lg:col-6">
                            <InputForm
                                id="apartment_name"
                                value={infos.apartment_name}
                                onChange={(e) => setInfos({ ...infos, apartment_name: e.target.value })}
                                label="Tên căn hộ"
                            />
                            <DropdownForm
                                id="building_place_id"
                                value={infos.building_place_id}
                                options={buildings}
                                onChange={(e) => setInfos({ ...infos, building_place_id: e.target.value })}
                                label="Tòa nhà (*)"
                            />
                            <InputNumberForm
                                id="floor"
                                value={infos.floor}
                                handleOnChange={(e) => setInfos({ ...infos, floor: e })}
                                label="Số tầng (*)"
                                required
                            />
                            <InputNumberForm
                                id="area"
                                value={infos.area}
                                handleOnChange={(e) => setInfos({ ...infos, area: e })}
                                label="Diện tích m2 (*)"
                                required
                            />
                            <InputForm
                                id="code_electric"
                                value={infos.code_electric}
                                onChange={(e) => setInfos({ ...infos, code_electric: e.target.value })}
                                label="Mã công tơ điện"
                            />
                        </div>
                        <div className="col-12 lg:col-6">
                            <InputForm
                                id="code_water"
                                value={infos.code_water}
                                onChange={(e) => setInfos({ ...infos, code_water: e.target.value })}
                                label="Mã công tơ nước"
                            />
                            <DropdownForm
                                id="status"
                                value={infos.status}
                                options={statusApartment}
                                onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                                label="Tình trạng"
                            />
                            <InputForm
                                id="code"
                                value={infos.code}
                                onChange={(e) => setInfos({ ...infos, code: e.target.value })}
                                label="Mã căn"
                            />
                            <InputForm
                                id="code_customer"
                                value={infos.code_customer}
                                onChange={(e) => setInfos({ ...infos, code_customer: e.target.value })}
                                label="Mã khách hàng"
                            />
                            <InputForm
                                id="name_customer"
                                value={infos.name_customer}
                                onChange={(e) => setInfos({ ...infos, name_customer: e.target.value })}
                                label="Tên khách hàng"
                            />
                        </div>
                    </div>
                    <InputTextareaForm
                        id="description"
                        value={infos.description}
                        onChange={(e) => setInfos({ ...infos, description: e.target.value })}
                        label="Mô tả"
                    />
                </div>
                {id && <>
                    <div
                        className="font-left flex align-items-center justify-content-between mb-4"
                        style={{ height: '50px', borderBottom: '2px solid #dee2e6' }}
                    >
                        <b className="text-xl p-0">Cư dân</b>
                        <Button
                            onClick={() => setVisible(true)}
                            icon="pi pi-plus"
                            label="Thêm mới"
                            size="small"
                            severity="info"
                            raised
                            type="button"
                        />
                    </div>             <DataTablezV2
                        title="cư dân"
                        data={dataResident}
                        className="mb-4"
                        setData={(id) => setDataResident((pre) => pre.filter((p) => p.id !== id))}
                        handleDelete={(id_resident) => {
                            return { id_apartment: id, id_resident }
                        }}
                        deleteAction={deleteResidentOfApartmentApi}
                    >
                        <Columnz field="full_name" header="Họ và tên" />
                        <Columnz field="email_contact" header="Email liên hệ" />
                        <Columnz field="phone_contact" header="Mobile" />
                        <Columnz body={(e) => Body(relationshipOwner, e.type)} header="Quan hệ" />
                    </DataTablezV2>
                    <HeaderListForm title="Phương tiện" />
                    <DataTablezV2 title="phương tiện" data={dataVehicle} className="mb-4">
                        <Columnz field="name" header="Tên phương tiện" />
                        <Columnz field="typeVehicle" header="Loại phương tiện" />
                        <Columnz field="number" header="Biển số" />
                        <Columnz field="code" header="Mã thẻ" />
                        <Columnz field="priority_type" header="Mức ưu tiên" />
                        <Columnz field="price" header="Phí" />
                        <Columnz field="description" header="Mô tả" />
                    </DataTablezV2></>}
            </FormUpdate>
        </>
    )
}

export default UpdateApartment
