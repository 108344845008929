import {
    ActionBody,
    Columnz,
    DataTablez,
    Dialogz,
    DropdownForm,
    Dropdownz,
    FormUpdate,
    GridForm,
    HeaderListForm,
    InputForm,
    InputSwitchForm,
    InputTextareaForm,
    Inputz,
    RenderHeader,
    useGetParams,
} from '@/components'
import React, { useEffect, useState } from 'react'
import { useCountPaymentInfo, useDetailPaymentInfo, useListPaymentInfo } from '../utils'
import { useParams } from 'react-router-dom'
import { addPaymentInfoApi, deletePaymentInfoApi, updatePaymentInfoApi } from '../api'
import { removePropObject } from '@/utils'
import { banking } from '@/constants'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    const listCategoryData = [
        { id: 1, name: 'Thiết bị điện' },
        { id: 2, name: 'Đồ gia dụng' },
        { id: 3, name: 'Phòng cháy chữa cháy' },
        { id: 4, name: 'Đồ điện tử' },
        { id: 5, name: 'Đồ Thông Cống' },
        { id: 6, name: 'Thiết bị thông minh' },
    ]

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập từ khóa"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.category}
                options={listCategoryData}
                onChange={(e) => setFilter({ ...filter, category: e.target.value })}
                placeholder="Chọn danh mục"
            />
        </GridForm>
    )
}

export default function PaymentInfo() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListPaymentInfo({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountPaymentInfo({ status: undefined, ...params, first: undefined }) || 0
    const [visible, setVisible] = useState(false)

    const { id } = useParams()
    const paymentInfoDetail = useDetailPaymentInfo(id)
    const [infos, setInfos] = useState({
        account_number: '',
        bank_name: '',
        account_name: '',
        branch: '',
        auto_transaction: true,
        accounting_transction: true,
    })
    useEffect(() => {
        if (paymentInfoDetail.id)
            setInfos({ ...infos, ...paymentInfoDetail, status: paymentInfoDetail.status === 0 ? false : true })
    }, [paymentInfoDetail])

    const handleData = () => {
        let info = { ...infos }
        if (Number(id)) info = { ...removePropObject(info, paymentInfoDetail), id: id }
        return info
    }

    const RenderDialog = () => {
        return (
            <Dialogz title={'Thông tin thanh toán'} visible={visible} setVisible={setVisible}>
                <FormUpdate
                    checkId={Number(id)}
                    // title="Thông tin thanh toán"
                    handleData={handleData}
                    route="/building_overview"
                    actions={{ add: addPaymentInfoApi, update: updatePaymentInfoApi }}
                    refreshObjects={[setInfos]}
                >
                    <InputForm
                        id="account_number"
                        value={infos.account_number}
                        onChange={(e) => setInfos({ ...infos, account_number: e.target.value })}
                        label="Số tài khoản (*)"
                    />
                    <DropdownForm
                        id="bank_name"
                        value={infos.bank_name}
                        optionLabel="name"
                        options={banking}
                        onChange={(e) => setInfos({ ...infos, bank_name: e.target.value })}
                        label="Ngân hàng"
                        placeholder="Ngân hàng"
                    />
                    <InputForm
                        id="account_name"
                        value={infos.account_name}
                        onChange={(e) => setInfos({ ...infos, account_name: e.target.value })}
                        label="Số tài khoản (*)"
                    />
                    <InputForm
                        id="branch"
                        value={infos.branch}
                        onChange={(e) => setInfos({ ...infos, branch: e.target.value })}
                        label="Tên tài khoản"
                    />
                    <InputSwitchForm
                        id="auto_transaction"
                        checked={infos.auto_transaction}
                        onChange={(e) => setInfos({ ...infos, auto_transaction: e.target.value })}
                        label="Nhận giao dịch tự động"
                    />
                    <InputSwitchForm
                        id="accounting_transction"
                        checked={infos.accounting_transction}
                        onChange={(e) => setInfos({ ...infos, accounting_transction: e.target.value })}
                        label="Tự động hạch toán"
                    />
                </FormUpdate>
            </Dialogz>
        )
    }
    return (
        <>
            <RenderDialog />
            <HeaderListForm title="Thông tin thanh toán" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Thanh toán"
                setVisibledDialog={setVisible}
                route="/building_overview"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                actionsInfo={{ deleteAction: deletePaymentInfoApi }}
            >
                <Columnz field="asset" header="Số tài khoản" />
                <Columnz field="categogy" header="Ngân hàng" />
                <Columnz field="typeMaintain" header="Tên Tài khoản " />
                <Columnz field="timeMaintain" header="Chi nhánh" />
                <Columnz field="note" header="Nhận giao dịch tự động" />
                <Columnz field="note" header="Tự động hạch toán" />
                {/* <Columnz
                    header="Actions"
                    body={(e) =>
                        ActionBody({
                            setVisibledDialog: setVisible,
                            id: e.id,
                            route: '/building_overview',
                            deleteAction: deletePaymentInfoApi,
                            params,
                            setParams,
                        })
                    }
                /> */}
            </DataTablez>
        </>
    )
}
