import { DropdownForm, FormUpdate, InputForm } from '@/components'
import { removePropObject } from '@/utils'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { addBuildingServiceApi, updateBuildingServiceApi } from '../api'
import { useDetailBuildingService } from '../utils'

const UpdateBuildingService = () => {
    const { id } = useParams()
    const buildingServiceDetail = useDetailBuildingService(id)
    const [infos, setInfos] = useState({
        nameBuildingServicePlace: '',
        statusBuildingServicePlace: '',
        codeBuildingServicePlace: '',
    })

    useEffect(() => {
        if (buildingServiceDetail.id) setInfos({ ...infos, ...buildingServiceDetail, password: '' })
    }, [buildingServiceDetail])

    const handleData = () => {
        let info = { ...infos, status: infos.status ? 1 : 0 }
        if (Number(id)) info = { ...removePropObject(info, buildingServiceDetail), id: id }
        return info
    }

    return (
        <FormUpdate
            checkId={Number(id)}
            title="tòa nhà"
            handleData={handleData}
            route="/building_service"
            actions={{ add: addBuildingServiceApi, update: updateBuildingServiceApi }}
            refreshObjects={[setInfos]}
        >
            <div className="card bg-color">
                <div style={{ maxWidth: '1200px' }}>
                    <InputForm
                        id="nameBuildingServicePlace"
                        value={infos.nameBuildingServicePlace}
                        onChange={(e) => setInfos({ ...infos, nameBuildingServicePlace: e.target.value })}
                        label="Tên tòa nhà (*)"
                        required
                    />
                    <InputForm
                        id="statusBuildingServicePlace"
                        value={infos.statusBuildingServicePlace}
                        onChange={(e) => setInfos({ ...infos, statusBuildingServicePlace: e.target.value })}
                        label="Địa chỉ (*)"
                        required
                    />
                    <DropdownForm
                        value={infos.codeBuildingServicePlace}
                        onChange={(e) => setInfos({ ...infos, codeBuildingServicePlace: e.target.value })}
                        label="Mã tòa nhà (*)"
                    />
                </div>
            </div>
        </FormUpdate>
    )
}

export default UpdateBuildingService
