import React, { useRef, useState } from 'react'
import {
    Calendarz,
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    RenderHeader,
    TimeBody,
    useGetParams,
    ActionBody,
    Inputz,
    HeaderListForm,
} from '@/components'
import { status } from '@/constants'
import { useCountCompany, useListCompany } from '../utils'
import { deleteCompanyApi, updateCompanyApi } from '../api'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'
import { Column } from 'primereact/column'
const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-12">
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn tòa nhà"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn căn hộ"
            />

            <Calendarz value={filter.status} className="lg:col-3" onChange showIcon placeholder="Từ ngày" />
            <Calendarz value={filter.status} className="lg:col-3" onChange showIcon placeholder="Từ ngày" />
        </GridForm>
    )
}
function MarginReport() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListCompany({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountCompany({ status: undefined, ...params, first: undefined }) || 0
    return (
        <div className="card">
            <HeaderListForm title="Sổ ký quỹ" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                route="/deposit_list"
                title="Sổ ký quỹ"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
            >
                <Columnz field="" header="Căn hộ" />
                <Columnz field="" header="Đã thu" />
                <Columnz field="" header="Đã chi" />
                <Columnz field="" header="Còn nợ" />
                <Columnz field="" header="Mã SP" />
            </DataTablez>
        </div>
    )
}

export default MarginReport
