import { getData, postData } from '@/lib/request'

export const listPromotionApi = (params) => getData('web2/company/getListCompany', params)
export const countPromotionApi = (params) => getData('web2/company/countCompany', params)
export const deletePromotionApi = (params) => postData('web2/company/deleteCompany', params)
export const detailPromotionApi = (params) => getData('web2/company/getDetailCompany', params)
export const addPromotionApi = (params) => postData('/web2/company/addCompany', params)
export const updatePromotionApi = (params) => postData('/web2/company/updateCompany', params)

export const listPromotionApartApi = (params) => getData('web2/company/getListCompany', params)
export const countPromotionApartApi = (params) => getData('web2/company/countCompany', params)
export const deletePromotionApartApi = (params) => postData('web2/company/deleteCompany', params)
export const detailPromotioApartApi = (params) => getData('web2/company/getDetailCompany', params)
export const addPromotionApartApi = (params) => postData('/web2/company/addCompany', params)
export const updatePromotionApartApi = (params) => postData('/web2/company/updateCompany', params)
