import React, { useEffect, useState } from 'react'
import {
    ActionBody,
    Columnz,
    DataTablez,
    Dialogz,
    DropdownForm,
    Dropdownz,
    FormUpdate,
    GridForm,
    HeaderListForm,
    InputForm,
    InputTextareaForm,
    Inputz,
    RenderHeader,
    StatusBody,
    useGetParams,
} from '@/components'
import { useParams } from 'react-router-dom'
import { useCountBuildingInfo, useDetailBuildingInfo, useListBuildingInfo } from '../utils'
import { addBuildingInfoApi, deleteBuildingInfoApi, updateBuildingInfoApi } from '../api'
import { removePropObject } from '@/utils'
import { banking } from '@/constants'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    const listCategoryData = [
        { id: 1, name: 'Thiết bị điện' },
        { id: 2, name: 'Đồ gia dụng' },
        { id: 3, name: 'Phòng cháy chữa cháy' },
        { id: 4, name: 'Đồ điện tử' },
        { id: 5, name: 'Đồ Thông Cống' },
        { id: 6, name: 'Thiết bị thông minh' },
    ]

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập từ khóa"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.category}
                options={listCategoryData}
                onChange={(e) => setFilter({ ...filter, category: e.target.value })}
                placeholder="Chọn danh mục"
            />
        </GridForm>
    )
}

export default function BuildingInfo() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListBuildingInfo({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountBuildingInfo({ status: undefined, ...params, first: undefined }) || 0
    const [visible, setVisible] = useState(false)

    const { id } = useParams()
    const buildingInfoDetail = useDetailBuildingInfo(id)
    const [infos, setInfos] = useState({
        name: '',
        description: '',
        address: '',
        money: '',
        mobile: '',
        email: '',
        receive_supervision: '',
        template_email: '',
        hashkey: '',
    })
    useEffect(() => {
        if (buildingInfoDetail.id)
            setInfos({ ...infos, ...buildingInfoDetail, status: buildingInfoDetail.status === 0 ? false : true })
    }, [buildingInfoDetail])

    const handleData = () => {
        let info = { ...infos }
        if (Number(id)) info = { ...removePropObject(info, buildingInfoDetail), id: id }
        return info
    }

    const supervisor = [
        { id: 1, name: 'Bộ phận kĩ thuật' },
        { id: 2, name: 'Bộ phận lễ tân' },
        { id: 3, name: 'Bộ phận an ninh' },
        { id: 4, name: 'Bộ phận vệ sinh' },
        { id: 5, name: 'Trưởng ban quản lý' },
    ]

    const RenderDialog = () => {
        return (
            <Dialogz title={'Thêm thông tin'} visible={visible} setVisible={setVisible}>
                <FormUpdate
                    checkId={Number(id)}
                    handleData={handleData}
                    route="/building_overview"
                    actions={{ add: addBuildingInfoApi, update: updateBuildingInfoApi }}
                    refreshObjects={[setInfos]}
                >
                    <InputForm
                        id="name"
                        value={infos.name}
                        onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                        label="Tên tòa nhà (*)"
                        required
                    />
                    <InputTextareaForm
                        id="description"
                        value={infos.description}
                        onChange={(e) => setInfos({ ...infos, description: e.target.value })}
                        label="Mô tả"
                    />
                    <InputForm
                        id="address"
                        value={infos.address}
                        onChange={(e) => setInfos({ ...infos, address: e.target.value })}
                        label="Địa chỉ"
                    />
                    <InputForm
                        id="money"
                        value={infos.money}
                        onChange={(e) => setInfos({ ...infos, money: e.target.value })}
                        label="Đóng tiền mặt"
                    />
                    <InputForm
                        id="mobile"
                        value={infos.type_maintain}
                        onChange={(e) => setInfos({ ...infos, mobile: e.target.value })}
                        label="Mobile"
                    />
                    <InputForm
                        id="email"
                        value={infos.type_maintain}
                        onChange={(e) => setInfos({ ...infos, email: e.target.value })}
                        label="Email"
                    />
                    <DropdownForm
                        id="receive_supervision"
                        value={infos.receive_supervision}
                        optionLabel="name"
                        options={supervisor}
                        onChange={(e) => setInfos({ ...infos, receive_supervision: e.target.value })}
                        label="Bộ phận giám sát"
                    />
                    <InputForm
                        id="template_email"
                        value={infos.template_email}
                        onChange={(e) => setInfos({ ...infos, template_email: e.target.value })}
                        label="Template Email"
                    />
                    <InputForm
                        id="hashkey"
                        value={infos.hashkey}
                        onChange={(e) => setInfos({ ...infos, hashkey: e.target.value })}
                        label="Hash Key"
                    />
                </FormUpdate>
            </Dialogz>
        )
    }
    return (
        <>
            <RenderDialog />
            <HeaderListForm title="Thông tin tòa nhà" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Tòa nhà"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/building_overview"
                setVisibledDialog={setVisible}
                actionsInfo={{ deleteAction: deleteBuildingInfoApi }}
            >
                <Columnz field="title" header="Nội dung" />
                <Columnz field="numbers" header="Số lượng" />
                <Columnz field="note" header="Ghi chú" />
                <Columnz
                    header="Trạng thái"
                    body={(e) =>
                        StatusBody({
                            e,
                            route: '/building_overview',
                            updateAction: updateBuildingInfoApi,
                        })
                    }
                />
            </DataTablez>
        </>
    )
}
