import { CalendarForm, Dialogz, DropdownForm, FormUpdate, InputForm, MultiRadioButton } from '@/components'
import { useState } from 'react'
import { addOldResidentApi, addResidentApi, checkResidentApi } from '../api'
import { genders, relationshipOwner } from '@/constants'
import { useListApartmentV2 } from '../../apartment_management/utils'
import { Button } from '@/uiCore'
import { Link } from 'react-router-dom'
import { listToast } from '@/constants'
import { setToast } from '@/redux/features'
import { useDispatch } from 'react-redux'
import { databaseDate, validate } from '@/lib'
const initInfos = { name: '', phone: '', email: '', pword: '', phone_contact: '', email_contact: '' }

const CheckResident = ({ setVisible, setResident }) => {
    const dispatch = useDispatch()
    const [keySearch, setKeySearch] = useState('')

    async function fetchDataSubmit(obj) {
        const response = await checkResidentApi({ key_search: keySearch })
        if (response.data.status) {
            const data = response.data.data
            if (data.id) setResident({ ...data, type: 3 })
            else setResident({ type: 2, ...obj })
        } else dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const obj = {}
        if (validate('email', keySearch)) obj.email_contact = keySearch
        else if (validate('phone', keySearch)) obj.phone_contact = keySearch
        else {
            dispatch(
                setToast({ ...listToast[1], detail: 'Dữ liệu nhập không đúng định dạng số điện thoại hoặc email!' }),
            )
            return
        }
        fetchDataSubmit(obj)
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="card bg-color mt-4">
                <InputForm
                    id="key_search"
                    value={keySearch}
                    onChange={(e) => setKeySearch(e.target.value)}
                    label="Tìm theo số điện thoại, email, cmt"
                    required
                />
                <Link onClick={() => setResident({ type: 1 })} className="px-2">
                    <b>(*) Trường hợp cư dân chưa có số điện thoại, email, cmt</b>
                </Link>
            </div>
            <div className="justify-content-end flex gap-3">
                <Button
                    type="button"
                    raised
                    size="small"
                    severity="danger"
                    label="Hủy"
                    icon="pi pi-times"
                    onClick={() => {
                        setVisible(false)
                        setResident({ type: '' })
                    }}
                />
                <Button type="submit" raised size="small" icon="pi pi-search" severity="info" label="Tìm" />
            </div>
        </form>
    )
}

const ResidentInfo = ({ setVisible, resident, setResident, setParams }) => {
    const [infos, setInfos] = useState(() => {
        if (resident) return { ...initInfos, ...resident, type: undefined, name: resident.full_name || '', birthday: resident.birthday ? new Date(resident.birthday) : ''}
        else return { ...initInfos }
    })
    const apartments = useListApartmentV2()

    const handleData = () => {
        if (!infos.gender) return "Vui lòng chọn giới tính!"
        if (!infos.birthday) return "Vui lòng chọn ngày sinh!"
        if (!infos.apartment_id) return "Vui lòng chọn căn hộ!"
        if (!(infos.type || infos.type === 0)) return "Vui lòng chọn quan hệ với chủ hộ!"
        let info = { ...infos, birthday: databaseDate(infos.birthday), email: infos.email_contact, phone: infos.phone_contact }
        if (resident.type === 3) info = { user_id: resident.user_id, apartment_id: infos.apartment_id, type: infos.type }
        return info
    }

    return (
        <FormUpdate
            checkId={resident.type === 3}
            handleData={handleData}
            actions={{ add: addResidentApi, update: addOldResidentApi }}
            setVisible={setVisible}
            setParams={setParams}
            refreshObjects={[setInfos, setResident]}
        >
            <div className="card bg-color mt-4">
                <div className="grid grid-form">
                    <div className="col-12 lg:col-6">
                        <InputForm
                            id="name"
                            value={infos.name}
                            onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                            label="Tên dân cư (*)"
                            required
                            disabled={resident.type === 3}
                        />
                        <CalendarForm
                            value={infos.birthday}
                            onChange={(e) => setInfos({ ...infos, birthday: e.target.value })}
                            label="Ngày sinh"
                            disabled={resident.type === 3}
                        />
                        {[2, 3].includes(resident.type) && (
                            <InputForm
                                id="email_contact"
                                value={infos.email_contact}
                                onChange={(e) => setInfos({ ...infos, email_contact: e.target.value })}
                                label="Email liên hệ"
                                disabled={resident.type === 3}
                            />
                        )}
                        <MultiRadioButton
                            id="gender"
                            value={infos.gender}
                            onChange={(e) => setInfos({ ...infos, gender: e })}
                            options={genders}
                            className="w-full"
                            label="Giới tính"
                            disabled={resident.type === 3}
                        />
                    </div>
                    <div className="col-12 lg:col-6">
                        <DropdownForm
                            id="apartment_id"
                            value={infos.apartment_id}
                            onChange={(e) => setInfos({ ...infos, apartment_id: e.target.value })}
                            label="Chọn căn hộ (*)"
                            options={apartments}
                        />
                        <DropdownForm
                            id="type"
                            value={infos.type}
                            onChange={(e) => setInfos({ ...infos, type: e.target.value })}
                            label="Quan hệ với chủ hộ (*)"
                            options={relationshipOwner}
                        />
                        {[2, 3].includes(resident.type) && (
                            <InputForm
                                id="phone_contact"
                                value={infos.phone_contact}
                                onChange={(e) => setInfos({ ...infos, phone_contact: e.target.value })}
                                type="phone"
                                label="Số điện thoại liên hệ"
                                disabled={resident.type === 3}
                            />
                        )}
                    </div>
                </div>
            </div>
            {resident.type === 2 && (
                <div className="card bg-color mt-4">
                    <div className="grid grid-form">
                        <div className="col-12 lg:col-6">
                            <InputForm
                                value={infos.email_contact}
                                onChange={(e) => setInfos({ ...infos, email_contact: e.target.value })}
                                label="Email (*)"
                            />
                            <InputForm
                                value={infos.phone_contact}
                                onChange={(e) => setInfos({ ...infos, phone_contact: e.target.value })}
                                label="Số điện thoại (*)"
                            />
                        </div>
                        <div className="col-12 lg:col-6">
                            <InputForm
                                id="pword"
                                value={infos.pword}
                                onChange={(e) => setInfos({ ...infos, pword: e.target.value })}
                                label="Mật khẩu"
                                type="password"
                            />
                        </div>
                    </div>
                    <a className="px-2">
                        <b>(*) Hệ thống sẽ gửi thông tin tài khoản, căn hộ đến email cho cư dân.</b>
                    </a>
                </div>
            )}
        </FormUpdate>
    )
}

const AddResident = ({ visible, setVisible, setParams }) => {
    const [resident, setResident] = useState({ type: '' })

    return (
        <Dialogz
            header={resident.type ? 'Thêm mới cư dân' : 'Nhập thông tin cư dân'}
            visible={visible}
            setVisible={setVisible}
        >
            {resident.type ? (
                <ResidentInfo setParams={setParams} resident={resident} setResident={setResident} setVisible={setVisible} />
            ) : (
                <CheckResident setResident={setResident} setVisible={setVisible} />
            )}
        </Dialogz>
    )
}

export default AddResident
