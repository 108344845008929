import { useEffect, useState } from 'react'
import { countDataReviewApi, detailDataReviewApi, listDataReviewApi } from '../api'

export const useListDataReview = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listDataReviewApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountDataReview = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countDataReviewApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailDataReview = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailDataReviewApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
