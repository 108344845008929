import { Body, Columnz, DataTablez, Dropdownz, GridForm, Inputz, useGetParams } from '@/components'
import { status, statusBuilding } from '@/constants'
import { useState } from 'react'
import { deleteBuildingApi } from '../api'
import { useCountBuilding, useListBuilding } from '../utils'
import { TabPanel, TabView } from 'primereact/tabview'
import Floors from './Floor'
import { getArrId } from '@/modules/permissions/permission/screens/ListUser'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ name: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
            <Inputz
                value={filter.name}
                placeholder="Tìm kiếm theo tên tòa nhà"
                onChange={(e) => setFilter({ ...filter, name: e.target.value })}
            />
            <Dropdownz
                value={filter.status}
                options={statusBuilding}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn trạng thái"
            />
        </GridForm>
    )
}

const Buildings = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListBuilding({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountBuilding({ status: undefined, ...params, first: undefined }) || 0
    const [selectedProducts, setSelectedProducts] = useState([])

    const items = [
        {
            label: 'Xóa',
            icon: 'pi pi-trash',
            title: 'tòa nhà',
            actionType: 'xóa',
            action: async (listId) => await deleteBuildingApi({ listId: getArrId(listId) }),
        },
    ]

    return (
        <div className="card">
            <TabView>
                <TabPanel header="Quản lý tòa nhà">
                    <Header setParams={setParams} />
                    <DataTablez
                        value={data}
                        title="tòa nhà"
                        totalRecords={totalRecords}
                        params={params}
                        setParams={setParams}
                        route="/building_management"
                        headerInfo={{ items }}
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                        actionsInfo={{ deleteAction: deleteBuildingApi }}
                    >
                        <Columnz field="name" header="Tên tòa nhà" />
                        <Columnz field="code" header="Mã" />
                        <Columnz body={(e) => Body(statusBuilding, e.status)} header="Tình trạng" />
                    </DataTablez>
                </TabPanel>
                <TabPanel header="Quản lý tầng">
                    <Floors />
                </TabPanel>
            </TabView>
        </div>
    )
}

export default Buildings
