import { FormAuth, FormInput } from '@/components'
import { listToast } from '@/constants'
import { validate } from '@/lib'
import { setPasswordAPI } from '@/modules/auth/api'
import { setToast } from '@/redux/features'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const SetPassword = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const input = localStorage.getItem('emailReset')
    console.log(input, 1234);
    const [loading, setLoading] = useState(false)
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [otp, setOTP] = useState('')
    const [inputFormat, setInputFormat] = useState('')

    useEffect(() => {
        if (validate('phone', input)) {
            setInputFormat('phone')
        } else if (!validate('email', input)) {
            setInputFormat('email')
        }
    }, [input])

    async function fetchData() {
        const response = await setPasswordAPI({ key_search: input, otp: otp, newPassword: newPassword, confirmPassword: confirmPassword })
        console.log(response);
        if (response) setLoading(false)
        if (response.data.status) {
            localStorage.removeItem('emailReset')
            dispatch(setToast({ ...listToast[0], detail: 'Đổi mật khẩu thành công, vui lòng đăng nhập lại!' }))
            navigate('/auth/login')
        } else dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        fetchData()
    }

    return (
        <FormAuth
            title="Đặt mật khẩu mới"
            subtitle="Go back"
            handleSubmit={handleSubmit}
            linkSubtitle="/auth/forgotpassword"
            loading={loading}
            disabled={!newPassword}
        >
            <FormInput id="email" label={inputFormat === 'phone' ? 'Phone' : 'Email'} disabled value={input} />
            <FormInput
                id="otp"
                label="OTP"
                value={otp}
                type="number"
                onChange={(e) => setOTP(e.target.value)}
            />
            <FormInput
                id="newPassword"
                label="New password"
                value={newPassword}
                type="password"
                onChange={(e) => setNewPassword(e.target.value)}
            />
            <FormInput
                id="newPassword"
                label="Confirm password"
                value={confirmPassword}
                type="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
            />
        </FormAuth>
    )
}

export default SetPassword
