import { Columnz, DataTablez, Dropdownz, GridForm, HeaderListForm, StatusBody, useGetParams } from '@/components'
import { useState } from 'react'
import { deleteImportApi, updateImportApi } from '../api'
import { useCountImport, useListImport } from '../utils'
import { typeImport } from '@/constants'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Dropdownz
                value={filter.key_search}
                placeholder="Loại import"
                options={typeImport}
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
        </GridForm>
    )
}

const Imports = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListImport({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountImport({ status: undefined, ...params, first: undefined }) || 0

    return (
        <div className="card">
            <HeaderListForm title="Lịch sử import" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="import"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/import_history"
                actionsInfo={{ deleteAction: deleteImportApi }}
            >
                <Columnz field="name" header="Loại Import" />
                <Columnz field="address" header="Data" />
                <Columnz field="phone" header="Ghi chú" />
                <Columnz field="email" header="Người tạo" />
                <Columnz
                    header="Trạng thái"
                    body={(e) => StatusBody({ e, route: '/import_history', updateAction: updateImportApi })}
                />
            </DataTablez>
        </div>
    )
}

export default Imports
