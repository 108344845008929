import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDetailCompany } from '../utils'
import { updateCompanyApi, addCompanyApi } from '../api'
import { removePropObject } from '@/utils'
import { InputForm, FormUpdate, InputTextareaForm, InputSwitchForm } from '@/components'
import { removeUndefinedProps } from '@/utils'

const initInfos = {
    name: '',
    address: '',
    email: '',
    phone_number: '',
    description: '',
    code: '',
    status: true,
}

const UpdateCompany = () => {
    const { id } = useParams()
    const companyDetail = useDetailCompany(id)
    const [infos, setInfos] = useState(initInfos)
    useEffect(() => {
        if (companyDetail.id)
            setInfos({ ...infos, ...removeUndefinedProps(companyDetail), status: Number(companyDetail.status) !== 0 })
    }, [companyDetail])

    const handleData = () => {
        let info = { ...infos }
        if (Number(id)) info = { ...removePropObject(info, companyDetail), id: id }
        return info
    }

    return (
        <FormUpdate
            checkId={Number(id)}
            title="công ty"
            handleData={handleData}
            route="/company"
            actions={{ add: addCompanyApi, update: updateCompanyApi }}
            refreshObjects={[setInfos]}
        >
            <div className="card bg-color">
                <div className="grid grid-form">
                    <div className="col-12 lg:col-6">
                        <InputForm
                            id="name"
                            value={infos.name}
                            onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                            label="Tên công ty (*)"
                            required
                        />
                        <InputForm
                            id="address"
                            value={infos.address}
                            onChange={(e) => setInfos({ ...infos, address: e.target.value })}
                            label="Địa chỉ"
                        />
                        <InputForm
                            id="code"
                            value={infos.code}
                            onChange={(e) => setInfos({ ...infos, code: e.target.value })}
                            label="Mã công ty"
                            disabled
                        />
                    </div>
                    <div className="col-12 lg:col-6">
                        <InputForm
                            id="email"
                            value={infos.email}
                            onChange={(e) => setInfos({ ...infos, email: e.target.value })}
                            label="Email"
                        />
                        <InputForm
                            id="phone_number"
                            value={infos.phone_number}
                            onChange={(e) => setInfos({ ...infos, phone_number: e.target.value })}
                            label="Số điện thoại"
                        />
                        <InputSwitchForm
                            id="status"
                            checked={infos.status}
                            onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                        />
                    </div>
                </div>
                <InputTextareaForm
                    id="description"
                    value={infos.description}
                    onChange={(e) => setInfos({ ...infos, description: e.target.value })}
                    label="Ghi chú"
                />
            </div>
        </FormUpdate>
    )
}

export default UpdateCompany
