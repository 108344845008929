import { ActionBody, Columnz, DataTablez, Dropdownz, GridForm, HeaderListForm, RenderHeader, useGetParams } from '@/components'
import { status } from '@/constants'
import { useState } from 'react'
import { deleteElectricWaterApi } from '../api'
import { useCountElectricWater, useListElectricWater } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-12">
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn tòa nhà"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Căn hộ"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Tháng chốt sổ"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Loại"
            />
        </GridForm>
    )
}

const ListElectricWater = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListElectricWater({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountElectricWater({ status: undefined, ...params, first: undefined }) || 0
    const items = [
        {
            label: 'Xóa',
            icon: 'pi pi-trash',
            command: () => { },
        },
        {
            label: 'Tải xuống',
            icon: 'pi pi-cloud-download',
            command: () => { },
        },
    ]

    return (
        <div className="card">
            <HeaderListForm title="Danh sách Chỉ số điện nước" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Tòa nhà"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/electric_water"
                actionsInfo={{ deleteAction: deleteElectricWaterApi }}
            >
                <Columnz field="name" header="Căn hộ" />
                <Columnz field="address" header="Mã căn hộ" />
                <Columnz field="email" header="Dịch vụ" />
                <Columnz field="phone" header="Chỉ số đầu" />
                <Columnz field="phone" header="Chỉ số cuối" />
                <Columnz field="phone" header="Tiêu thụ" />
                <Columnz field="phone" header="Hình thức" />
                <Columnz field="phone" header="Ngày chốt sổ" />
                <Columnz field="phone" header="Hình ảnh" />
                <Columnz field="phone" header="Người tạo" />
            </DataTablez>
        </div>
    )
}

export default ListElectricWater
