import { DropdownForm, FormUpdate, InputForm, InputTextareaForm } from '@/components'
import { removePropObject } from '@/utils'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { addImportApi, updateImportApi } from '../api'
import { useDetailImport } from '../utils'

const UpdateImport = () => {
    const { id } = useParams()
    const importDetail = useDetailImport(id)
    const [infos, setInfos] = useState({ name: '', phone: '', email: '', address: '', note: '', status: true })

    useEffect(() => {
        if (importDetail.id) setInfos({ ...infos, ...importDetail, password: '' })
    }, [importDetail])

    const handleData = () => {
        let info = { ...infos, status: infos.status ? 1 : 0 }
        if (Number(id)) info = { ...removePropObject(info, importDetail), id: id }
        return info
    }

    return (
        <FormUpdate
            checkId={Number(id)}
            title="import"
            handleData={handleData}
            route="/import_history"
            actions={{ add: addImportApi, update: updateImportApi }}
            refreshObjects={[setInfos]}
        >
            <div className="card bg-color">
                <div style={{ maxWidth: '1200px' }}>
                    <InputForm
                        id="name"
                        value={infos.name}
                        onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                        label="Tên import (*)"
                        required
                    />
                    <DropdownForm
                        value={infos.name}
                        onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                        label="Danh mục cung cấp (*)"
                    />
                    <InputForm
                        id="address"
                        value={infos.address}
                        onChange={(e) => setInfos({ ...infos, address: e.target.value })}
                        label="Địa chỉ (*)"
                        required
                    />
                    <InputForm
                        id="phone"
                        value={infos.phone}
                        onChange={(e) => setInfos({ ...infos, phone: e.target.value })}
                        label="Số điện thoại (*)"
                        type="phone"
                        required
                    />
                    <InputForm
                        id="email"
                        value={infos.email}
                        onChange={(e) => setInfos({ ...infos, email: e.target.value })}
                        label="Email (*)"
                        type="email"
                        required
                    />
                    <InputTextareaForm
                        id="note"
                        value={infos.note}
                        onChange={(e) => setInfos({ ...infos, note: e.target.value })}
                        label="Ghi chú"
                    />
                </div>
            </div>
        </FormUpdate>
    )
}

export default UpdateImport
