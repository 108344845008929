import { DropdownForm, Editorz, FormUpdate, InputTextareaForm } from '@/components'
import { removePropObject } from '@/utils'
import { FileUpload } from 'primereact/fileupload'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { addFormTemplateApi, updateFormTemplateApi } from '../api'
import { useDetailFormTemplate } from '../utils'

const UpdateFormTemplate = () => {
    const { id } = useParams()
    const formTemplateDetail = useDetailFormTemplate(id)
    const [infos, setInfos] = useState({
        nameFormTemplatePlace: '',
        statusFormTemplatePlace: '',
        codeFormTemplatePlace: '',
    })

    useEffect(() => {
        if (formTemplateDetail.id) setInfos({ ...infos, ...formTemplateDetail, password: '' })
    }, [formTemplateDetail])

    const handleData = () => {
        let info = { ...infos, status: infos.status ? 1 : 0 }
        if (Number(id)) info = { ...removePropObject(info, formTemplateDetail), id: id }
        return info
    }

    return (
        <FormUpdate
            checkId={Number(id)}
            title="tòa nhà"
            handleData={handleData}
            route="/form_template"
            actions={{ add: addFormTemplateApi, update: updateFormTemplateApi }}
            refreshObjects={[setInfos]}
        >
            <div className="card bg-color">
                <div style={{ maxWidth: '1200px' }}>
                    <DropdownForm
                        value={infos.codeFormTemplatePlace}
                        onChange={(e) => setInfos({ ...infos, codeFormTemplatePlace: e.target.value })}
                        label="Chọn mẫu form (*)"
                    />
                    <FileUpload />
                    <Editorz label="aa" />
                    <InputTextareaForm
                        id="nameFormTemplatePlace"
                        value={infos.nameFormTemplatePlace}
                        onChange={(e) => setInfos({ ...infos, nameFormTemplatePlace: e.target.value })}
                        label="Mô tả (*)"
                        required
                    />
                    <DropdownForm
                        value={infos.codeFormTemplatePlace}
                        onChange={(e) => setInfos({ ...infos, codeFormTemplatePlace: e.target.value })}
                        label="Trạng thái (*)"
                    />
                </div>
            </div>
        </FormUpdate>
    )
}

export default UpdateFormTemplate
