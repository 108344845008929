import { postData, getData } from '@/lib/request'

export const listAssetApi = (params) => getData('web2/company/getListCompany', params)
export const countAssetApi = (params) => getData('web2/company/countCompany', params)
export const deleteAssetApi = (params) => postData('web2/company/deleteCompany', params)
export const detailAssetApi = (params) => getData('web2/company/getDetailCompany', params)
export const addAssetApi = (params) => postData('/web2/company/addCompany', params)
export const updateAssetApi = (params) => postData('/web2/company/updateCompany', params)

export const listAssetAreaApi = (params) => getData('web2/company/getListCompany', params)
export const countAssetAreaApi = (params) => getData('web2/company/countCompany', params)
export const deleteAssetAreaApi = (params) => postData('web2/company/deleteCompany', params)
export const detailAssetAreaApi = (params) => getData('web2/company/getDetailCompany', params)
export const addAssetAreaApi = (params) => postData('/web2/company/addCompany', params)
export const updateAssetAreaApi = (params) => postData('/web2/company/updateCompany', params)

export const listAssetCategoryApi = (params) => getData('web2/company/getListCompany', params)
export const countAssetCategoryApi = (params) => getData('web2/company/countCompany', params)
export const deleteAssetCategoryApi = (params) => postData('web2/company/deleteCompany', params)
export const detailAssetCategoryApi = (params) => getData('web2/company/getDetailCompany', params)
export const addAssetCategoryApi = (params) => postData('/web2/company/addCompany', params)
export const updateAssetCategoryApi = (params) => postData('/web2/company/updateCompany', params)

export const listAssetDetailApi = (params) => getData('web2/company/getListCompany', params)
export const countAssetDetailApi = (params) => getData('web2/company/countCompany', params)
export const deleteAssetDetailApi = (params) => postData('web2/company/deleteCompany', params)
export const detailAssetDetailApi = (params) => getData('web2/company/getDetailCompany', params)
export const addAssetDetailApi = (params) => postData('/web2/company/addCompany', params)
export const updateAssetDetailApi = (params) => postData('/web2/company/updateCompany', params)
