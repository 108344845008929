import { postData, getData } from '@/lib/request'

export const listPostApi = (params) => getData('web2/company/getListCompany', params)
export const countPostApi = (params) => getData('web2/company/countCompany', params)
export const deletePostApi = (params) => postData('web2/company/deleteCompany', params)
export const detailPostApi = (params) => getData('web2/company/getDetailCompany', params)
export const addPostApi = (params) => postData('/web2/company/addCompany', params)
export const updatePostApi = (params) => postData('/web2/company/updateCompany', params)

export const listDirectoryApi = (params) => getData('web2/company/getListCompany', params)
export const countDirectoryApi = (params) => getData('web2/company/countCompany', params)
export const deleteDirectoryApi = (params) => postData('web2/company/deleteCompany', params)
export const detailDirectoryApi = (params) => getData('web2/company/getDetailCompany', params)
export const addDirectoryApi = (params) => postData('/web2/company/addCompany', params)
export const updateDirectoryApi = (params) => postData('/web2/company/updateCompany', params)
