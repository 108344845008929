import { ActionBody, Columnz, DataTablez, Dropdownz, GridForm, RenderHeader, useGetParams } from '@/components'
import { status } from '@/constants'
import { useState } from 'react'
import { deleteBuildingApi } from '../api'
import { useCountBuilding, useListBuilding } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn tòa nhà"
            />
        </GridForm>
    )
}

const Floors = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListBuilding({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountBuilding({ status: undefined, ...params, first: undefined }) || 0
    const [selectedProducts, setSelectedProducts] = useState([])

    return (
        <>
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="tòa nhà"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/building_management"
                headerInfo={{ items: [], batchDelete: async () => await deleteBuildingApi() }}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
            >
                <Columnz field="name" header="Tên tòa nhà" />
                <Columnz field="address" header="Mã" />
                <Columnz field="phone" header="Tình trạng" />
                <Columnz
                    header="Thao tác"
                    body={(e) =>
                        ActionBody({
                            id: e.id,
                            route: '/building_management',
                            deleteAction: deleteBuildingApi,
                            params,
                            setParams,
                        })
                    }
                />
            </DataTablez>
        </>
    )
}

export default Floors
