import { ActionBody, Columnz, DataTablez, Dropdownz, GridForm, HeaderListForm, Inputz, RenderHeader, useGetParams } from '@/components'
import { status } from '@/constants'
import { useState } from 'react'
import { deleteBuildingServiceApi } from '../api'
import { useCountBuildingService, useListBuildingService } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo email, số điện thoại"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn trạng thái"
            />
        </GridForm>
    )
}

const BuildingServices = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListBuildingService({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountBuildingService({ status: undefined, ...params, first: undefined }) || 0

    return (
        <div className="card">
            <HeaderListForm title="Danh sách dịch vụ tòa nhà" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Tòa nhà"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/building_service"
                actionsInfo={{ deleteAction: deleteBuildingServiceApi }}
            >
                <Columnz field="name" header="Tên tòa nhà" />
                <Columnz field="address" header="Mã" />
                <Columnz field="phone" header="Tình trạng" />
            </DataTablez>
        </div>
    )
}

export default BuildingServices
