import { FormUpdate, InputForm, InputTextareaForm, MultiSelectForm } from '@/components'
import { removePropObject } from '@/utils'
import { useEffect, useState } from 'react'
import { addGroupApartmentApi, updateGroupApartmentApi } from '../api'
import { useDetailGroupApartment, useListApartmentV2 } from '../utils'
import { getArrId } from '@/modules/permissions/permission/screens/ListUser'

const UpdateGroupApartment = ({ setVisible, setParams, visible }) => {
    const groupApartmentDetail = useDetailGroupApartment(typeof visible === 'number' ? visible : undefined)
    const [infos, setInfos] = useState({ name: '' })
    const buildings = useListApartmentV2()

    useEffect(() => {
        if (groupApartmentDetail.info && groupApartmentDetail.listApartments) {
            setInfos({
                ...groupApartmentDetail.info,
                name: groupApartmentDetail.info.group_name || '',
                id_apartments: getArrId(groupApartmentDetail.listApartments),
            })
        }
    }, [groupApartmentDetail])

    const handleData = () => {
        let info = { ...infos }
        if (!(info.id_apartments && info.id_apartments[1])) return 'Vui lòng chọn từ 2 căn hộ trở lên!'
        if (typeof visible === 'number') info = { ...removePropObject(info, groupApartmentDetail), groupApartment_id: visible }
        return info
    }

    return (
        <FormUpdate
            checkId={typeof visible === 'number'}
            handleData={handleData}
            actions={{ add: addGroupApartmentApi, update: updateGroupApartmentApi }}
            setVisible={setVisible}
            setParams={setParams}
            refreshObjects={[setInfos]}
        >
            <div className="card mt-4">
                <InputForm
                    id="name"
                    value={infos.name}
                    onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                    label="Tên nhóm căn hộ (*)"
                    required
                />
                <InputTextareaForm
                    id="description"
                    value={infos.description}
                    onChange={(e) => setInfos({ ...infos, description: e.target.value })}
                    label="Mô tả"
                />
                <MultiSelectForm
                    id="id_apartments"
                    value={infos.id_apartments}
                    options={buildings}
                    onChange={(e) => setInfos({ ...infos, id_apartments: e.target.value })}
                    label="Căn hộ (*)"
                />
            </div>
        </FormUpdate>
    )
}

export default UpdateGroupApartment
