import React, { useState } from 'react'
import { RenderHeader, TimeBody, Columnz, StatusBody, DataTablez, useGetParams } from '@/components'
import { updateToolApi } from '../api'

const ToolChilds = ({ data }) => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const totalRecords = data.length

    const header = RenderHeader({ title: 'Danh sách tool' })
    return (
        <DataTablez
            value={data}
            header={header}
            title="quyền nhỏ"
            totalRecords={totalRecords}
            params={params}
            setParams={setParams}
        >
            <Columnz field="name" header="Tên tool" />
            <Columnz field="route" header="Route" />
            <Columnz field="actions" header="Quyền" />
            <Columnz header="Ngày khởi tạo" body={(e) => TimeBody(e.created_at)} />
            <Columnz header="Thời gian cập nhật" body={(e) => TimeBody(e.updated_at)} />
            <Columnz
                field="status"
                header="Trạng thái"
                body={(e) => StatusBody({ e, route: '/tool', updateAction: updateToolApi })}
            />
        </DataTablez>
    )
}

export default ToolChilds
