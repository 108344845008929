import { postData, getData } from '@/lib/request'

export const listManagementDocsApi = (params) => getData('web2/company/getListCompany', params)
export const countManagementDocsApi = (params) => getData('web2/company/countCompany', params)
export const deleteManagementDocsApi = (params) => postData('web2/company/deleteCompany', params)
export const detailManagementDocsApi = (params) => getData('web2/company/getDetailCompany', params)
export const addManagementDocsApi = (params) => postData('/web2/company/addCompany', params)
export const updateManagementDocsApi = (params) => postData('/web2/company/updateCompany', params)

export const listApartmentDocsApi = (params) => getData('web2/company/getListCompany', params)
export const countApartmentDocsApi = (params) => getData('web2/company/countCompany', params)
export const deleteApartmentDocsApi = (params) => postData('web2/company/deleteCompany', params)
export const detailApartmentDocsApi = (params) => getData('web2/company/getDetailCompany', params)
export const addApartmentDocsApi = (params) => postData('/web2/company/addCompany', params)
export const updateApartmentDocsApi = (params) => postData('/web2/company/updateCompany', params)
