import {
    BuildingService, CaculatorDebt, DataReview,
    ElectricWater,
    ListPay,
    ListPrice,
    ListReceipt,
    NotiList,
    PeriodManages,
    Promotion,
    ReceiptExpenditure, ReceiptImport, UpdateBuildingService, UpdateElectricWater,
    UpdateListPrice, UpdateReceiptExpenditure, VehicleManage
} from '@/modules/accountant'
import { ApartmentHandover, PostsCustomer, UpdateApartmentHandover } from '@/modules/apartment_handover'
import {
    AccessDeniedPage, ChangePassword,
    ErrorPage, ForgotPassword, Login, SetPassword
} from '@/modules/auth'
import {
    AssetManagement, BuildingGuide,
    BuildingOverview, DepartmentsList,
    DocsManagement,
    MaintainanceSchedule,
    WorkManagement
} from '@/modules/buildings'
import {
    Companies, Exchanges, Groups, Partners, UpdateCompany, UpdateExchange, UpdateGroup, UpdatePartner, UpdateUser, Users
} from '@/modules/companies'
import { DashBoard } from '@/modules/dashboard'
import {
    AggregateDebt,
    AggregateDebtDetails, BankTreasuryBook, CashBookMoney,
    CustomerList,
    ExcessMoney,
    ExcessMoneyDetails,
    ServicesList
} from '@/modules/debts'
import { DepositList, MarginReport } from '@/modules/deposit'
import { Notification, UpdateNotification } from '@/modules/notifications'
import { ParkingDetails, ParkingGeneral, Report } from '@/modules/parkings'
import { Permissions, Tools, UpdatePermission, UpdateTool } from '@/modules/permissions'
import {
    AggreGateRevenueReport, DebtReport,
    OptionReport,
    RevenueReport,
    VehicleReport,
    WorkReport
} from '@/modules/report_management'
import {
    Apartment,
    Building,
    Card,
    Import,
    Resident,
    UpdateApartment,
    UpdateBuilding,
    UpdateCard,
    UpdateImport,
    UpdateResident
} from '@/modules/resident_apartments'
import {
    ApartmentDeposit,
    FormRequest,
    FormTemplate,
    RatedService,
    RegisterRepair,
    ResidentOpinion,
    ServiceRequest,
    UpdateApartmentDeposit,
    UpdateFormTemplate,
    UpdateRatedService,
    UpdateRegisterRepair,
    UpdateResidentOpinion,
    UpdateServiceRequest
} from '@/modules/resident_opinions'
import { AggregateRevenue, BuildingData, DebtSumReport, InteractiveReport } from '@/modules/statistic_report'
import {
    AccountingAccount,
    ConfigList,
    ReceptForm, ServicesType, UpdateConfig,
    UpdateReceptForm, UpdateService
} from '@/modules/system'

export const routes = [
    // ==================== Authentication ==============================
    { path: '/', component: DashBoard, layout: true },
    { path: '/auth/login', component: Login, public: true },
    { path: '/auth/forgot_password', component: ForgotPassword, public: true },
    { path: '/auth/set_password', component: SetPassword, public: true },
    { path: '/auth/change_password', component: ChangePassword },

    // ==================== Quản Lý Quyền ==============================
    { path: '/permission', component: Permissions, layout: true },
    { path: '/permission/add', component: UpdatePermission, layout: true },
    { path: '/permission/detail/:id', component: UpdatePermission, layout: true },

    { path: '/tool', component: Tools, layout: true },
    { path: '/tool/add', component: UpdateTool, layout: true },
    { path: '/tool/detail/:id', component: UpdateTool, layout: true },

    // ==================== Quản Lý Công Ty ==============================
    { path: '/company', component: Companies, layout: true },
    { path: '/company/add', component: UpdateCompany, layout: true },
    { path: '/company/detail/:id', component: UpdateCompany, layout: true },

    { path: '/exchange', component: Exchanges, layout: true },
    { path: '/exchange/add', component: UpdateExchange, layout: true },
    { path: '/exchange/detail/:id', component: UpdateExchange, layout: true },

    { path: '/group', component: Groups, layout: true },
    { path: '/group/add', component: UpdateGroup, layout: true },
    { path: '/group/detail/:id', component: UpdateGroup, layout: true },

    { path: '/user', component: Users, layout: true },
    { path: '/user/add', component: UpdateUser, layout: true },
    { path: '/user/detail/:id', component: UpdateUser, layout: true },

    { path: '/partner', component: Partners, layout: true },
    { path: '/partner/add', component: UpdatePartner, layout: true },
    { path: '/partner/detail/:id', component: UpdatePartner, layout: true },

    // ==================== Quản Lý Công Nợ ==============================
    { path: '/aggregate_debt', component: AggregateDebt, layout: true },
    { path: '/aggregate_debt_details', component: AggregateDebtDetails, layout: true },
    { path: '/cash_book_money', component: CashBookMoney, layout: true },
    { path: '/customer_list', component: CustomerList, layout: true },
    { path: '/excess_money', component: ExcessMoney, layout: true },
    { path: '/excess_money_details', component: ExcessMoneyDetails, layout: true },
    { path: '/services_list', component: ServicesList, layout: true },
    { path: '/bank_treasury_book', component: BankTreasuryBook, layout: true },

    // ==================== Quản Lý Tòa Nhà ==============================
    { path: '/building_overview', component: BuildingOverview, layout: true },
    { path: '/asset_management', component: AssetManagement, layout: true },
    { path: '/building_guide', component: BuildingGuide, layout: true },
    { path: '/departments_list', component: DepartmentsList, layout: true },
    { path: '/docs_management', component: DocsManagement, layout: true },
    { path: '/maintainance_schedule', component: MaintainanceSchedule, layout: true },
    { path: '/work_management', component: WorkManagement, layout: true },
    // ==================== Quản Lý Thông Báo ==============================
    { path: '/notification', component: Notification, layout: true },
    { path: '/notification/detail/:id', component: UpdateNotification, layout: true },

    // ==================== Hệ Thống Bãi Xe ==============================
    { path: '/parking_general', component: ParkingGeneral, layout: true },
    { path: '/parking_details', component: ParkingDetails, layout: true },
    { path: '/report', component: Report, layout: true },

    // ==================== Căn hộ dân cư ==============================
    { path: '/apartment_management', component: Apartment, layout: true },
    { path: '/apartment_management/add', component: UpdateApartment, layout: true },
    { path: '/apartment_management/detail/:id', component: UpdateApartment, layout: true },

    { path: '/building_management', component: Building, layout: true },
    { path: '/building_management/add', component: UpdateBuilding, layout: true },
    { path: '/building_management/detail/:id', component: UpdateBuilding, layout: true },

    { path: '/card_management', component: Card, layout: true },
    { path: '/card_management/add', component: UpdateCard, layout: true },
    { path: '/card_management/detail/:id', component: UpdateCard, layout: true },

    { path: '/import_history', component: Import, layout: true },
    { path: '/import_history/add', component: UpdateImport, layout: true },
    { path: '/import_history/detail/:id', component: UpdateImport, layout: true },

    { path: '/resident_management', component: Resident, layout: true },
    { path: '/resident_management/add', component: UpdateResident, layout: true },
    { path: '/resident_management/detail/:id', component: UpdateResident, layout: true },

    // ==================== Ý kiến dân cư ==============================
    { path: '/apartment_deposit', component: ApartmentDeposit, layout: true },
    { path: '/apartment_deposit/add', component: UpdateApartmentDeposit, layout: true },
    { path: '/apartment_deposit/detail/:id', component: UpdateApartmentDeposit, layout: true },

    { path: '/form_request', component: FormRequest, layout: true },

    { path: '/form_template', component: FormTemplate, layout: true },
    { path: '/form_template/add', component: UpdateFormTemplate, layout: true },
    { path: '/form_template/detail/:id', component: UpdateFormTemplate, layout: true },

    { path: '/rated_service', component: RatedService, layout: true },
    { path: '/rated_service/add', component: UpdateRatedService, layout: true },
    { path: '/rated_service/detail/:id', component: UpdateRatedService, layout: true },

    { path: '/register_repair', component: RegisterRepair, layout: true },
    { path: '/register_repair/add', component: UpdateRegisterRepair, layout: true },
    { path: '/register_repair/detail/:id', component: UpdateRegisterRepair, layout: true },

    { path: '/resident_opinions', component: ResidentOpinion, layout: true },
    { path: '/resident_opinions/add', component: UpdateResidentOpinion, layout: true },
    { path: '/resident_opinions/detail/:id', component: UpdateResidentOpinion, layout: true },

    { path: '/service_request', component: ServiceRequest, layout: true },
    { path: '/service_request/add', component: UpdateServiceRequest, layout: true },
    { path: '/service_request/detail/:id', component: UpdateServiceRequest, layout: true },

    // ==================== Bàn giao căn hộ ==============================
    { path: '/apartment_handover', component: ApartmentHandover, layout: true },
    { path: '/apartment_handover/add', component: UpdateApartmentHandover, layout: true },
    { path: '/apartment_handover/detail/:id', component: UpdateApartmentHandover, layout: true },

    { path: '/posts_customer', component: PostsCustomer, layout: true },

    // ==================== Kế toán ==============================
    { path: '/building_services', component: BuildingService, layout: true },
    { path: '/building_services/add', component: UpdateBuildingService, layout: true },
    { path: '/building_services/detail/:id', component: UpdateBuildingService, layout: true },

    { path: '/calculate_debt', component: CaculatorDebt, layout: true },

    { path: '/receipt_import', component: ReceiptImport, layout: true },
    { path: '/data_review', component: DataReview, layout: true },

    { path: '/electric_water', component: ElectricWater, layout: true },
    { path: '/electric_water/add', component: UpdateElectricWater, layout: true },
    { path: '/electric_water/detail/:id', component: UpdateElectricWater, layout: true },

    { path: '/payment', component: ListPay, layout: true },

    { path: '/list_receipt', component: ListReceipt, layout: true },

    { path: '/noti_list', component: NotiList, layout: true },

    { path: '/period_management', component: PeriodManages, layout: true },

    { path: '/list_price', component: ListPrice, layout: true },
    { path: '/list_price/add', component: UpdateListPrice, layout: true },
    { path: '/list_price/detail/:id', component: UpdateListPrice, layout: true },

    { path: '/promotion_management', component: Promotion, layout: true },

    { path: '/receipts_expenditure', component: ReceiptExpenditure, layout: true },
    { path: '/receipts_expenditure/add', component: UpdateReceiptExpenditure, layout: true },
    { path: '/receipts_expenditure/detail/:id', component: UpdateReceiptExpenditure, layout: true },

    { path: '/vehicle_manage', component: VehicleManage, layout: true },

    // ==================== Hệ thống ==============================
    { path: '/service_type', component: ServicesType, layout: true },
    { path: '/service_type/add', component: UpdateService, layout: true },
    { path: '/service_type/detail/:id', component: UpdateService, layout: true },

    { path: '/recept_form', component: ReceptForm, layout: true },
    { path: '/recept_form/add', component: UpdateReceptForm, layout: true },
    { path: '/recept_form/detail/:id', component: UpdateReceptForm, layout: true },

    { path: '/accounting_account', component: AccountingAccount, layout: true },

    { path: '/config_list', component: ConfigList, layout: true },
    { path: '/config_list/add', component: UpdateConfig, layout: true },
    { path: '/config_list/detail/:id', component: UpdateConfig, layout: true },

    // Ký Quỹ
    { path: '/deposit_list', component: DepositList, layout: true },
    { path: '/margin_report', component: MarginReport, layout: true },

    // Báo Cáo Thống Kê
    { path: '/aggregate_revenue', component: AggregateRevenue, layout: true },
    { path: '/building_data', component: BuildingData, layout: true },
    { path: '/debt_sum_report', component: DebtSumReport, layout: true },
    { path: '/interavtive_report', component: InteractiveReport, layout: true },

    // Báo cáo quản trị
    { path: '/debt_report', component: DebtReport, layout: true },
    { path: '/option_report', component: OptionReport, layout: true },
    { path: '/revenue_report', component: RevenueReport, layout: true },
    { path: '/vehicle_report', component: VehicleReport, layout: true },
    { path: '/work_report', component: WorkReport, layout: true },
    { path: '/aggregate_revenue_report', component: AggreGateRevenueReport, layout: true },
]

export const errorPage = { path: '*', component: ErrorPage }
export const accessDeniedPage = { path: '*', component: AccessDeniedPage }
