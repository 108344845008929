import {
    CalendarForm,
    Columnz,
    DataTablez,
    Dialogz,
    DropdownForm,
    FormUpdate,
    GridForm,
    HeaderListForm,
    InputForm,
    Inputz,
    RenderHeader,
    useGetParams,
} from '@/components'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { removePropObject } from '@/utils'
import { addPromotionApartApi, deletePromotionApartApi, updatePromotionApartApi } from '../api'
import { useCountPromotionApart, useDetailPromotionApart, useListPromotionApart } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo tên"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
        </GridForm>
    )
}

const ApartmentPromotionManage = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListPromotionApart({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountPromotionApart({ status: undefined, ...params, first: undefined }) || 0
    const [visible, setVisible] = useState(false)

    const { id } = useParams()
    const promotionManageDetail = useDetailPromotionApart(id)
    const [infos, setInfos] = useState({
        service: '',
        apart_service: '',
        promotion: '',
        semester: '',
    })

    useEffect(() => {
        if (promotionManageDetail.id)
            setInfos({ ...infos, ...promotionManageDetail, status: promotionManageDetail.status === 0 ? false : true })
    }, [promotionManageDetail])

    const handleData = () => {
        let info = { ...infos }
        if (Number(id)) info = { ...removePropObject(info, promotionManageDetail), id: id }
        return info
    }

    const category = [
        { id: 1, name: 'Đồ gia dụng' },
        { id: 2, name: 'Phòng cháy chữa cháy' },
        { id: 3, name: 'Đồ điện tử ' },
        { id: 4, name: 'Đồ thông cống' },
        { id: 5, name: 'Thiết bị thông minh' },
    ]

    const RenderDialog = () => {
        return (
            <Dialogz title={'Khuyến Mãi Căn hộ'} visible={visible} setVisible={setVisible}>
                <FormUpdate
                    checkId={Number(id)}
                    // title="Khuyến mãi"
                    handleData={handleData}
                    route="/promotion_management"
                    actions={{ add: addPromotionApartApi, update: updatePromotionApartApi }}
                    refreshObjects={[setInfos]}
                >
                    <DropdownForm
                        id="service"
                        value={infos.service}
                        optionLabel="name"
                        options={category}
                        onChange={(e) => setInfos({ ...infos, service: e.target.value })}
                        label="Dịch vụ"
                    />
                    <DropdownForm
                        id="apart_service"
                        value={infos.apart_service}
                        optionLabel="name"
                        options={category}
                        onChange={(e) => setInfos({ ...infos, apart_service: e.target.value })}
                        label="Dịch vụ căn hộ"
                    />
                    <DropdownForm
                        id="service"
                        value={infos.service}
                        optionLabel="name"
                        options={category}
                        onChange={(e) => setInfos({ ...infos, service: e.target.value })}
                        label="Khuyến mãi"
                    />
                    <CalendarForm
                        id="semester"
                        value={infos.semester}
                        onChange={(e) => setInfos({ ...infos, semester: e.target.value })}
                        showIcon
                        label="Kỳ"
                    />
                </FormUpdate>
            </Dialogz>
        )
    }

    return (
        <>
            <RenderDialog />
            <HeaderListForm title="Quản lý khuyến mãi căn hộ" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Khuyến mãi"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/promotion_management"
                setVisibledDialog={setVisible}
                actionsInfo={{ deleteAction: deletePromotionApartApi }}
            >
                <Columnz field="name" header="Tên khuyến mãi" />
                <Columnz field="phone" header="Căn hộ" />
                <Columnz field="address" header="Dịch vụ" />
                <Columnz field="address" header="Sản phẩm" />
                <Columnz field="address" header="Kỳ" />
                <Columnz field="address" header="Khuyến mãi" />
                <Columnz field="address" header="Người tạo" />
                <Columnz field="address" header="Ngày tạo" />
            </DataTablez>
        </>
    )
}

export default ApartmentPromotionManage
