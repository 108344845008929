import { DropdownForm, FormUpdate, InputForm, InputTextareaForm } from '@/components'
import { removePropObject } from '@/utils'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { addCardApi, updateCardApi } from '../api'
import { useDetailCard } from '../utils'

const UpdateCard = () => {
    const { id } = useParams()
    const cardDetail = useDetailCard(id)
    const [infos, setInfos] = useState({ name: '', phone: '', email: '', address: '', note: '', status: true })

    useEffect(() => {
        if (cardDetail.id) setInfos({ ...infos, ...cardDetail, password: '' })
    }, [cardDetail])

    const handleData = () => {
        let info = { ...infos, status: infos.status ? 1 : 0 }
        if (Number(id)) info = { ...removePropObject(info, cardDetail), id: id }
        return info
    }

    return (
        <FormUpdate
            checkId={Number(id)}
            title="thẻ"
            handleData={handleData}
            route="/card_management"
            actions={{ add: addCardApi, update: updateCardApi }}
            refreshObjects={[setInfos]}
        >
            <div className="card bg-color">
                <div style={{ maxWidth: '1200px' }}>
                    <InputForm
                        id="name"
                        value={infos.name}
                        onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                        label="Mã thẻ (*)"
                        required
                    />
                    <DropdownForm
                        value={infos.name}
                        onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                        label="Tòa nhà (*)"
                    />
                    <DropdownForm
                        value={infos.name}
                        onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                        label="Căn hộ (*)"
                    />
                    <DropdownForm
                        value={infos.name}
                        onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                        label="Loại phương tiện (*)"
                    />
                    <DropdownForm
                        value={infos.name}
                        onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                        label="Biển số (*)"
                    />
                    <InputTextareaForm
                        id="note"
                        value={infos.note}
                        onChange={(e) => setInfos({ ...infos, note: e.target.value })}
                        label="Ghi chú"
                    />
                </div>
            </div>
        </FormUpdate>
    )
}

export default UpdateCard
