import { ActionBody, Columnz, DataTablez, Dropdownz, GridForm, HeaderListForm, RenderHeader, useGetParams } from '@/components'
import { status } from '@/constants'
import { useState } from 'react'
import { deleteReceiptExpenditureApi } from '../api'
import { useCountReceiptExpenditure, useListReceiptExpenditure } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-12">
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn tòa nhà"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Căn hộ"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Tháng chốt sổ"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Loại"
            />
        </GridForm>
    )
}

const ListReceiptExpenditure = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListReceiptExpenditure({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountReceiptExpenditure({ status: undefined, ...params, first: undefined }) || 0

    return (
        <div className="card">
            <HeaderListForm title="Danh sách phiếu thu/chi" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Phiếu thu/chi"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/receipts_expenditure"
                actionsInfo={{ deleteAction: deleteReceiptExpenditureApi }}
            >
                <Columnz field="name" header="Dự án" />
                <Columnz field="address" header="Mã chứng từ" />
                <Columnz field="email" header="Hình thức" />
                <Columnz field="phone" header="Loại phiếu" />
                <Columnz field="phone" header="Ngày lập phiếu" />
                <Columnz field="phone" header="Ngày hạch toán" />
                <Columnz field="phone" header="Căn hộ" />
                <Columnz field="phone" header="Tòa" />
                <Columnz field="phone" header="Khách hàng" />
                <Columnz field="phone" header="Số tiền" />
                <Columnz field="phone" header="Nội dung" />
                <Columnz field="phone" header="Người tạo" />
                <Columnz field="phone" header="Người xóa" />
                <Columnz field="phone" header="In phiếu" />
            </DataTablez>
        </div>
    )
}

export default ListReceiptExpenditure
