import { createSlice } from '@reduxjs/toolkit'
export const loadingSlice = createSlice({
    name: 'loading',
    initialState: { loading: true },
    reducers: {
        setLoading: (state, action) => {
            state.loading = true
        },
        clearLoading: (state, action) => {
            state.loading = false
        },
    },
})
export const { setLoading, clearLoading } = loadingSlice.actions
export default loadingSlice.reducer
