import React, { useEffect, useState } from 'react'
import {
    ActionBody,
    Columnz,
    DataTablez,
    Dialogz,
    Dropdownz,
    FormUpdate,
    GridForm,
    HeaderListForm,
    InputForm,
    InputTextareaForm,
    Inputz,
    RenderHeader,
    StatusBody,
    TimeBody,
    useGetParams,
} from '@/components'
import { useParams } from 'react-router-dom'
import { removePropObject } from '@/utils'
import { useCountCheckList, useDetailCheckList, useListCheckList } from '../utils'
import { addCheckListApi, deleteCheckListApi, updateCheckListApi } from '../api'
import { Button } from 'primereact/button'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    const listCategoryData = [
        { id: 1, name: 'Thiết bị điện' },
        { id: 2, name: 'Đồ gia dụng' },
        { id: 3, name: 'Phòng cháy chữa cháy' },
        { id: 4, name: 'Đồ điện tử' },
        { id: 5, name: 'Đồ Thông Cống' },
        { id: 6, name: 'Thiết bị thông minh' },
    ]

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập từ khóa"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.category}
                options={listCategoryData}
                onChange={(e) => setFilter({ ...filter, category: e.target.value })}
                placeholder="Chọn danh mục"
            />
        </GridForm>
    )
}

export default function CheckList() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListCheckList({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountCheckList({ status: undefined, ...params, first: undefined }) || 0
    const [visible, setVisible] = useState(false)

    const { id } = useParams()
    const checkListDetail = useDetailCheckList(id)
    const [infos, setInfos] = useState({
        title: '',
    })
    useEffect(() => {
        if (checkListDetail.id)
            setInfos({ ...infos, ...checkListDetail, status: checkListDetail.status === 0 ? false : true })
    }, [checkListDetail])

    const handleData = () => {
        let info = { ...infos }
        if (Number(id)) info = { ...removePropObject(info, checkListDetail), id: id }
        return info
    }

    const RenderDialog = () => {
        return (
            <Dialogz title={'Công việc'} visible={visible} setVisible={setVisible}>
                <FormUpdate
                    checkId={Number(id)}
                    // title="Check list"
                    handleData={handleData}
                    route="/work_management"
                    actions={{ add: addCheckListApi, update: updateCheckListApi }}
                    refreshObjects={[setInfos]}
                >
                    <InputForm
                        id="title"
                        value={infos.title}
                        onChange={(e) => setInfos({ ...infos, title: e.target.value })}
                        label="Tiêu đề"
                        required
                    />
                    <Button label="Thêm Check List từ mẫu" />
                </FormUpdate>
            </Dialogz>
        )
    }
    return (
        <>
            <RenderDialog />
            <HeaderListForm title="Danh sách check list" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Check list"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/work_management"
                setVisibledDialog={setVisible}
                actionsInfo={{ deleteAction: deleteCheckListApi }}
            >
                <Columnz field="title" header="Tiêu đề" />
                <Columnz header="Ngày tạo" body={(e) => TimeBody(e.created_at)} />
                <Columnz
                    header="Trạng thái"
                    body={(e) =>
                        StatusBody({
                            e,
                            route: '/work_management',
                            updateAction: updateCheckListApi,
                        })
                    }
                />
            </DataTablez>
        </>
    )
}
