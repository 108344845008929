import { ActionBody, Columnz, DataTablez, GridForm, HeaderListForm, Inputz, RenderHeader, useGetParams } from '@/components'
import { useState } from 'react'
import { deletePeriodManageApi } from '../api'
import { useCountPeriodManage, useListPeriodManage } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập từ khóa"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
        </GridForm>
    )
}

const PeriodManages = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListPeriodManage({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountPeriodManage({ status: undefined, ...params, first: undefined }) || 0

    return (
        <div className="card">
            <HeaderListForm title="Quản lý khóa kì" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Tòa nhà"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/period_management"
                actionsInfo={{ deleteAction: deletePeriodManageApi }}
            >
                <Columnz field="name" header="ID" />
                <Columnz field="address" header="Tiêu đề" />
                <Columnz field="phone" header="Căn hộ nhận thông báo" />
                <Columnz field="phone" header="Danh mục" />
                <Columnz field="phone" header="Người tạo" />
                <Columnz field="phone" header="Trạng thái" />
            </DataTablez>
        </div>
    )
}

export default PeriodManages
