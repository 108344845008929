import React, { useState } from 'react'
import { Columnz, DataTablez, TimeBody, StatusBody, useGetParams, Body, HeaderListForm } from '@/components'
import { deleteGroupApi, updateGroupApi } from '../api'
import { useCountGroup, useListGroup } from '../utils'
import { Dropdownz, GridForm, Inputz } from '@/components'
import { status, units } from '@/constants'
import { useSelector } from 'react-redux'
import { useListExchangeV2 } from '../../exchange/utils'

const Header = ({ setParams, exchanges }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-12">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo tên, mã nhóm"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.bdc_department_id}
                options={exchanges}
                onChange={(e) => setFilter({ ...filter, bdc_department_id: e.target.value })}
                placeholder="Phòng ban"
            />
            <Dropdownz
                value={filter.type}
                options={units}
                onChange={(e) => setFilter({ ...filter, type: e.target.value })}
                placeholder="Phân loại"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Trạng thái"
            />
        </GridForm>
    )
}

const Groups = ({ bdc_department_id }) => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListGroup({ status: undefined, bdc_department_id, ...params, first: undefined })
    const totalRecords = useCountGroup({ status: undefined, bdc_department_id, ...params, first: undefined }) || 0

    const users = useSelector((state) => state.users)
    const exchanges = useListExchangeV2()

    return (
        <div className={bdc_department_id ? '' : 'card'}>
            {!bdc_department_id && (
                <>
                    <HeaderListForm title="Danh sách nhóm" />
                    <Header exchanges={exchanges} setParams={setParams} />
                </>
            )}
            <DataTablez
                value={data}
                title="nhóm"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/group"
                actionsInfo={{ deleteAction: deleteGroupApi }}
            >
                <Columnz body={(e) => Body(units, e.type)} header="Phân loại" />
                <Columnz field="exchange_name" header="Tên phòng ban / dự án" />
                <Columnz field="name" header="Tên nhóm" />
                <Columnz field="code" header="Mã nhóm" />
                <Columnz field="phone_number" header="Hotline" />
                <Columnz field="email" header="Email liên hệ" />
                <Columnz body={(e) => Body(users.users, e.main_user_id)} header="Trưởng nhóm" />
                <Columnz header="Thời gian tạo" body={(e) => TimeBody(e.created_at)} />
                <Columnz header="Thời gian cập nhật" body={(e) => TimeBody(e.updated_at)} />
                <Columnz
                    header="Trạng thái"
                    body={(e) => StatusBody({ e, route: '/group', updateAction: updateGroupApi })}
                />
            </DataTablez>
        </div>
    )
}

export default Groups
