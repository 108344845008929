import React, { useState } from 'react'
import {
    ActionBody,
    RenderHeader,
    Columnz,
    DataTablez,
    TimeBody,
    StatusBody,
    useGetParams,
    HeaderListForm,
} from '@/components'
import { Dropdownz, GridForm, Inputz } from '@/components'
import { status } from '@/constants'
import { useCountConfig, useListConfig } from '../utils'
import { deleteConfigApi, updateConfigApi } from '../api'
import { useNavigate } from 'react-router-dom'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập từ khóa"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
        </GridForm>
    )
}

export default function ConfigList() {
    const navigate = useNavigate()
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListConfig({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountConfig({ status: undefined, ...params, first: undefined }) || 0
    const [selectedProducts, setSelectedProducts] = useState([])

    const items = [
        {
            label: 'Xóa',
            icon: 'pi pi-trash',
            command: () => {},
        },
        {
            label: 'Active',
            icon: 'pi pi-check',
            command: () => {},
        },
        {
            label: 'Inactive',
            icon: 'pi pi-times',
            command: () => {},
        },
    ]
    const moreOptions = [
        { label: 'Chọn mẫu bảng kê', icon: 'pi pi-table', command: () => navigate('/') },
        { label: 'Chọn mẫu phiếu thu', icon: 'pi pi-table', command: () => navigate('/') },
    ]

    return (
        <div className="card">
            <HeaderListForm title="Danh sách config" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Config"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                selectedProducts={selectedProducts}
                route="/config_list"
                setSelectedProducts={setSelectedProducts}
                headerInfo={{ items, moreOptions }}
                actionsInfo={{ deleteAction: deleteConfigApi }}
            >
                <Columnz field="title" header="Tiêu đề" />
                <Columnz field="code" header="Giá trị" />
                <Columnz field="key" header="Key" />
                <Columnz field="building_id" header="Building_id" />
                <Columnz
                    header="Trạng thái"
                    body={(e) =>
                        StatusBody({
                            e,
                            route: '/config_list',
                            updateAction: updateConfigApi,
                        })
                    }
                />
                <Columnz field="isShow" header="Hiện" />
                <Columnz field="creator" header="Người tạo" />
                <Columnz header="Thời gian tạo" body={(e) => TimeBody(e.created_at)} />
            </DataTablez>
        </div>
    )
}
