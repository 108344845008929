import { useEffect, useState } from 'react'
import { countResidentApi, detailResidentApi, listResidentApi } from '../api'

export const useListResident = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listResidentApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountResident = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countResidentApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailResident = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailResidentApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
