import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { removePropObject } from '@/utils'
import { InputForm, FormUpdate, InputTextareaForm, InputSwitchForm } from '@/components'
import EditorV2 from '@/components/Editorz'
import { useDetailReceptForm } from '../utils'
import { addReceptFormApi, updateReceptFormApi } from '../api'

const UpdateReceptForm = () => {
    const { id } = useParams()
    const receptFormDetail = useDetailReceptForm(id)
    const [infos, setInfos] = useState({
        type: '',
        bill: '',
        description: '',
        status: true,
    })
    useEffect(() => {
        if (receptFormDetail.id)
            setInfos({ ...infos, ...receptFormDetail, status: receptFormDetail.status === 0 ? false : true })
    }, [receptFormDetail])

    const handleData = () => {
        let info = { ...infos }
        if (Number(id)) info = { ...removePropObject(info, receptFormDetail), id: id }
        return info
    }

    return (
        <FormUpdate
            checkId={Number(id)}
            title="Hình Thức Phiếu Thu"
            handleData={handleData}
            route="/recept_form"
            actions={{ add: addReceptFormApi, update: updateReceptFormApi }}
            refreshObjects={[setInfos]}
        >
            <InputForm
                id="type"
                value={infos.type}
                onChange={(e) => setInfos({ ...infos, type: e.target.value })}
                label="Hình thức thu tiền"
            />
            <InputForm
                id="bill"
                value={infos.bill}
                onChange={(e) => setInfos({ ...infos, bill: e.target.value })}
                label="Phiếu kế toán"
            />
            <label className="mt-3 mb-3 font-medium w-5">Mô tả</label>
            <EditorV2
                id="description"
                value={infos.description}
                onChange={(e) => setInfos({ ...infos, description: e.target.value })}
                label="Mô tả"
            />
            <div className="mb-5"></div>
            <InputSwitchForm
                id="status"
                checked={infos.status}
                onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                label="Status"
            />
        </FormUpdate>
    )
}

export default UpdateReceptForm
