import { getData, postData } from '@/lib/request'

export const listReceiptExpenditureApi = (params) =>
    getData('web2/receipts_expenditure/getListReceiptExpenditure', params)
export const countReceiptExpenditureApi = (params) =>
    getData('web2/receipts_expenditure/countReceiptExpenditure', params)
export const deleteReceiptExpenditureApi = (params) =>
    getData('web2/receipts_expenditure/deleteReceiptExpenditure', params)
export const detailReceiptExpenditureApi = (params) =>
    getData('web2/receipts_expenditure/getDetailReceiptExpenditure', params)
export const addReceiptExpenditureApi = (params) => postData('web2/receipts_expenditure/addReceiptExpenditure', params)
export const updateReceiptExpenditureApi = (params) =>
    postData('web2/receipts_expenditure/updateReceiptExpenditure', params)
