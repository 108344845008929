import { useSelector } from 'react-redux'
import { Outlet, Navigate } from 'react-router-dom'

export const PrivateRoutes = () => {
    const user = useSelector((state) => state.userInfo)
    return Boolean(user.userInfo) ? <Outlet /> : <Navigate to="/auth/login" />
}

export const PublicRoutes = () => {
    const user = useSelector((state) => state.userInfo)
    return !Boolean(user.userInfo) ? <Outlet /> : <Navigate to="/" />
}
