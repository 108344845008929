import { createSlice } from '@reduxjs/toolkit'
export const userSlice = createSlice({
    name: 'userInfo',
    initialState: { userInfo: null },
    reducers: {
        setUserInfo: (state, action) => {
            state.userInfo = action.payload
        },
        clearUserInfo: (state) => {
            state.userInfo = null
        },
    },
})
export const { setUserInfo, clearUserInfo } = userSlice.actions
export default userSlice.reducer
