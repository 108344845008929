import { postData, getData } from '@/lib/request'

//building info
export const listBuildingInfoApi = (params) => getData('web2/company/getListCompany', params)
export const countBuildingInfoApi = (params) => getData('web2/company/countCompany', params)
export const deleteBuildingInfoApi = (params) => postData('web2/company/deleteCompany', params)
export const detailBuildingInfoApi = (params) => getData('web2/company/getDetailCompany', params)
export const addBuildingInfoApi = (params) => postData('/web2/company/addCompany', params)
export const updateBuildingInfoApi = (params) => postData('/web2/company/updateCompany', params)

//contact info
export const listContactInfoApi = (params) => getData('web2/company/getListCompany', params)
export const countContactInfoApi = (params) => getData('web2/company/countCompany', params)
export const deleteContactInfoApi = (params) => postData('web2/company/deleteCompany', params)
export const detailContactInfoApi = (params) => getData('web2/company/getDetailCompany', params)
export const addContactInfoApi = (params) => postData('/web2/company/addCompany', params)
export const updateContactInfoApi = (params) => postData('/web2/company/updateCompany', params)

//paymentInfo
export const listPaymentInfoApi = (params) => getData('web2/company/getListCompany', params)
export const countPaymentInfoApi = (params) => getData('web2/company/countCompany', params)
export const deletePaymentInfoApi = (params) => postData('web2/company/deleteCompany', params)
export const detailPaymentInfoApi = (params) => getData('web2/company/getDetailCompany', params)
export const addPaymentInfoApi = (params) => postData('/web2/company/addCompany', params)
export const updatePaymentInfoApi = (params) => postData('/web2/company/updateCompany', params)

//paymentMethod
export const listPaymentMethodApi = (params) => getData('web2/company/getListCompany', params)
export const countPaymentMethodApi = (params) => getData('web2/company/countCompany', params)
export const deletePaymentMethodApi = (params) => postData('web2/company/deleteCompany', params)
export const detailPaymentMethodApi = (params) => getData('web2/company/getDetailCompany', params)
export const addPaymentMethodApi = (params) => postData('/web2/company/addCompany', params)
export const updatePaymentMethodApi = (params) => postData('/web2/company/updateCompany', params)
