import {
    ActionBody,
    Columnz,
    DataTablez,
    GridForm,
    HeaderListForm,
    Inputz,
    RenderHeader,
    useGetParams,
} from '@/components'
import { useState } from 'react'
import { deletePostsCustomerApi } from '../api'
import { useCountPostsCustomer, useListPostsCustomer } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập từ khóa"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
        </GridForm>
    )
}

const PostsCustomers = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListPostsCustomer({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountPostsCustomer({ status: undefined, ...params, first: undefined }) || 0

    return (
        <div className="card">
            <HeaderListForm title="Danh sách gửi thông báo" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Đánh giá"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/posts_customer"
                actionsInfo={{ deleteAction: deletePostsCustomerApi }}
            >
                <Columnz field="name" header="ID" />
                <Columnz field="address" header="Tiêu đề" />
                <Columnz field="phone" header="Căn hộ nhận thông báo" />
                <Columnz field="phone" header="Danh mục" />
                <Columnz field="phone" header="Người tạo" />
                <Columnz field="phone" header="Trạng thái" />
            </DataTablez>
        </div>
    )
}

export default PostsCustomers
