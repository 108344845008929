import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDetailUser } from '../utils'
import { updateUserApi, addUserApi } from '../api'
import { removePropObject } from '@/utils'
import { InputForm, InputSwitchForm, FormUpdate, CalendarForm, DropdownForm } from '@/components'
import { UploadImg } from '@/components'
import { databaseDate } from '@/lib'
import { removeUndefinedProps } from '@/utils'
import { useListGroupV2 } from '../../group/utils'
import { useListExchangeV2 } from '../../exchange/utils'

const initInfos = {
    full_name: '',
    phone: '',
    email: '',
    address: '',
    cmt_number: '',
    cmt_address: '',
    birthday: '',
    status: true,
}

const UpdateUser = () => {
    const { id } = useParams()
    const userDetail = useDetailUser(id)
    const [infos, setInfos] = useState(initInfos)
    const [avatar, setAvatar] = useState(null)
    const exchanges = useListExchangeV2()
    const groups = useListGroupV2({ department_id: infos.department_id })

    useEffect(() => {
        if (userDetail.id)
            setInfos({
                ...infos,
                ...removeUndefinedProps(userDetail),
                status: Number(userDetail.status) !== 0,
                email: userDetail.email_contact || '',
                phone: userDetail.phone_contact || '',
                birthday: userDetail.birthday ? new Date(userDetail.birthday) : '',
            })
    }, [userDetail])

    const handleData = () => {
        let info = { ...infos, status: infos.status ? 1 : 0 }
        info.birthday = info.birthday ? databaseDate(info.birthday) : undefined
        if (Number(id)) info = { ...removePropObject(info, userDetail), id: id }
        return info
    }

    return (
        <FormUpdate
            checkId={Number(id)}
            title="người dùng"
            handleData={handleData}
            route="/user"
            actions={{ add: addUserApi, update: updateUserApi }}
            refreshObjects={[setInfos]}
        >
            <div className="card">
                <div className="grid grid-form">
                    <div className="col-12 lg:col-3">
                        <div className="card bg-color">
                            <UploadImg image={avatar} setImage={setAvatar} title="Ảnh đại diện" />
                        </div>
                    </div>
                    <div className="col-12 lg:col-9 ">
                        <div className="card bg-color">
                            <div className="grid grid-form">
                                <div className="col-12 lg:col-6">
                                    <InputForm
                                        id="full_name"
                                        value={infos.full_name}
                                        onChange={(e) => setInfos({ ...infos, full_name: e.target.value })}
                                        label="Tên nhân viên (*)"
                                        required
                                    />
                                    <InputForm
                                        id="code"
                                        value={infos.code}
                                        onChange={(e) => setInfos({ ...infos, code: e.target.value })}
                                        label="Mã nhân viên (*)"
                                        required
                                    />
                                    <InputForm
                                        id="email"
                                        value={infos.email}
                                        onChange={(e) => setInfos({ ...infos, email: e.target.value })}
                                        label="Email (*)"
                                        type="email"
                                        required
                                        disabled={id}
                                    />
                                    <InputForm
                                        id="phone"
                                        value={infos.phone}
                                        onChange={(e) => setInfos({ ...infos, phone: e.target.value })}
                                        label="Số điện thoại (*)"
                                        type="phone"
                                        required
                                        disabled={id}
                                    />
                                    <CalendarForm
                                        id="birthday"
                                        label="Ngày sinh"
                                        value={infos.birthday}
                                        onChange={(e) => setInfos({ ...infos, birthday: e.target.value })}
                                    />
                                </div>
                                <div className="col-12 lg:col-6">
                                    <InputForm
                                        id="address"
                                        value={infos.address}
                                        onChange={(e) => setInfos({ ...infos, address: e.target.value })}
                                        label="Địa chỉ"
                                    />
                                    <InputForm
                                        id="cmt_number"
                                        value={infos.cmt_number}
                                        onChange={(e) => setInfos({ ...infos, cmt_number: e.target.value })}
                                        label="Số chứng minh thư"
                                        type="number"
                                    />
                                    <InputForm
                                        id="cmt_address"
                                        value={infos.cmt_address}
                                        onChange={(e) => setInfos({ ...infos, cmt_address: e.target.value })}
                                        label="Nơi cấp"
                                    />
                                    <DropdownForm
                                        id="department_id"
                                        value={infos.department_id}
                                        onChange={(e) => setInfos({ ...infos, department_id: e.target.value })}
                                        options={exchanges}
                                        label="Phòng ban"
                                    />
                                    <DropdownForm
                                        id="group_id"
                                        value={infos.group_id}
                                        onChange={(e) => setInfos({ ...infos, group_id: e.target.value })}
                                        options={groups}
                                        label="Nhóm"
                                    />
                                    <InputSwitchForm
                                        checked={infos.status}
                                        onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </FormUpdate>
    )
}

export default UpdateUser
