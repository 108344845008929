import { useState, useEffect } from 'react'
import { updateUserApi, addUserApi } from '@/modules/companies/users/api'
import { removePropObject } from '@/utils'
import { InputForm, InputSwitchForm, FormUpdate, CalendarForm } from '@/components'
import { UploadImg } from '@/components'
import { useSelector } from 'react-redux'

const UpdateUser = () => {
    const user = useSelector((state) => state.userInfo)
    const userDetail = user.userInfo
    const [infos, setInfos] = useState({
        full_name: '',
        phone: '',
        email: '',
        address: '',
        cmt_number: '',
        status: true,
    })
    const [avatar, setAvatar] = useState(null)

    useEffect(() => {
        if (userDetail.id) setInfos({ ...infos, ...userDetail, password: '' })
    }, [userDetail])

    const handleData = () => {
        let info = { ...infos, status: infos.status ? 1 : 0 }
        info = { ...removePropObject(info, userDetail) }
        return info
    }

    return (
        <FormUpdate
            checkId={true}
            handleData={handleData}
            route="/user"
            actions={{ add: addUserApi, update: updateUserApi }}
            refreshObjects={[setInfos]}
        >
            <div className="grid grid-form">
                <div className="col-12 lg:col-3">
                    <div className="card">
                        <UploadImg image={avatar} setImage={setAvatar} title="Ảnh đại diện" />
                    </div>
                </div>
                <div className="col-12 lg:col-9">
                    <div className="card">
                        <InputForm
                            id="full_name"
                            value={infos.full_name}
                            onChange={(e) => setInfos({ ...infos, full_name: e.target.value })}
                            label="Tên nhân viên (*)"
                            required
                        />
                        <InputForm
                            id="email"
                            value={infos.email}
                            onChange={(e) => setInfos({ ...infos, email: e.target.value })}
                            label="Email (*)"
                            type="email"
                            required
                            disabled={true}
                        />
                        <InputForm
                            id="phone"
                            value={infos.phone}
                            onChange={(e) => setInfos({ ...infos, phone: e.target.value })}
                            label="Số điện thoại (*)"
                            type="phone"
                            required
                            disabled={true}
                        />
                        <CalendarForm
                            id="ccc"
                            label="Ngày sinh"
                            value={infos.date}
                            onChange={(e) => setInfos({ ...infos, date: e.target.value })}
                        />
                        <InputForm
                            id="address"
                            value={infos.address}
                            onChange={(e) => setInfos({ ...infos, address: e.target.value })}
                            label="Địa chỉ"
                        />
                        <InputForm
                            id="cmt_number"
                            value={infos.cmt_number}
                            onChange={(e) => setInfos({ ...infos, cmt_number: e.target.value })}
                            label="Số chứng minh thư"
                            type="number"
                        />
                        <InputForm
                            id="cmt_number"
                            value={infos.cmt_number}
                            onChange={(e) => setInfos({ ...infos, cmt_number: e.target.value })}
                            label="Nơi cấp"
                        />
                        <InputSwitchForm
                            checked={Boolean(infos.status)}
                            onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                        />
                    </div>
                </div>
            </div>
        </FormUpdate>
    )
}

export default UpdateUser
