import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDetailExchange } from '../utils'
import { addExchangeApi, updateExchangeApi } from '../api'
import { removePropObject } from '@/utils'
import {
    InputForm,
    FormUpdate,
    InputTextareaForm,
    DropdownForm,
    MultiRadioButton,
    InputSwitchForm,
    MultiSelectForm,
} from '@/components'
import { TabView, TabPanel } from 'primereact/tabview'
import Groups from '../../group/screens/Groups'
import { units } from '@/constants'
import { removeUndefinedProps } from '@/utils'
import { useListUserV2 } from '../../users/utils'
import { useListBuildingV2 } from '@/modules/resident_apartments/building_management/utils'

const initInfos = {
    name: '',
    phone_number: '',
    email: '',
    address: '',
    description: '',
    code_department: '',
    status: true,
    type: 1,
}

const ExchangeInfo = (props) => {
    const { infos, setInfos } = props
    const projects = useListBuildingV2()
    const users = useListUserV2()

    return (
        <div className="card bg-color">
            <div className="grid grid-form">
                <div className="col-12 lg:col-6">
                    <MultiRadioButton
                        className="w-full"
                        label="Đơn vị quản lý (*)"
                        options={units.slice(0, -1)}
                        value={infos.type}
                        onChange={(e) => setInfos({ ...infos, type: e })}
                    />
                    <InputForm
                        id="name"
                        value={infos.name}
                        onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                        label="Tên phòng ban, dự án (*)"
                        required
                    />
                    <InputForm
                        id="code_department"
                        value={infos.code_department}
                        onChange={(e) => setInfos({ ...infos, code_department: e.target.value })}
                        label="Mã phòng ban"
                    />
                    <MultiSelectForm
                        id="building_id"
                        value={infos.building_id}
                        onChange={(e) => setInfos({ ...infos, building_id: e.target.value })}
                        options={projects}
                        label="Dự án"
                    />
                </div>
                <div className="col-12 lg:col-6">
                    <InputForm
                        id="address"
                        value={infos.address}
                        onChange={(e) => setInfos({ ...infos, address: e.target.value })}
                        label="Địa chỉ (*)"
                        required
                    />
                    <InputForm
                        id="email"
                        value={infos.email}
                        onChange={(e) => setInfos({ ...infos, email: e.target.value })}
                        label="Email (*)"
                        required
                        type="email"
                    />
                    <InputForm
                        id="phone_number"
                        value={infos.phone_number}
                        onChange={(e) => setInfos({ ...infos, phone_number: e.target.value })}
                        label="Số điện thoại (*)"
                        required
                        type="phone"
                    />
                    <DropdownForm
                        id="main_user_id"
                        value={infos.main_user_id}
                        onChange={(e) => setInfos({ ...infos, main_user_id: e.target.value })}
                        options={users}
                        optionLabel="full_name"
                        optionValue="user_id"
                        label="Trưởng bộ phận"
                    />
                </div>
            </div>
            <InputTextareaForm
                id="description"
                value={infos.description}
                onChange={(e) => setInfos({ ...infos, description: e.target.value })}
                label="Ghi chú"
            />
            <InputSwitchForm
                id="status"
                checked={infos.status}
                onChange={(e) => setInfos({ ...infos, status: e.target.value })}
            />
        </div>
    )
}

const UpdateExchange = () => {
    const { id } = useParams()
    const exchangeDetail = useDetailExchange(id)
    const [infos, setInfos] = useState(initInfos)

    useEffect(() => {
        if (exchangeDetail.id)
            setInfos({
                ...infos,
                ...removeUndefinedProps(exchangeDetail),
                building_id: exchangeDetail.building_id ? JSON.parse(exchangeDetail.building_id) : undefined,
                status: Number(exchangeDetail.status) !== 0,
            })
    }, [exchangeDetail])

    const handleData = () => {
        let info = { ...infos, status: infos.status ? 1 : 0 }
        if (info.type === 2 && info.building_id && info.building_id[0])
            return 'Đơn vị quản lý là dự án thì chỉ được chọn một dự án!'
        if (Number(id)) info = { ...removePropObject(info, exchangeDetail), id: id }
        return info
    }

    return (
        <FormUpdate
            checkId={Number(id)}
            title="phòng ban / dự án"
            handleData={handleData}
            route="/exchange"
            actions={{ add: addExchangeApi, update: updateExchangeApi }}
            refreshObjects={[setInfos]}
        >
            <div className="card">
                <TabView>
                    <TabPanel header="Chi tiết">
                        <ExchangeInfo infos={infos} setInfos={setInfos} />
                    </TabPanel>
                    <TabPanel disabled={!Number(id)} header="Danh sách nhóm">
                        <Groups bdc_department_id={Number(id)} />
                    </TabPanel>
                </TabView>
            </div>
        </FormUpdate>
    )
}

export default UpdateExchange
