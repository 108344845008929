import React, { useEffect, useState } from 'react'
import {
    ActionBody,
    RenderHeader,
    Columnz,
    DataTablez,
    TimeBody,
    StatusBody,
    useGetParams,
    Dialogz,
    FormUpdate,
    InputForm,
    CalendarForm,
    InputSwitchForm,
    DropdownForm,
    Editorz,
    HeaderListForm,
} from '@/components'
import { Dropdownz, GridForm, Inputz } from '@/components'
import { status } from '@/constants'
import { useCountPost, useDetailPost, useListPost } from '../utils'
import { addPostApi, deletePostApi, updatePostApi } from '../api'
import { useParams } from 'react-router-dom'
import { removePropObject } from '@/utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập nội dung tìm kiếm"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.staus}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Danh mục"
            />
            <Dropdownz
                value={filter.staus}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Phân loại"
            />
            <Dropdownz
                value={filter.staus}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Người tạo"
            />
            <Dropdownz
                value={filter.staus}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Trạng thái"
            />
        </GridForm>
    )
}

export default function Posts() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListPost({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountPost({ status: undefined, ...params, first: undefined }) || 0

    const [visible, setVisible] = useState(false)

    const { id } = useParams()
    const postlDetail = useDetailPost(id)
    const [infos, setInfos] = useState({
        title: '',
        content: '',
        category: '',
        directory: '',
        video_link: '',
        upload_avatar: '',
        is_intro_post: true,
        status: true,
    })
    useEffect(() => {
        if (postlDetail.id) setInfos({ ...infos, ...postlDetail, status: postlDetail.status === 0 ? false : true })
    }, [postlDetail])

    const handleData = () => {
        let info = { ...infos }
        if (Number(id)) info = { ...removePropObject(info, postlDetail), id: id }
        return info
    }

    const RenderDialog = () => {
        return (
            <Dialogz title={'Thông tin bài viết'} visible={visible} setVisible={setVisible}>
                <FormUpdate
                    checkId={Number(id)}
                    // title="Tài sản"
                    handleData={handleData}
                    route="/asset_management"
                    actions={{ add: addPostApi, update: updatePostApi }}
                    refreshObjects={[setInfos]}
                >
                    <InputForm
                        id="name"
                        value={infos.name}
                        onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                        label="Tiêu đề(*)"
                    />
                    <Editorz
                        id="content"
                        value={infos.content}
                        onChange={(e) => setInfos({ ...infos, content: e.target.value })}
                        label="Nội dung"
                    />
                    <DropdownForm
                        id="category"
                        value={infos.category}
                        onChange={(e) => setInfos({ ...infos, category: e.target.value })}
                        label="Phân loại"
                    />
                    <DropdownForm
                        id="directory"
                        value={infos.directory}
                        onChange={(e) => setInfos({ ...infos, directory: e.target.value })}
                        label="Danh mục"
                    />
                    <InputForm
                        id="name"
                        value={infos.video_link}
                        onChange={(e) => setInfos({ ...infos, video_link: e.target.value })}
                        label="Link video"
                    />
                    <InputForm
                        type="file"
                        id="upload_avatar"
                        value={infos.upload_avatar}
                        onChange={(e) => setInfos({ ...infos, upload_avatar: e.target.value })}
                        label="Upload avatar"
                    />
                    <InputSwitchForm
                        id="is_intro_post"
                        checked={infos.is_intro_post}
                        onChange={(e) => setInfos({ ...infos, is_intro_post: e.target.value })}
                        label="Bài giới thiệu"
                    />
                    <InputSwitchForm
                        id="status"
                        checked={status}
                        onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                        label="Trạng thái"
                    />
                </FormUpdate>
            </Dialogz>
        )
    }

    return (
        <>
            <RenderDialog />
            <HeaderListForm title="Bài viết" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Bài viết"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/building_guide"
                setVisibledDialog={setVisible}
                actionsInfo={{ deleteAction: deletePostApi }}
            >
                <Columnz field="title" header="Tiêu đề " />
                <Columnz field="categogy" header="Danh mục" />
                <Columnz field="type" header="Phân loại" />
                <Columnz field="partner" header="Đối tác" />
                <Columnz field="creator" header="Người tạo" />
                <Columnz
                    header="Trạng thái"
                    body={(e) =>
                        StatusBody({
                            e,
                            route: '/building_guide',
                            updateAction: updatePostApi,
                        })
                    }
                />
            </DataTablez>
        </>
    )
}
