import React, { useEffect, useRef, useState } from 'react'
import {
    Calendarz,
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    RenderHeader,
    TimeBody,
    useGetParams,
    ActionBody,
    Inputz,
    HeaderListForm,
} from '@/components'
import { status } from '@/constants'
// import { useCountCompany, useListCompany } from '../utils'
// import { deleteCompanyApi, updateCompanyApi } from '../api'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'
import { Column } from 'primereact/column'
import { Chart } from 'primereact/chart'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Calendarz value={filter.status} className="lg:col-3" onChange showIcon placeholder="Thời gian" />
        </GridForm>
    )
}
function DebtReport() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const [chartData, setChartData] = useState({})
    const [chartOptions, setChartOptions] = useState({})
    const [chartDataMonth, setChartDataMonth] = useState({})
    const [chartOptionsMonth, setChartOptionsMonth] = useState({})
    const [chartDataPie, setChartDataPie] = useState({})
    const [chartOptionsPie, setChartOptionsPie] = useState({})
    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement)
        const textColor = documentStyle.getPropertyValue('--text-color')
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary')
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border')
        const data = {
            labels: [
                'Toà 17T1',
                'Toà 17T2',
                'Toà 17T3',
                'Toà 15T',
                'Toà 34T',
                'Toà 24T1',
                'Toà 17T4',
                'Toà 17T5',
                'Toà 17T6',
                'Toà 18T1',
                'Toà Bohemia',
                'Toà Vinata Tower',
                'Toà VinaConex 1',
                'New House Xala',
                'Nhà Ga T2',
                'N05 Hoàng.Đ.T',
            ],
            datasets: [
                {
                    type: 'line',
                    label: 'Tỉ lệ % thu công nợ',
                    borderColor: '#D34C19FF',
                    backgroundColor: '#D34C19FF',
                    borderWidth: 2,
                    fill: false,
                    tension: 0.4,
                    data: [
                        1500, 1300, 1500, 1300, 1250, 1400, 1650, 1550, 1600, 1700, 1350, 1400, 1500, 1700, 1900, 1600,
                    ],
                    steppedLine: true,
                },
                {
                    type: 'bar',
                    label: 'Công nợ đã thu',
                    backgroundColor: '#165BAA',
                    data: [1000, 900, 950, 810, 790, 750, 1200, 1000, 1100, 820, 700, 1150, 1100, 820, 650, 1200],
                    borderColor: 'white',
                    borderWidth: 1,
                    barPercentage: 0.6,
                },
                {
                    type: 'bar',
                    label: 'Công nợ phải thu',
                    backgroundColor: '#5711d0',
                    data: [200, 150, 150, 180, 350, 100, 150, 100, 150, 200, 250, 170, 100, 200, 220, 150],
                    borderWidth: 1,
                    barPercentage: 0.6,
                },
            ],
        }
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                legend: {
                    labels: {
                        color: textColor,
                    },
                },
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                },
                y: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                },
            },
        }

        setChartData(data)
        setChartOptions(options)
    }, [])
    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement)
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary')
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border')
        const data = {
            labels: ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6'],
            datasets: [
                {
                    type: 'line',
                    label: 'Tỉ lệ % thu công nợ',
                    borderColor: '#D34C19FF',
                    backgroundColor: '#D34C19FF',
                    borderWidth: 2,
                    fill: false,
                    tension: 0.4,
                    data: [1500, 1300, 1500, 1300, 1250, 1400],
                    steppedLine: true,
                },
                {
                    type: 'bar',
                    label: 'Công nợ đã thu',
                    backgroundColor: '#165BAA',
                    data: [1000, 900, 950, 810, 790, 750, 1200, 1000, 1100, 820, 700, 1150, 1100, 820, 650, 1200],
                    borderColor: 'white',
                    borderWidth: 1,
                    barPercentage: 0.4,
                },
                {
                    type: 'bar',
                    label: 'Công nợ phải thu',
                    backgroundColor: '#5711d0',
                    data: [200, 150, 150, 180, 350, 100, 150, 100, 150, 200, 250, 170, 100, 200, 220, 150],
                    borderWidth: 1,
                    barPercentage: 0.4,
                },
            ],
        }
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    position: 'bottom',
                },
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                    title: {
                        display: true,
                    },
                },
                y: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                    title: {
                        display: true,
                        text: 'Trăm triệu đồng',
                    },
                },
                y1: {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        drawOnChartArea: false,
                        color: surfaceBorder,
                    },
                },
            },
        }
        setChartDataMonth(data)
        setChartOptionsMonth(options)
    }, [])
    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement)
        const data = {
            labels: [
                'Phí dịch vụ cư dân',
                'Phí Vận Hành TMDV',
                'Phí dịch vụ TM',
                'Chi Phí Điện',
                'Phí dịch vụ hầm',
                'Chi Phí nước',
                'Chi Phí xe tháng',
                'Chi phí quảng cáo',
                'Chi phí Kích sóng',
                'Phí khác',
            ],
            datasets: [
                {
                    data: [20, 15, 5, 7, 5, 6, 10, 7, 6, 8],
                    backgroundColor: [
                        documentStyle.getPropertyValue('--blue-500'),
                        documentStyle.getPropertyValue('--yellow-500'),
                        documentStyle.getPropertyValue('--green-500'),
                        documentStyle.getPropertyValue('--orange-500'),
                        documentStyle.getPropertyValue('--purple-500'),
                        documentStyle.getPropertyValue('--violet-500'),
                    ],
                    hoverBackgroundColor: [
                        documentStyle.getPropertyValue('--blue-400'),
                        documentStyle.getPropertyValue('--yellow-400'),
                        documentStyle.getPropertyValue('--green-400'),
                    ],
                },
            ],
        }
        const options = {
            cutout: '60%',
        }

        setChartDataPie(data)
        setChartOptionsPie(options)
    }, [])

    return (
        <>
            <div className="card">
                <HeaderListForm title="Báo cáo công nợ" />
                <Header setParams={setParams} />
                <Chart type="bar" data={chartData} options={chartOptions} />
            </div>
            <div className="card">
                <Header setParams={setParams} />

                <div class="grid align-items-center">
                    <div class="col-12 md:col-6 lg:col-8">
                        <div class="text-center p-3 border-round-sm  font-bold">
                            <Chart type="line" data={chartDataMonth} options={chartOptionsMonth} />
                        </div>
                    </div>
                    <div class="col-12 md:col-6 lg:col-4">
                        <div class="text-center p-3 border-round-sm h-full  font-bold">
                            <Chart type="doughnut" data={chartDataPie} options={chartOptionsPie} className="w-full " />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DebtReport
