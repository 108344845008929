export const createFormData = (body = {}, file, files, avatar, cmt_image) => {
    const data = new FormData()
    if (file) {
        data.append('file', file)
    }
    if (avatar) {
        data.append('avatar', avatar)
    }
    if (files) {
        Object.keys(files).forEach((value) => {
            data.append(value, files[value])
        })
    }
    if (cmt_image) {
        data.append('cmt_image', cmt_image)
    }
    Object.keys(body).forEach((key) => {
        if (typeof body[key] === 'object') data.append(key, JSON.stringify(body[key]))
        else if (body[key] || body[key] === 0 || body[key] === '') data.append(key, body[key])
    })
    return data
}
export const convertData = (body = {}) => {
    Object.keys(body).forEach((key) => {
        if (typeof body[key] === 'object') {
            body[key] = JSON.stringify(body[key])
        }
    })
    return body
}
