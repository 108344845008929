import { ActionBody, Columnz, DataTablez, Dropdownz, GridForm, HeaderListForm, RenderHeader, useGetParams } from '@/components'
import { status } from '@/constants'
import { useState } from 'react'
import { deletePriceApi } from '../api'
import { useCountPrice, useListPrice } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Dịch vụ"
            />
        </GridForm>
    )
}

const ListPrice = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListPrice({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountPrice({ status: undefined, ...params, first: undefined }) || 0

    return (
        <div className="card">
            <HeaderListForm title="Bảng giá" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Tòa nhà"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/list_price"
                actionsInfo={{ deleteAction: deletePriceApi }}
            >
                <Columnz field="name" header="Mã" />
                <Columnz field="address" header="Tên" />
                <Columnz field="phone" header="Loại" />
                <Columnz field="email" header="Dịch vụ" />
                <Columnz field="phone" header="Ngày áp dụng" />
            </DataTablez>
        </div>
    )
}

export default ListPrice
