import { clientApi } from '@/axios'
import { convertData, createFormData } from './request'

export const postData = (url, data, isUpload = false, timeout = 600000) => {
    if (isUpload) {
        const { file, files, avatar, cmt_image, ...params } = data
        data = createFormData(params, file, files, avatar, cmt_image)
    } else data = convertData(data)
    return clientApi.post(
        url,
        data,
        isUpload ? { timeout, headers: { 'Content-Type': 'multipart/form-data' } } : { timeout },
    )
}

export const getData = (url, params) => {
    params = convertData(params)
    return clientApi.get(url, { params })
}
