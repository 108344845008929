import { DropdownForm, FormUpdate, InputForm } from '@/components'
import { removePropObject } from '@/utils'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { addRatedServiceApi, updateRatedServiceApi } from '../api'
import { useDetailRatedService } from '../utils'

const UpdateRatedService = () => {
    const { id } = useParams()
    const ratedServiceDetail = useDetailRatedService(id)
    const [infos, setInfos] = useState({
        nameRatedServicePlace: '',
        statusRatedServicePlace: '',
        codeRatedServicePlace: '',
    })

    useEffect(() => {
        if (ratedServiceDetail.id) setInfos({ ...infos, ...ratedServiceDetail, password: '' })
    }, [ratedServiceDetail])

    const handleData = () => {
        let info = { ...infos, status: infos.status ? 1 : 0 }
        if (Number(id)) info = { ...removePropObject(info, ratedServiceDetail), id: id }
        return info
    }

    return (
        <FormUpdate
            checkId={Number(id)}
            title="tòa nhà"
            handleData={handleData}
            route="rated_service"
            actions={{ add: addRatedServiceApi, update: updateRatedServiceApi }}
            refreshObjects={[setInfos]}
        >
            <div className="card bg-color">
                <div style={{ maxWidth: '1200px' }}>
                    <InputForm
                        id="nameRatedServicePlace"
                        value={infos.nameRatedServicePlace}
                        onChange={(e) => setInfos({ ...infos, nameRatedServicePlace: e.target.value })}
                        label="Tên tòa nhà (*)"
                        required
                    />
                    <InputForm
                        id="statusRatedServicePlace"
                        value={infos.statusRatedServicePlace}
                        onChange={(e) => setInfos({ ...infos, statusRatedServicePlace: e.target.value })}
                        label="Địa chỉ (*)"
                        required
                    />
                    <DropdownForm
                        value={infos.codeRatedServicePlace}
                        onChange={(e) => setInfos({ ...infos, codeRatedServicePlace: e.target.value })}
                        label="Mã tòa nhà (*)"
                    />
                </div>
            </div>
        </FormUpdate>
    )
}

export default UpdateRatedService
