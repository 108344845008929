import {
    CalendarForm,
    Columnz,
    DataTablez,
    Dialogz,
    DropdownForm,
    Dropdownz,
    FormUpdate,
    GridForm,
    HeaderListForm,
    InputForm,
    InputSwitchForm,
    InputTextareaForm,
    Inputz,
    RenderHeader,
    useGetParams,
} from '@/components'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { removePropObject } from '@/utils'
import { addVehicleApi, deleteVehicleApi, updateVehicleApi } from '../api'
import { useCountVehicle, useDetailVehicle, useListVehicle } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-12">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo tên"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.key_search}
                placeholder="Chọn tòa nhà"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.key_search}
                placeholder="Loại phương tiện"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.key_search}
                placeholder="Loại phương tiện"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
        </GridForm>
    )
}

const ListVehicleManage = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const [visible, setVisible] = useState(false)
    const [selectedProducts, setSelectedProducts] = useState([])
    const data = useListVehicle({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountVehicle({ status: undefined, ...params, first: undefined }) || 0
    const navigate = useNavigate()

    const { id } = useParams()
    const vehicleDetail = useDetailVehicle(id)
    const [infos, setInfos] = useState({
        apartment_name: '',
        building_place_id: '',
        floor: '',
        area: '',
        description: '',
        code_electric: '',
        code_water: '',
        status: '',
        code: '',
        code_customer: '',
        name_customer: '',
    })

    useEffect(() => {
        if (vehicleDetail.id)
            setInfos({ ...infos, ...vehicleDetail, status: vehicleDetail.status === 0 ? false : true })
    }, [vehicleDetail])

    const handleData = () => {
        let info = { ...infos }
        if (Number(id)) info = { ...removePropObject(info, vehicleDetail), id: id }
        return info
    }

    const items = [
        {
            label: 'Xóa',
            icon: 'pi pi-trash',
            command: () => {},
        },
    ]

    const moreOptions = [
        { label: 'Chọn mẫu bảng kê', icon: 'pi pi-table', command: () => navigate('/') },
        { label: 'Chọn mẫu phiếu thu', icon: 'pi pi-table', command: () => navigate('/') },
    ]

    const RenderDialog = () => {
        return (
            <Dialogz title={'Phương tiện'} visible={visible} setVisible={setVisible}>
                <FormUpdate
                    checkId={Number(id)}
                    // title="Phương tiện"
                    handleData={handleData}
                    route="/vehicle_manage"
                    actions={{ add: addVehicleApi, update: updateVehicleApi }}
                    refreshObjects={[setInfos]}
                >
                    <InputForm
                        id="nameVehicleManage"
                        value={infos.nameVehicleManage}
                        onChange={(e) => setInfos({ ...infos, nameVehicleManage: e.target.value })}
                        label="Tên phương tiện"
                        required
                    />
                    <DropdownForm
                        id="nameVehicleManage"
                        value={infos.nameVehicleManage}
                        onChange={(e) => setInfos({ ...infos, nameVehicleManage: e.target.value })}
                        label="Tòa nhà"
                        required
                    />
                    <DropdownForm
                        id="nameVehicleManage"
                        value={infos.nameVehicleManage}
                        onChange={(e) => setInfos({ ...infos, nameVehicleManage: e.target.value })}
                        label="Căn hộ"
                        required
                    />
                    <DropdownForm
                        id="nameVehicleManage"
                        value={infos.nameVehicleManage}
                        onChange={(e) => setInfos({ ...infos, nameVehicleManage: e.target.value })}
                        label="Loại phương tiện"
                        required
                    />
                    <InputForm
                        id="nameVehicleManage"
                        value={infos.nameVehicleManage}
                        onChange={(e) => setInfos({ ...infos, nameVehicleManage: e.target.value })}
                        label="Biển số(nếu có)"
                        required
                    />
                    <InputTextareaForm
                        id="nameVehicleManage"
                        value={infos.nameVehicleManage}
                        onChange={(e) => setInfos({ ...infos, nameVehicleManage: e.target.value })}
                        label="Mô tả"
                        required
                    />
                    <CalendarForm
                        id="nameVehicleManage"
                        value={infos.nameVehicleManage}
                        onChange={(e) => setInfos({ ...infos, nameVehicleManage: e.target.value })}
                        label="Ngày áp dụng"
                        required
                    />
                    <CalendarForm
                        id="nameVehicleManage"
                        value={infos.nameVehicleManage}
                        onChange={(e) => setInfos({ ...infos, nameVehicleManage: e.target.value })}
                        label="Ngày tính phí"
                        required
                    />
                    <InputForm
                        id="nameVehicleManage"
                        value={infos.nameVehicleManage}
                        onChange={(e) => setInfos({ ...infos, nameVehicleManage: e.target.value })}
                        label="Trạng thái"
                        required
                    />
                    <InputSwitchForm checked={true} />
                </FormUpdate>
            </Dialogz>
        )
    }

    return (
        <>
            <RenderDialog />
            <HeaderListForm title="Danh sách phương tiện" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Phương tiện"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/vehicle_manage"
                setVisibledDialog={setVisible}
                headerInfo={{ items, moreOptions }}
                actionsInfo={{ deleteAction: deleteVehicleApi }}
            >
                <Columnz field="name" header="Căn hộ" />
                <Columnz field="address" header="Tên phương tiện" />
                <Columnz field="phone" header="Loại phương tiện" />
                <Columnz field="email" header="Biển số" />
                <Columnz field="phone" header="Mã thẻ" />
                <Columnz field="phone" header="Mức ưu tiên" />
                <Columnz field="phone" header="Phí" />
                <Columnz field="phone" header="Ngày bắt đầu tính phí" />
                <Columnz field="phone" header="Ngày kết thúc tính phí" />
                <Columnz field="phone" header="Mô tả" />
                <Columnz field="phone" header="Trạng thái" />
                <Columnz field="phone" header="Người cập nhật" />
            </DataTablez>
        </>
    )
}

export default ListVehicleManage
