import React, { useEffect, useState } from 'react'
import {
    RenderHeader,
    TimeBody,
    Columnz,
    StatusBody,
    DataTablez,
    ActionBody,
    Dropdownz,
    GridForm,
    Inputz,
    Dialogz,
    FormUpdate,
    InputForm,
    InputTextareaForm,
    HeaderListForm,
} from '@/components'
import { useGetParams } from '@/components'
import { status } from '@/constants'
import { useCountAssetArea, useDetailAssetArea, useListAssetArea } from '../utils'
import { addAssetAreaApi, deleteAssetAreaApi, updateAssetAreaApi } from '../api'
import { useParams } from 'react-router-dom'
import { removePropObject } from '@/utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập từ khóa"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
        </GridForm>
    )
}

export default function AssetArea() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListAssetArea({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountAssetArea({ status: undefined, ...params, first: undefined }) || 0
    const [visible, setVisible] = useState(false)

    const { id } = useParams()
    const assetAreaDetail = useDetailAssetArea(id)
    const [infos, setInfos] = useState({
        area_name: '',
        building_name: '',
        floor: '',
    })
    useEffect(() => {
        if (assetAreaDetail.id)
            setInfos({ ...infos, ...assetAreaDetail, status: assetAreaDetail.status === 0 ? false : true })
    }, [assetAreaDetail])

    const handleData = () => {
        let info = { ...infos }
        if (Number(id)) info = { ...removePropObject(info, assetAreaDetail), id: id }
        return info
    }

    const RenderDialog = () => {
        return (
            <Dialogz title={'Khu vực tài sản'} visible={visible} setVisible={setVisible}>
                <FormUpdate
                    checkId={Number(id)}
                    // title="Khu vực tài sản"
                    handleData={handleData}
                    route="/asset_management"
                    actions={{ add: addAssetAreaApi, update: updateAssetAreaApi }}
                    refreshObjects={[setInfos]}
                >
                    <InputForm
                        id="area_name"
                        value={infos.name}
                        onChange={(e) => setInfos({ ...infos, area_name: e.target.value })}
                        label="Tên khu vực"
                        required
                    />
                    <InputForm
                        id="building_name"
                        value={infos.building_name}
                        onChange={(e) => setInfos({ ...infos, building_name: e.target.value })}
                        label="Tòa nhà"
                        required
                    />
                    <InputForm
                        id="floor"
                        value={infos.floor}
                        onChange={(e) => setInfos({ ...infos, floor: e.target.value })}
                        label="Tầng"
                        required
                    />
                </FormUpdate>
            </Dialogz>
        )
    }
    return (
        <>
            <RenderDialog />
            <HeaderListForm title="Khu vực tài sản" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Tòa nhà"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/asset_management"
                setVisibledDialog={setVisible}
                actionsInfo={{ deleteAction: deleteAssetAreaApi }}
            >
                <Columnz field="name" header="Tên tòa" />
                <Columnz field="buildingName" header="Tên tòa" />
                <Columnz field="floor" header="Tầng" />
            </DataTablez>
        </>
    )
}
