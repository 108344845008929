import React, { useState } from 'react'
import {
    ActionBody,
    RenderHeader,
    Columnz,
    DataTablez,
    TimeBody,
    StatusBody,
    useGetParams,
    HeaderListForm,
} from '@/components'
import { Dropdownz, GridForm, Inputz } from '@/components'
import { status } from '@/constants'
import { useCountConfig, useCountReceptForm, useListConfig, useListReceptForm } from '../utils'
import { deleteConfigApi, deleteReceptFormApi, updateConfigApi, updateReceptFormApi } from '../api'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập từ khóa"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
        </GridForm>
    )
}

export default function ConfigList() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListReceptForm({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountReceptForm({ status: undefined, ...params, first: undefined }) || 0
    const [selectedProducts, setSelectedProducts] = useState([])

    const items = [
        {
            label: 'Xóa',
            icon: 'pi pi-trash',
            command: () => {},
        },
        {
            label: 'Active',
            icon: 'pi pi-check',
            command: () => {},
        },
        {
            label: 'Inactive',
            icon: 'pi pi-times',
            command: () => {},
        },
    ]

    return (
        <div className="card">
            <HeaderListForm title="Hình thức phiếu thu" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="phiếu thu"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                selectedProducts={selectedProducts}
                route="/recept_form"
                setSelectedProducts={setSelectedProducts}
                headerInfo={{ items }}
                actionsInfo={{ deleteAction: deleteReceptFormApi }}
            >
                <Columnz field="recept_form" header="Kiểu phiếu" />
                <Columnz field="type" header="Phân loại" />
                <Columnz field="update" header="Cập nhật" />
                <Columnz field="building_id" header="Building_id" />
                <Columnz
                    header="Trạng thái"
                    body={(e) =>
                        StatusBody({
                            e,
                            route: '/recept_form',
                            updateAction: updateReceptFormApi,
                        })
                    }
                />
            </DataTablez>
        </div>
    )
}
