import {
    ActionBody,
    Columnz,
    DataTablez,
    Dialogz,
    DropdownForm,
    Dropdownz,
    FormUpdate,
    GridForm,
    HeaderListForm,
    InputForm,
    InputTextareaForm,
    Inputz,
    RenderHeader,
    useGetParams,
} from '@/components'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { removePropObject } from '@/utils'
import { useCountPaymentMethod, useDetailPaymentMethod, useListPaymentMethod } from '../utils'
import { addPaymentMethodApi, deletePaymentMethodApi, updatePaymentMethodApi } from '../api'
import { banking } from '@/constants'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    const listCategoryData = [
        { id: 1, name: 'Thiết bị điện' },
        { id: 2, name: 'Đồ gia dụng' },
        { id: 3, name: 'Phòng cháy chữa cháy' },
        { id: 4, name: 'Đồ điện tử' },
        { id: 5, name: 'Đồ Thông Cống' },
        { id: 6, name: 'Thiết bị thông minh' },
    ]

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập từ khóa"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.category}
                options={listCategoryData}
                onChange={(e) => setFilter({ ...filter, category: e.target.value })}
                placeholder="Chọn danh mục"
            />
        </GridForm>
    )
}

export default function PaymentMethod() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListPaymentMethod({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountPaymentMethod({ status: undefined, ...params, first: undefined }) || 0
    const [visible, setVisible] = useState(false)

    const { id } = useParams()
    const paymentMethodDetail = useDetailPaymentMethod(id)
    const [infos, setInfos] = useState({
        banking_method: '',
        fixed_fee_per_transaction: 0,
        transaction_fee: 0,
    })
    useEffect(() => {
        if (paymentMethodDetail.id)
            setInfos({ ...infos, ...paymentMethodDetail, status: paymentMethodDetail.status === 0 ? false : true })
    }, [paymentMethodDetail])

    const handleData = () => {
        let info = { ...infos }
        if (Number(id)) info = { ...removePropObject(info, paymentMethodDetail), id: id }
        return info
    }

    const RenderDialog = () => {
        return (
            <Dialogz title={'Phương thức thanh toán'} visible={visible} setVisible={setVisible}>
                <FormUpdate
                    checkId={Number(id)}
                    // title="Phương thức"
                    handleData={handleData}
                    route="/building_overview"
                    actions={{ add: addPaymentMethodApi, update: updatePaymentMethodApi }}
                    refreshObjects={[setInfos]}
                >
                    <DropdownForm
                        id="banking_method"
                        value={infos.banking_method}
                        optionLabel="name"
                        options={banking}
                        onChange={(e) => setInfos({ ...infos, banking_method: e.target.value })}
                        label="Ngân hàng"
                        placeholder="Chọn ngân hàng"
                    />
                    <InputForm
                        id="fixed_fee_per_transaction"
                        value={infos.fixed_fee_per_transaction}
                        onChange={(e) => setInfos({ ...infos, fixed_fee_per_transaction: e.target.value })}
                        label="Phí cố định(vnđ)"
                    />
                    <InputForm
                        id="transaction_fee"
                        value={infos.type_maintain}
                        onChange={(e) => setInfos({ ...infos, transaction_fee: e.target.value })}
                        label="Phí giao dịch(%)"
                    />
                </FormUpdate>
            </Dialogz>
        )
    }
    return (
        <>
            <RenderDialog />
            <HeaderListForm title="Phương thức thanh toán" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Phương thức"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/building_overview"
                setVisibledDialog={setVisible}
                actionsInfo={{ deleteAction: deletePaymentMethodApi }}
            >
                <Columnz field="method" header="Phương thức" />
                <Columnz field="categogy" header="Phí cố định/giao dịch(VND)" />
                <Columnz field="typeMaintain" header="Phí giao dịch(%) " />
            </DataTablez>
        </>
    )
}
