import { useState, useEffect } from 'react'
import { listToolApi, countToolApi, detailToolApi } from '../api'

export const useListTool = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listToolApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountTool = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countToolApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailTool = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailToolApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
