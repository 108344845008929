import React, { useRef, useState } from 'react'
import {
    Calendarz,
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    RenderHeader,
    TimeBody,
    useGetParams,
    ActionBody,
    Inputz,
    HeaderListForm,
} from '@/components'
import { status } from '@/constants'
import { useCountCompany, useListCompany } from '../utils'
import { deleteCompanyApi, updateCompanyApi } from '../api'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'
import { Column } from 'primereact/column'
const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-12">
            <Inputz placeholder="Tìm kiếm từ khóa" />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Hình thức"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Kiểu phiếu"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn tòa nhà"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn căn hộ"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Danh mục"
            />
            <Calendarz value={filter.status} className="lg:col-3" onChange showIcon placeholder="Từ ngày" />
            <Calendarz value={filter.status} className="lg:col-3" onChange showIcon placeholder="Từ ngày" />
        </GridForm>
    )
}
function DepositList() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListCompany({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountCompany({ status: undefined, ...params, first: undefined }) || 0
    return (
        <div className="card">
            <HeaderListForm title="Quản lý phiếu thu" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                route="/deposit_list"
                title="phiếu thu"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
            >
                <Columnz field="" header="Mã chứng từ" />
                <Columnz field="" header="Hình thức" />
                <Columnz field="" header="Loại phiếu" />
                <Columnz field="" header="Ngày lập phiếu" />
                <Columnz field="" header="Ngày hạch toán" />
                <Columnz field="" header="Căn hộ" />
                <Columnz field="" header="Tòa" />
                <Columnz field="" header="Khách hàng" />
                <Columnz field="" header="Số tiền" />
                <Columnz field="" header="Nội dung" />
                <Columnz field="" header="Người tạo" />
                <Columnz field="" header="Danh mục" />
                <Columnz
                    field=""
                    header="Thao tác"
                    body={(e) =>
                        ActionBody({
                            id: e.id,
                            route: '/deposit_list',
                            deleteAction: deleteCompanyApi,
                            params,
                            setParams,
                        })
                    }
                />
                <Columnz field="" header="In phiếu" />
            </DataTablez>
        </div>
    )
}

export default DepositList
