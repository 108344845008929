import React, { useEffect, useState } from 'react'
import MenuSidebar from './MenuSidebar'
import { MenuProvider } from './context/menucontext'
import { useDispatch, useSelector } from 'react-redux'
import { setItem, setLoading, setToast } from '@/redux/features'
import { TreeSelect } from 'primereact/treeselect'
import { listToast } from '@/constants'
import { useNavigate } from 'react-router-dom'

const handleTreeSelect = (data) => {
    let newData = []
    if (data && data[0]) {
        newData = data.map((d) => {
            const children =
                d.buildings && d.buildings[0]
                    ? d.buildings.map((b) => {
                          return { key: d.id + '-' + b.id, label: b.name, icon: 'pi pi-building' }
                      })
                    : []
            return { key: d.id + '', label: d.name, children }
        })
    }
    return newData
}

const HeaderSideBar = (props) => {
    const navigate = useNavigate()
    const user = useSelector((state) => state.userInfo) || {}
    const { data, setVisible, item } = props
    const [selectedNodeKey, setSelectedNodeKey] = useState(() => {
        return item.building_id ? item.company_id + '-' + item.building_id : item.company_id
    })
    const dispatch = useDispatch()
    const handleNodeChange = (e) => {
        setSelectedNodeKey(e.value)
        const company_id = e.value && e.value.includes('-') ? e.value.split('-')[0] : e.value
        const building_id = e.value && e.value.includes('-') ? e.value.split('-')[1] : undefined
        const obj = { company_id: Number(company_id), building_id: Number(building_id) }
        dispatch(setToast({ ...listToast[0], detail: 'Đổi dự án thành công!' }))
        localStorage.setItem('item', JSON.stringify(obj))
        dispatch(setItem(obj))
        dispatch(setLoading())
        navigate('/')
    }

    return (
        <div className="header-sidebar">
            <div className="w-full grid grid-form align-item-center mb-4 mt-4">
                <div className="col-4 text-right cursor-pointer" onClick={() => setVisible(true)}>
                    <img src="/assets/img/profile.png" height="48px" width="48px" alt="Ảnh đại diện"></img>
                </div>
                <div className="col-8 text-left">
                    <b>Tên: {user && user.userInfo && user.userInfo.full_name}</b>
                    <div className="mt-1">
                        <i className="pi pi-circle-fill mr-2" style={{ color: '#28a745' }} />
                        <b>Online</b>
                    </div>
                </div>
            </div>
            <div className="w-10 text-color mx-auto mt-0 text-center">
                <label htmlFor="company_project" className="font-bold text-white">
                    Dự án quản lý
                </label>
                <TreeSelect
                    panelStyle={{ maxWidth: '50px', padding: '0' }}
                    inputId="company_project"
                    value={selectedNodeKey}
                    onChange={(e) => handleNodeChange(e)}
                    options={data}
                    className="w-full mt-2"
                    placeholder="Chọn dự án"
                    filter
                ></TreeSelect>
            </div>
        </div>
    )
}

const AppSidebar = ({ setVisible }) => {
    const myTool = useSelector((state) => state.myTool) || {}
    const item = useSelector((state) => state.item) || {}
    const [model, setModel] = useState([])
    const [data, setData] = useState([])

    useEffect(() => {
        if (myTool.companies && myTool.companies[0]) {
            const newData = handleTreeSelect(myTool.companies)
            setData([...newData])
        }
        if (myTool.myMenuTool && myTool.myMenuTool[0]) {
            const type = item.building_id ? 'building' : 'company'
            const newData = myTool.myMenuTool.filter((m) => m.type === type || !m.type)
            setModel([{ children: newData }])
        }
    }, [myTool])

    return (
        <MenuProvider>
            <HeaderSideBar item={item} setVisible={setVisible} data={data} />
            <ul className="layout-menu">
                {model.map((item, i) => {
                    return <MenuSidebar item={item} root={true} index={i} key={i} />
                })}
            </ul>
        </MenuProvider>
    )
}

export default AppSidebar
