import { useEffect, useState } from 'react'
import { listPartnerApi, countPartnerApi, detailPartnerApi } from '../api'

export const useListPartner = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listPartnerApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountPartner = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countPartnerApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailPartner = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailPartnerApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
