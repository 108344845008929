import React, { useState } from 'react'
import { TabPanel, TabView } from 'primereact/tabview'
import AssetDetail from './AssetDetail'
import AssetCategory from './AssetCategory'
import AssetArea from './AssetArea'
import Asset from './Asset'

export default function AssetManagement() {
    return (
        <div className="card">
            <TabView>
                <TabPanel header="Tài sản">
                    <Asset />
                </TabPanel>
                <TabPanel header="Chi tiết tài sản">
                    <AssetDetail />
                </TabPanel>
                <TabPanel header="Danh mục tài sản">
                    <AssetCategory />
                </TabPanel>
                <TabPanel header="Khu vực tài sản">
                    <AssetArea />
                </TabPanel>
            </TabView>
        </div>
    )
}
