import { configureStore } from '@reduxjs/toolkit'
import toastSlice from './features/toast'
import userSlice from './features/userInfo'
import itemSlice from './features/item'
import myToolSlice from './features/myTool'
import loadingSlice from './features/loading'
import usersSlice from './features/users'

const store = configureStore({
    reducer: {
        loading: loadingSlice,
        toast: toastSlice,
        userInfo: userSlice,
        item: itemSlice,
        myTool: myToolSlice,
        users: usersSlice,
    },
})

export default store
export const reduxStage = store.getState()
