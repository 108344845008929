import {
    ActionBody,
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    HeaderListForm,
    Inputz,
    RenderHeader,
    useGetParams,
} from '@/components'
import { status } from '@/constants'
import { useState } from 'react'
import { deleteServiceRequestApi } from '../api'
import { useCountServiceRequest, useListServiceRequest } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-12">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo email, số điện thoại"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn căn hộ"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Loại yêu cầu"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn trạng thái"
            />
        </GridForm>
    )
}

const ServiceRequests = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListServiceRequest({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountServiceRequest({ status: undefined, ...params, first: undefined }) || 0

    return (
        <div className="card">
            <HeaderListForm title="Danh sách Yêu cầu dịch vụ" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="yêu cầu"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/service_request"
                actionsInfo={{ deleteAction: deleteServiceRequestApi }}
            >
                <Columnz field="name" header="ID" />
                <Columnz field="address" header="Tiêu đề" />
                <Columnz field="name" header="Thông tin chi tiết" />
                <Columnz field="address" header="Căn hộ" />
                <Columnz field="name" header="Tầng" />
                <Columnz field="address" header="Tòa nhà" />
                <Columnz field="name" header="Người lập" />
                <Columnz field="phone" header="Trạng thái" />
            </DataTablez>
        </div>
    )
}

export default ServiceRequests
