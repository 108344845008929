import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { TreeSelect } from 'primereact/treeselect'
import { useEffect, useState } from 'react'
import { useListExchangeV2 } from '@/modules/companies/exchange/utils'
import { useListGroupV2 } from '@/modules/companies/group/utils'

export const formatTreeSelectValue = (array) => {
    let newObject = {}
    array.forEach((a, index) => {
        newObject[`${a}`] = { checked: true, partialChecked: true, sort: index }
    })
    return newObject
}

export const getArrId = (arr) => {
    const newArr = []
    if (arr && arr[0]) {
        arr.forEach((a) => {
            if (a.user_id) {
                newArr.push(a.user_id)
            } else {
                newArr.push(a.id)
            }
        })
    }
    return newArr
}

export const getArrIdFromTreeSelect = (object) => {
    let arr = []
    let newArr = []
    for (let key in object) {
        if (!key.includes('-') && object[key] && object[key].checked) {
            if (object[key] && (object[key].sort === 0 || object[key].sort)) {
                arr[Number(object[key].sort)] = Number(key)
            } else newArr.push(Number(key))
        }
    }
    return arr.concat(newArr)
}

const handleTreeData = (arr1, arr2, key) => {
    key = key || 'id'
    const result = []
    if (arr2 && arr2[0]) {
        arr2.forEach((a2) => {
            if (a2[key]) {
                const index = result.findIndex((r) => r.key === key + '-' + a2[key])
                const obj = arr1.find((a1) => a1.id === a2[key]) || {}
                if (index < 0) {
                    result.push({
                        ...obj,
                        key: key + '-' + a2[key],
                        label: obj.name,
                        children: [
                            {
                                ...a2,
                                key: a2.user_id || a2.key || a2.id,
                                label: a2.name || a2.full_name + ' - ' + a2.code,
                            },
                        ],
                    })
                } else {
                    result[index].children.push({
                        ...a2,
                        key: a2.user_id || a2.key || a2.id,
                        label: a2.name || a2.full_name + ' - ' + a2.code,
                    })
                }
            }
        })
    }
    return result
}

export const ListUser = (props) => {
    const { users, data, setData } = props
    const exchanges = useListExchangeV2()
    const groups = useListGroupV2()
    const [saleTree, setSaleTree] = useState([])
    const [selectTree, setSelectTree] = useState([])
    const totalRecords = data.length

    useEffect(() => {
        if (exchanges[0] && groups[0] && users[0]) {
            setSaleTree([...handleTreeData(exchanges, handleTreeData(groups, users, 'group_id'), 'bdc_department_id')])
        }
    }, [exchanges, groups, users])

    const onChange = (e) => {
        let idArr = getArrIdFromTreeSelect(e.value)
        const newData = data.filter((obj) => idArr.includes(obj.id))
        idArr.forEach((id) => {
            const isIdExist = newData.some((obj) => obj.id === id)
            if (!isIdExist) {
                users.forEach((u) => {
                    if (u.user_id === id) newData.push(u)
                })
            }
        })
        setSelectTree(e.value)
        setData([...newData])
    }

    useEffect(() => {
        setSelectTree(formatTreeSelectValue(getArrId(data)))
    }, [data])

    const actionBody = (e) => {
        const handleDelete = () => {
            setData([...data.filter((d) => d !== e)])
        }
        return <Button type="button" icon="pi pi-trash" onClick={handleDelete} rounded outlined severity="danger" />
    }

    return (
        <>
            <div className="grid grid-form mb-2">
                <div className="col-12 lg:col-6"></div>
                <div className="col-12 lg:col-6">
                    <TreeSelect
                        value={selectTree}
                        filter
                        onChange={onChange}
                        options={saleTree}
                        metaKeySelection={false}
                        className="w-full"
                        selectionMode="checkbox"
                        display="chip"
                        placeholder="Chọn nhân viên"
                        style={{ minHeight: '40px' }}
                    ></TreeSelect>
                </div>
            </div>
            <DataTable
                value={data}
                lazy
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                paginator
                first="0"
                rows="100"
                totalRecords={totalRecords}
                dataKey="user_id"
                selectionMode="checkbox"
                showGridlines
                emptyMessage="Không tìm thấy nhân viên."
                currentPageReportTemplate="Tổng số: {totalRecords} bản ghi"
            >
                <Column field="user_id" header="Id" />
                <Column field="full_name" header="Họ tên" />
                <Column field="code" header="Mã nhân viên" />
                <Column header="Action" body={actionBody} />
            </DataTable>
        </>
    )
}
