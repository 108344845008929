import { useEffect, useState } from 'react'
import {
    countPromotionApartApi,
    countPromotionApi,
    detailPromotioApartApi,
    detailPromotionApi,
    listPromotionApartApi,
    listPromotionApi,
} from '../api'

// ======================Promotion==========================
export const useListPromotion = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listPromotionApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountPromotion = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countPromotionApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailPromotion = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailPromotionApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}

//=======================Promotion Apart=========================
export const useListPromotionApart = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listPromotionApartApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountPromotionApart = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countPromotionApartApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailPromotionApart = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailPromotioApartApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
