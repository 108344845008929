import React, { useRef, useState, useEffect } from 'react'
import {
    Calendarz,
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    RenderHeader,
    TimeBody,
    useGetParams,
    Inputz,
    MultiSelectz,
    ActionBody,
} from '@/components'
import { status } from '@/constants'
import { Chart } from 'primereact/chart'
const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Phân loại"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Loại xe"
            />
        </GridForm>
    )
}
function BuildingData() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const [chartDataPie, setChartDataPie] = useState({})
    const [chartOptionsPie, setChartOptionsPie] = useState({})
    const [chartData, setChartData] = useState({})
    const [chartOptions, setChartOptions] = useState({})
    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement)
        const data = {
            labels: ['Xe ô tô', 'Xe đạp thường', 'Xe máy thường', 'Xe máy điện'],
            datasets: [
                {
                    data: [50, 20, 25, 5],
                    backgroundColor: ['#5BE8FE', '#A464CF', '#3214C1', '#E38488'],
                    hoverBackgroundColor: ['#5BE8FE', '#A464CF', '#3214C1', '#E38488'],
                },
            ],
        }
        const options = {
            plugins: {
                legend: {
                    position: 'top',
                    labels: {
                        usePointStyle: true,
                    },
                },
            },
        }

        setChartDataPie(data)
        setChartOptionsPie(options)
    }, [])
    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement)
        const textColor = documentStyle.getPropertyValue('--text-color')
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary')
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border')
        const data = {
            labels: [
                'Toà 17T1',
                'Toà 17T2',
                'Toà 17T3',
                'Toà 15T',
                'Toà 34T',
                'Toà 24T1',
                'Toà 17T4',
                'Toà 17T5',
                'Toà 17T6',
                'Toà 18T1',
                'Toà Bohemia',
                'Toà Vinata Tower',
                'Toà VinaConex 1',
                'New House Xala',
                'Nhà Ga T2',
                'N05 Hoàng.Đ.T',
            ],
            datasets: [
                {
                    type: 'bar',
                    label: 'Vào',
                    backgroundColor: '#6997F8',
                    data: [200, 150, 150, 180, 350, 100, 150, 100, 150, 200, 250, 170, 100, 200, 220, 150],
                    borderWidth: 1,
                    barPercentage: 1,
                },
                {
                    type: 'bar',
                    label: 'Ra',
                    backgroundColor: '#FD9670',
                    data: [200, 150, 150, 180, 350, 100, 150, 100, 150, 200, 250, 170, 100, 200, 220, 150],
                    borderWidth: 1,
                    barPercentage: 1,
                },
            ],
        }
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    position: 'bottom',
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                    title: {
                        display: true,
                    },
                },
                y: {
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                    title: {
                        display: true,
                        text: 'Xe',
                    },
                },
            },
        }
        setChartData(data)
        setChartOptions(options)
    }, [])
    return (
        <div>
            <div className="card">
                <h4 className=" pt-3">Báo cáo thông tin căn hộ tòa nhà</h4>

                <div class="grid">
                    <div class="col-12 md:col-6 lg:col-3 ">
                        <div class="text-center p-3 border-round-sm  " style={{ background: '#00A65A' }}>
                            <div className="flex  align-items-center ">
                                <div className="flex-auto ">
                                    <h5 className="text-white ">82</h5>
                                    <h5 className="text-white m-0">Căn hộ</h5>
                                </div>
                                <div className="flex-auto ">
                                    <i className="pi pi-home text-white" style={{ fontSize: '2.5rem' }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-6 lg:col-3">
                        <div class="text-center p-3 border-round-sm " style={{ background: '#F6B653' }}>
                            <div className="flex  align-items-center">
                                <div className="flex-auto ">
                                    <h5 className="text-white ">111</h5>
                                    <h5 className="text-white m-0">Cư dân</h5>
                                </div>
                                <div className="flex-auto ">
                                    <i className="pi pi-users text-white" style={{ fontSize: '2.5rem' }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-6 lg:col-3">
                        <div class="text-center p-3 border-round-sm  " style={{ background: '#3C8DBC' }}>
                            <div className="flex  align-items-center ">
                                <div className="flex-auto ">
                                    <h5 className="text-white ">8</h5>
                                    <h5 className="text-white m-0">Đăng nhập</h5>
                                </div>
                                <div className="flex-auto ">
                                    <i className="pi pi-sign-out text-white" style={{ fontSize: '2.5rem' }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-6 lg:col-3">
                        <div class="text-center p-3 border-round-sm  " style={{ background: '#DD4B39' }}>
                            <div className="flex  align-items-center">
                                <div className="flex-auto ">
                                    <h5 className="text-white ">31</h5>
                                    <h5 className="text-white m-0">Phương tiện</h5>
                                </div>
                                <div className="flex-auto ">
                                    <i className="pi pi-car text-white" style={{ fontSize: '2.5rem' }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <h4 className=" pt-3">Thống kê phương tiện</h4>
                <Header setParams={setParams} />
                <div className="grid">
                    <div class="col-12 md:col-6 lg:col-6">
                        <div class="text-center  ">
                            <div className="flex  align-items-center justify-content-center">
                                <Chart type="pie" data={chartDataPie} options={chartOptionsPie} className="w-25rem" />
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-6 lg:col-6">
                        <div class="text-center  ">
                            <div className="flex  align-items-center justify-content-center">
                                <Chart type="line" data={chartData} options={chartOptions} className="w-full" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BuildingData
