import React, { useState } from 'react'
import {
    ActionBody,
    RenderHeader,
    Columnz,
    DataTablez,
    TimeBody,
    StatusBody,
    useGetParams,
    HeaderListForm,
} from '@/components'
import { Dropdownz, GridForm, Inputz } from '@/components'
import { listToast, status } from '@/constants'
import { useCountServices, useListServices } from '../utils'
import { deleteServicesApi, updateServicesApi } from '../api'
import { setToast } from '@/redux/features'
import { useDispatch } from 'react-redux'
import { confirmDialog } from 'primereact/confirmdialog'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập từ khóa"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
        </GridForm>
    )
}

export default function ServicesType() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListServices({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountServices({ status: undefined, ...params, first: undefined }) || 0
    const [selectedProducts, setSelectedProducts] = useState([])
    const dispatch = useDispatch()

    const items = [
        {
            label: 'Xóa',
            icon: 'pi pi-trash',
            command: () => confirm(),
        },
        {
            label: 'Active',
            icon: 'pi pi-check',
            command: () => confirm(),
        },
        {
            label: 'Inactive',
            icon: 'pi pi-times',
            command: () => confirm(),
        },
    ]

    async function accept(status) {
        const response = await updateServicesApi({})
        if (response.data.status) {
            dispatch(setToast({ ...listToast[0], detail: 'Chuyển trạng thái thành công!' }))
            if (params && setParams) {
                setParams((pre) => {
                    return { ...pre, render: !pre.render }
                })
            }
        } else dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
    }

    const confirm = (status) => {
        if (!(selectedProducts && selectedProducts[0])) {
            dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chọn căn hộ trước khi đổi trạng thái!' }))
            return
        }
        confirmDialog({
            message: 'Bạn có muốn tiếp tục thay đổi trạng thái?',
            header: 'Building Care',
            icon: 'pi pi-info-circle',
            accept: accept(status),
        })
    }

    return (
        <div className="card">
            <HeaderListForm title="Danh sách loại dịch vụ" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Dịch vụ"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                selectedProducts={selectedProducts}
                route="/service_type"
                setSelectedProducts={setSelectedProducts}
                headerInfo={{ items }}
                actionsInfo={{ deleteAction: deleteServicesApi }}
            >
                <Columnz field="name" header="Loại dịch vụ" />
                <Columnz field="type" header="Phân loại" />
                <Columnz field="update" header="Cập nhật" />
                <Columnz
                    header="Trạng thái"
                    body={(e) =>
                        StatusBody({
                            e,
                            route: '/service_type',
                            updateAction: updateServicesApi,
                        })
                    }
                />
            </DataTablez>
        </div>
    )
}
