import { useEffect, useState } from 'react'
import { countBuildingApi, detailBuildingApi, listBuildingApi, listBuildingV2Api } from '../api'

export const useListBuilding = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listBuildingApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useListBuildingV2 = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listBuildingV2Api({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountBuilding = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countBuildingApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailBuilding = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailBuildingApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
