import {
    Calendarz,
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    HeaderListForm,
    Inputz,
    TimeBody,
    useGetParams,
} from '@/components'
import { genders, relationshipOwner } from '@/constants'
import { useState } from 'react'
import { deleteResidentApi } from '../api'
import { useCountResident, useListResident } from '../utils'
import { useListApartmentV2 } from '../../apartment_management/utils'
import { useListBuildingV2 } from '../../building_management/utils'
import { getArrId } from '@/modules/permissions/permission/screens/ListUser'
import AddResident from './AddResident'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ full_name: '', email: '', phone: '' })
    const apartments = useListApartmentV2()
    const buildings = useListBuildingV2()

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.full_name}
                placeholder="Nhập từ khóa tìm kiếm"
                onChange={(e) => setFilter({ ...filter, full_name: e.target.value })}
            />
            <Inputz
                value={filter.email}
                placeholder="Nhập email tìm kiếm"
                onChange={(e) => setFilter({ ...filter, email: e.target.value })}
            />
            <Inputz
                value={filter.phone}
                placeholder="Nhập số điện thoại tìm kiếm"
                onChange={(e) => setFilter({ ...filter, phone: e.target.value })}
                type="number"
            />
            <Dropdownz
                value={filter.building_place_id}
                options={buildings}
                onChange={(e) => setFilter({ ...filter, building_place_id: e.target.value })}
                placeholder="Chọn tòa nhà"
            />
            <Dropdownz
                value={filter.gender}
                options={genders}
                onChange={(e) => setFilter({ ...filter, gender: e.target.value })}
                placeholder="Chọn giới tính"
            />
            <Dropdownz
                value={filter.apartment_id}
                options={apartments}
                onChange={(e) => setFilter({ ...filter, apartment_id: e.target.value })}
                placeholder="Chọn căn hộ"
            />
            <Calendarz
                value={filter.dates}
                onChange={(e) => setFilter({ ...filter, dates: e.value })}
                className="lg:col-6"
                placeholder="Khoảng thời gian ngày hạch toán"
            />
            <Dropdownz
                value={filter.relationship}
                options={relationshipOwner}
                onChange={(e) => setFilter({ ...filter, relationship: e.target.value })}
                placeholder="Quan hệ với chủ hộ"
            />
        </GridForm>
    )
}

const Residents = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const [selectedProducts, setSelectedProducts] = useState([])
    const data = useListResident({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountResident({ status: undefined, ...params, first: undefined }) || 0
    const [visible, setVisible] = useState(false)

    const items = [
        {
            label: 'Xóa',
            icon: 'pi pi-trash',
            title: 'cư dân',
            actionType: 'xóa',
            action: async (list_id) => await deleteResidentApi({ list_id: getArrId(list_id) }),
        },
        {
            label: 'Gửi email',
            icon: 'pi pi-send',
            title: 'cư dân',
            actionType: 'gửi email',
            action: async (listId) => {},
        },
        {
            label: 'Gửi SMS',
            icon: 'pi pi-send',
            title: 'cư dân',
            actionType: 'gửi sms',
            action: async (listId) => {},
        },
    ]

    return (
        <div className="card">
            <AddResident setParams={setParams} visible={visible} setVisible={setVisible} />
            <HeaderListForm title="Quản lý cư dân" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="cư dân"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                headerInfo={{ items }}
                route="/resident_management"
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                setVisibledDialog={setVisible}
                actionsInfo={{
                    noDialog: true,
                    deleteAction: deleteResidentApi,
                    handleDelete: (id) => {
                        return { list_id: [id] }
                    },
                }}
            >
                <Columnz field="full_name" header="Họ và tên" />
                <Columnz field="email" header="Email liên hệ" />
                <Columnz field="phone" header="SĐT" />
                <Columnz body={(e) => TimeBody(e.birthday, 'date')} header="Ngày sinh" />
                <Columnz field="apartments" header="Căn hộ" />
                <Columnz field="" header="Mobile active" />
            </DataTablez>
        </div>
    )
}

export default Residents
