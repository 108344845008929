import React, { useRef, useState, useEffect } from 'react'
import {
    Calendarz,
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    RenderHeader,
    TimeBody,
    useGetParams,
    Inputz,
    MultiSelectz,
    ActionBody,
} from '@/components'
import { status } from '@/constants'
import { Chart } from 'primereact/chart'
const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-12">
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn loại dịch vụ"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Dịch vụ"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn tòa nhà"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Căn hộ"
            />
        </GridForm>
    )
}
function InteractiveReport() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const [chartDataPie, setChartDataPie] = useState({})
    const [chartOptionsPie, setChartOptionsPie] = useState({})
    const [chartDataPie1, setChartDataPie1] = useState({})
    const [chartOptionsPie1, setChartOptionsPie1] = useState({})
    const serviceType = [
        { name: 'Phí khác' },
        { name: 'Điện' },
        { name: 'Phí dịch vụ' },
        { name: 'Nước' },
        { name: 'Phương tiện' },
    ]
    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement)
        const data = {
            labels: ['Chờ phản hồi', 'Hoàn Thành', 'Đã tiếp nhận'],
            datasets: [
                {
                    data: [50, 20, 25, 5],
                    backgroundColor: ['#5BE8FE', '#A464CF', '#3214C1', '#E38488'],
                    hoverBackgroundColor: ['#5BE8FE', '#A464CF', '#3214C1', '#E38488'],
                },
            ],
        }
        const options = {
            plugins: {
                legend: {
                    position: 'top',
                    labels: {
                        usePointStyle: true,
                    },
                },
            },
        }

        setChartDataPie(data)
        setChartOptionsPie(options)
    }, [])
    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement)
        const data = {
            labels: ['Rất không hài lòng', 'Chưa hài lòng', 'Bình thường', 'Hài lòng', 'Rất hài lòng'],
            datasets: [
                {
                    data: [61, 13, 4, 22],
                    backgroundColor: ['#D07272', '#FF71FF', '#2FB900', '#FFCC33'],
                    hoverBackgroundColor: ['#D07272', '#FF71FF', '#2FB900', '#FFCC33'],
                },
            ],
        }
        const options = {
            plugins: {
                legend: {
                    position: 'top',
                    labels: {
                        usePointStyle: true,
                    },
                },
            },
        }

        setChartDataPie1(data)
        setChartOptionsPie1(options)
    }, [])
    return (
        <div>
            <div className="card">
                <h4 className=" pt-3">Tổng hợp thông tin - sự kiện</h4>

                <div class="grid">
                    <div class="col-12 md:col-6 lg:col-4 ">
                        <div class="text-center p-3 border-round-sm  " style={{ background: '#00A65A' }}>
                            <div className="flex  align-items-center ">
                                <div className="flex-auto ">
                                    <h5 className="text-white ">132</h5>
                                    <h5 className="text-white m-0">Thông báo</h5>
                                </div>
                                <div className="flex-auto ">
                                    <i className="pi pi-bell text-white" style={{ fontSize: '2.5rem' }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-6 lg:col-4">
                        <div class="text-center p-3 border-round-sm " style={{ background: '#F6B653' }}>
                            <div className="flex  align-items-center">
                                <div className="flex-auto ">
                                    <h5 className="text-white ">1</h5>
                                    <h5 className="text-white m-0">Sự kiện</h5>
                                </div>
                                <div className="flex-auto ">
                                    <i className="pi pi-megaphone text-white" style={{ fontSize: '2.5rem' }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-6 lg:col-4">
                        <div class="text-center p-3 border-round-sm  " style={{ background: '#3C8DBC' }}>
                            <div className="flex  align-items-center">
                                <div className="flex-auto ">
                                    <h5 className="text-white ">904/904</h5>
                                    <h5 className="text-white m-0">Bình luận</h5>
                                </div>
                                <div className="flex-auto ">
                                    <i className="pi pi-comment text-white" style={{ fontSize: '2.5rem' }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <h4 className=" pt-3">Báo cáo thống kê</h4>
                <Header setParams={setParams} />
                <div className="grid">
                    <div class="col-12 md:col-6 lg:col-6">
                        <div class="text-center  ">
                            <div className="flex  align-items-center justify-content-center">
                                <Chart type="pie" data={chartDataPie} options={chartOptionsPie} className="w-25rem" />
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-6 lg:col-6">
                        <div class="text-center  ">
                            <div className="flex align-items-center justify-content-center">
                                <Chart type="pie" data={chartDataPie1} options={chartOptionsPie1} className="w-25rem" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InteractiveReport
