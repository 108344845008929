import { useEffect, useState } from 'react'
import { countRegisterRepairApi, detailRegisterRepairApi, listRegisterRepairApi } from '../api'

export const useListRegisterRepair = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listRegisterRepairApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountRegisterRepair = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countRegisterRepairApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailRegisterRepair = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailRegisterRepairApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
