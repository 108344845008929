import { DropdownForm, FormUpdate, InputForm, InputSwitchForm } from '@/components'
import { removePropObject } from '@/utils'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { addBuildingApi, updateBuildingApi } from '../api'
import { useDetailBuilding } from '../utils'
import { statusBuilding } from '@/constants'

const UpdateBuilding = () => {
    const { id } = useParams()
    const buildingDetail = useDetailBuilding(id)
    const [infos, setInfos] = useState({
        name: '',
        code: '',
    })

    useEffect(() => {
        if (buildingDetail.id) setInfos({ ...infos, ...buildingDetail })
    }, [buildingDetail])

    const handleData = () => {
        let info = { ...infos }
        if (!(info.status === 0 || info.status === 1)) return 'Vui lòng chọn trạng thái!'
        if (Number(id)) info = { ...removePropObject(info, buildingDetail), id: id }
        return info
    }

    return (
        <FormUpdate
            checkId={Number(id)}
            title="tòa nhà"
            handleData={handleData}
            route="/building_management"
            actions={{ add: addBuildingApi, update: updateBuildingApi }}
            refreshObjects={[setInfos]}
        >
            <div className="card bg-color">
                <div className="grid grid-form">
                    <div className="col-12 lg:col-6">
                        <InputForm
                            id="name"
                            value={infos.name}
                            onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                            label="Tên tòa nhà (*)"
                            required
                        />
                        <InputForm
                            id="code"
                            value={infos.code}
                            onChange={(e) => setInfos({ ...infos, code: e.target.value })}
                            label="Mã tòa nhà (*)"
                            required
                        />
                    </div>
                    <div className="col-12 lg:col-6">
                        <DropdownForm
                            id="status"
                            value={infos.status}
                            onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                            options={statusBuilding}
                            label="Trạng thái (*)"
                        />
                    </div>
                </div>
            </div>
        </FormUpdate>
    )
}

export default UpdateBuilding
