import { CalendarForm, FormUpdate, InputForm } from '@/components'
import EditorV2 from '@/components/Editorz'
import { removePropObject } from '@/utils'
import { FileUpload } from 'primereact/fileupload'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { addRegisterRepairApi, updateRegisterRepairApi } from '../api'
import { useDetailRegisterRepair } from '../utils'

const UpdateRegisterRepair = () => {
    const { id } = useParams()
    const registerRepairDetail = useDetailRegisterRepair(id)
    const [infos, setInfos] = useState({
        nameRegisterRepairPlace: '',
        statusRegisterRepairPlace: '',
        codeRegisterRepairPlace: '',
    })

    useEffect(() => {
        if (registerRepairDetail.id) setInfos({ ...infos, ...registerRepairDetail, password: '' })
    }, [registerRepairDetail])

    const handleData = () => {
        let info = { ...infos, status: infos.status ? 1 : 0 }
        if (Number(id)) info = { ...removePropObject(info, registerRepairDetail), id: id }
        return info
    }

    return (
        <FormUpdate
            checkId={Number(id)}
            title="tòa nhà"
            handleData={handleData}
            route="/building_management"
            actions={{ add: addRegisterRepairApi, update: updateRegisterRepairApi }}
            refreshObjects={[setInfos]}
        >
            <div className="card bg-color">
                <div style={{ maxWidth: '1200px' }}>
                    <InputForm
                        id="nameRegisterRepairPlace"
                        value={infos.nameRegisterRepairPlace}
                        onChange={(e) => setInfos({ ...infos, nameRegisterRepairPlace: e.target.value })}
                        label="Căn hộ (*)"
                        required
                    />
                    <InputForm
                        id="statusRegisterRepairPlace"
                        value={infos.statusRegisterRepairPlace}
                        onChange={(e) => setInfos({ ...infos, statusRegisterRepairPlace: e.target.value })}
                        label="Tiêu đề (*)"
                        required
                    />
                    <EditorV2 />
                    <FileUpload />
                    <InputForm
                        id="statusRegisterRepairPlace"
                        value={infos.statusRegisterRepairPlace}
                        onChange={(e) => setInfos({ ...infos, statusRegisterRepairPlace: e.target.value })}
                        label="Đơn vị thi công (*)"
                        required
                    />
                    <CalendarForm label="Thời gian bắt đầu" />
                    <CalendarForm label="Thời gian kết thúc" />
                    <InputForm
                        id="statusRegisterRepairPlace"
                        value={infos.statusRegisterRepairPlace}
                        onChange={(e) => setInfos({ ...infos, statusRegisterRepairPlace: e.target.value })}
                        label="Họ và tên (*)"
                        required
                    />
                    <InputForm
                        id="statusRegisterRepairPlace"
                        value={infos.statusRegisterRepairPlace}
                        onChange={(e) => setInfos({ ...infos, statusRegisterRepairPlace: e.target.value })}
                        label="Email (*)"
                        required
                    />
                    <InputForm
                        id="statusRegisterRepairPlace"
                        value={infos.statusRegisterRepairPlace}
                        onChange={(e) => setInfos({ ...infos, statusRegisterRepairPlace: e.target.value })}
                        label="Số điện thoại (*)"
                        required
                    />
                </div>
            </div>
        </FormUpdate>
    )
}

export default UpdateRegisterRepair
