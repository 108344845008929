import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDetailPartner } from '../utils'
import { updatePartnerApi, addPartnerApi } from '../api'
import { removePropObject } from '@/utils'
import { InputForm, FormUpdate, InputTextareaForm, DropdownForm } from '@/components'
import { removeUndefinedProps } from '@/utils'

const initInfos = { name: '', phone: '', email: '', address: '', note: '', status: true }

const UpdatePartner = () => {
    const { id } = useParams()
    const partnerDetail = useDetailPartner(id)
    const [infos, setInfos] = useState(initInfos)

    useEffect(() => {
        if (partnerDetail.id) setInfos({ ...infos, ...removeUndefinedProps(partnerDetail) })
    }, [partnerDetail])

    const handleData = () => {
        let info = { ...infos, status: infos.status ? 1 : 0 }
        if (Number(id)) info = { ...removePropObject(info, partnerDetail), id: id }
        return info
    }

    return (
        <FormUpdate
            checkId={Number(id)}
            title="đối tác"
            handleData={handleData}
            route="/partner"
            actions={{ add: addPartnerApi, update: updatePartnerApi }}
            refreshObjects={[setInfos]}
        >
            <div className="card bg-color">
                <div className="grid grid-form">
                    <div className="col-12 lg:col-6">
                        <InputForm
                            id="name"
                            value={infos.name}
                            onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                            label="Tên đối tác (*)"
                            required
                        />
                        <DropdownForm
                            value={infos.name}
                            onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                            label="Danh mục cung cấp (*)"
                        />
                        <InputForm
                            id="address"
                            value={infos.address}
                            onChange={(e) => setInfos({ ...infos, address: e.target.value })}
                            label="Địa chỉ (*)"
                            required
                        />
                    </div>
                    <div className="col-12 lg:col-6">
                        <InputForm
                            id="phone"
                            value={infos.phone}
                            onChange={(e) => setInfos({ ...infos, phone: e.target.value })}
                            label="Số điện thoại (*)"
                            type="phone"
                            required
                        />
                        <InputForm
                            id="email"
                            value={infos.email}
                            onChange={(e) => setInfos({ ...infos, email: e.target.value })}
                            label="Email (*)"
                            type="email"
                            required
                        />
                    </div>
                </div>
                <InputTextareaForm
                    id="note"
                    value={infos.note}
                    onChange={(e) => setInfos({ ...infos, note: e.target.value })}
                    label="Ghi chú"
                />
            </div>
        </FormUpdate>
    )
}

export default UpdatePartner
