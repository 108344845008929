import React, { useState } from 'react'
import { Columnz, DataTablez, Dropdownz, GridForm, useGetParams, Inputz, HeaderListForm } from '@/components'
import { listToast, status } from '@/constants'
import { useCountDebt, useListDebt } from '../utils'
import { TabPanel, TabView } from 'primereact/tabview'
import { deleteApartmentApi } from '@/modules/resident_apartments/apartment_management/api'
import { getArrId } from '@/modules/permissions/permission/screens/ListUser'
import { setToast } from '@/redux/features'
import { confirmDialog } from 'primereact/confirmdialog'
import { useDispatch } from 'react-redux'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-12">
            <Inputz placeholder="Dư nợ cuối kì" type="number" />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn căn hộ"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn tòa nhà"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Tên dịch vụ"
            />

            {/* <div>
                <p style={{ marginTop: "40px" }}><strong>Tổng Tiền: 217,082,123</strong></p>
                <p><strong>Tổng Giảm trừ: 812,000</strong></p>
                <p><strong>Tổng Thành Tiền: 216,270,123</strong></p>
                <p><strong>Đã hạch toán: 195,046,224</strong></p>
                <p><strong>Còn nợ: 21,223,899</strong></p>
            </div> */}
        </GridForm>
    )
}
function AggregateDebt() {
    const dispatch = useDispatch()
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const [selectedProducts, setSelectedProducts] = useState([])
    const data = useListDebt({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountDebt({ status: undefined, ...params, first: undefined }) || 0

    async function accept() {
        const response = await deleteApartmentApi({ listId: getArrId(selectedProducts) })
        if (response.data.status) {
            dispatch(setToast({ ...listToast[0], detail: 'Nhắc phí thành công!' }))
            if (params && setParams) {
                setParams((pre) => {
                    return { ...pre, render: !pre.render }
                })
            }
        } else dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
    }

    const confirm = () => {
        if (!(selectedProducts && selectedProducts[0])) {
            dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chọn căn hộ trước khi nhắc phí!' }))
            return
        }
        confirmDialog({
            message: 'Bạn có chắc chắn muốn nhắc phí?',
            header: 'Building Care',
            icon: 'pi pi-info-circle',
            accept,
        })
    }

    const items = [{ label: 'Nhắc phí', icon: 'pi pi-bell', command: () => confirm() }]

    return (
        <div className="card">
            <HeaderListForm title="Tổng hợp công nợ" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="công nợ"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/aggregate_debt"
                headerInfo={{ items }}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
            >
                <Columnz field="" header="Mã KH/NCC	" />
                <Columnz field="" header="Tên KH/NCC" />
                <Columnz field="" header="Căn Hộ" />
                <Columnz field="" header="Mã dự án" />
                <Columnz field="" header="Mã sản phẩm" />
                <Columnz field="" header="Tên dự án	" />
                <Columnz field="" header="Số dư đầu kỳ" />
                <Columnz field="" header="Phát sinh trong kỳ" />
                <Columnz field="" header="Thanh toán" />
                <Columnz field="" header="Số dư cuối kỳ" />
            </DataTablez>
        </div>
    )
}

export default AggregateDebt
