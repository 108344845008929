import {
    ActionBody,
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    HeaderListForm,
    Inputz,
    StatusBody,
    useGetParams,
} from '@/components'
import { status } from '@/constants'
import { useState } from 'react'
import { deleteCardApi, updateCardApi } from '../api'
import { useCountCard, useListCard } from '../utils'
import { useListBuildingV2 } from '../../building_management/utils'
import { useListApartmentV2 } from '../../apartment_management/utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ card_code: '' })
    const buildings = useListBuildingV2()
    const apartments = useListApartmentV2()

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.card_code}
                placeholder="Nhập code hoặc biển số"
                onChange={(e) => setFilter({ ...filter, card_code: e.target.value })}
            />
            <Dropdownz
                value={filter.building_place_id}
                options={buildings}
                onChange={(e) => setFilter({ ...filter, building_place_id: e.target.value })}
                placeholder="Chọn tòa nhà"
            />
            <Dropdownz
                value={filter.building_place_id}
                options={apartments}
                onChange={(e) => setFilter({ ...filter, building_place_id: e.target.value })}
                placeholder="Chọn căn hộ"
            />
            <Dropdownz
                value={filter.type_vehicle_id}
                options={status}
                onChange={(e) => setFilter({ ...filter, type_vehicle_id: e.target.value })}
                placeholder="Chọn phương tiện"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn trạng thái"
            />
        </GridForm>
    )
}

const Cards = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListCard({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountCard({ status: undefined, ...params, first: undefined }) || 0
    const [selectedProducts, setSelectedProducts] = useState([])

    return (
        <div className="card">
            <HeaderListForm title="Quản lý thẻ" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="thẻ"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/card_management"
                headerInfo={{ items: [], batchDelete: async () => await deleteCardApi() }}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
            >
                <Columnz field="card_code" header="Mã thẻ" />
                <Columnz field="name_apartment" header="Căn hộ" />
                <Columnz field="group_apartments" header="Nhóm căn hộ" />
                <Columnz field="category" header="Phương tiện" />
                <Columnz field="license_plates" header="Biển số" />
                <Columnz field="description" header="Ghi chú" />
                <Columnz
                    header="Tình trạng"
                    body={(e) => StatusBody({ e, route: '/card_management', updateAction: updateCardApi })}
                />
                <Columnz
                    header="Thao tác"
                    body={(e) =>
                        ActionBody({
                            id: e.id,
                            route: '/card_management',
                            deleteAction: deleteCardApi,
                            params,
                            setParams,
                        })
                    }
                />
            </DataTablez>
        </div>
    )
}

export default Cards
