import React, { useState } from 'react'
import { TimeBody, Columnz, StatusBody, DataTablez, ActionBody, useGetParams, HeaderListForm } from '@/components'
import { useListTool } from '../utils'
import { updateToolApi } from '../api'

const Tools = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListTool({ ...params, status: undefined, first: undefined })
    const totalRecords = data.length

    return (
        <div className="card">
            <HeaderListForm title="Danh sách quyền hiện có" />
            <DataTablez
                value={data}
                title="quyền hiện có"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/tool"
                actionsInfo={{}}
            >
                <Columnz field="name" header="Tên quyền hiện có" />
                <Columnz field="company_name" header="Công ty" />
                <Columnz field="type" header="Loại quyền" />
                <Columnz header="Ngày khởi tạo" body={(e) => TimeBody(e.created_at)} />
                <Columnz header="Thời gian cập nhật" body={(e) => TimeBody(e.updated_at)} />
                <Columnz
                    field="status"
                    header="Trạng thái"
                    body={(e) => StatusBody({ e, route: '/tool', updateAction: updateToolApi })}
                />
            </DataTablez>
        </div>
    )
}

export default Tools
