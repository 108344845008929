import {
    ActionBody,
    Calendarz,
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    HeaderListForm,
    Inputz,
    RenderHeader,
    useGetParams,
} from '@/components'
import { status } from '@/constants'
import { useState } from 'react'
import { deleteReceiptApi } from '../api'
import { useCountReceipt, useListReceipt } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập từ khóa"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn chủ hộ"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn chủ hộ"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn chủ hộ"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn chủ hộ"
            />
            <Calendarz />
            <Calendarz />
            <Calendarz />
            <Calendarz />
        </GridForm>
    )
}

const ListReceipts = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListReceipt({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountReceipt({ status: undefined, ...params, first: undefined }) || 0

    return (
        <div className="card">
            <HeaderListForm title="Danh sách phiếu thu" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Phiếu thu"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/list_receipt"
                actionsInfo={{ deleteAction: deleteReceiptApi }}
            >
                <Columnz field="name" header="Mã chứng từ" />
                <Columnz field="address" header="Hình thức" />
                <Columnz field="phone" header="Loại phiếu" />
                <Columnz field="phone" header="Ngày lập phiếu" />
                <Columnz field="phone" header="Ngày hạch toán" />
                <Columnz field="phone" header="Căn hộ" />
                <Columnz field="phone" header="Tòa" />
                <Columnz field="phone" header="Khách hàng" />
                <Columnz field="phone" header="Số tiền" />
                <Columnz field="phone" header="Nội dung" />
                <Columnz field="phone" header="Người tạo" />
                <Columnz field="phone" header="In phiếu" />
            </DataTablez>
        </div>
    )
}

export default ListReceipts
