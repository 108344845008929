import React, { useEffect, useState } from 'react'
import {
    ActionBody,
    Columnz,
    DataTablez,
    Dialogz,
    Dropdownz,
    FormUpdate,
    GridForm,
    HeaderListForm,
    InputForm,
    InputSwitchForm,
    InputTextareaForm,
    Inputz,
    RenderHeader,
    StatusBody,
    useGetParams,
} from '@/components'
import { useParams } from 'react-router-dom'
import { removePropObject } from '@/utils'
import { useCountCategory, useDetailCategory, useListCategory } from '../utils'
import { addCategoryApi, deleteCategoryApi, updateCategoryApi } from '../api'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    const listCategoryData = [
        { id: 1, name: 'Thiết bị điện' },
        { id: 2, name: 'Đồ gia dụng' },
        { id: 3, name: 'Phòng cháy chữa cháy' },
        { id: 4, name: 'Đồ điện tử' },
        { id: 5, name: 'Đồ Thông Cống' },
        { id: 6, name: 'Thiết bị thông minh' },
    ]

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập từ khóa"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.category}
                options={listCategoryData}
                onChange={(e) => setFilter({ ...filter, category: e.target.value })}
                placeholder="Chọn danh mục"
            />
        </GridForm>
    )
}

export default function Category() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListCategory({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountCategory({ status: undefined, ...params, first: undefined }) || 0
    const [visible, setVisible] = useState(false)

    const { id } = useParams()
    const categoryDetail = useDetailCategory(id)
    const [infos, setInfos] = useState({
        name: '',
        status: true,
    })
    useEffect(() => {
        if (categoryDetail.id)
            setInfos({ ...infos, ...categoryDetail, status: categoryDetail.status === 0 ? false : true })
    }, [categoryDetail])

    const handleData = () => {
        let info = { ...infos }
        if (Number(id)) info = { ...removePropObject(info, categoryDetail), id: id }
        return info
    }

    const RenderDialog = () => {
        return (
            <Dialogz title={'Danh mục công việc'} visible={visible} setVisible={setVisible}>
                <FormUpdate
                    checkId={Number(id)}
                    // title="Công việc"
                    handleData={handleData}
                    route="/work_management"
                    actions={{ add: addCategoryApi, update: updateCategoryApi }}
                    refreshObjects={[setInfos]}
                >
                    <InputForm
                        id="name"
                        value={infos.name}
                        onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                        label="Tên danh mục(*)"
                        required
                    />
                    <InputSwitchForm
                        id="status"
                        label="Trạng thái"
                        value={infos.status}
                        onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                    />
                </FormUpdate>
            </Dialogz>
        )
    }
    return (
        <>
            <RenderDialog />
            <HeaderListForm title="Danh mục công việc" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Tòa nhà"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/work_management"
                setVisibledDialog={setVisible}
                actionsInfo={{ deleteAction: deleteCategoryApi }}
            >
                <Columnz field="name" header="Tên danh mục công việc" />
                <Columnz field="date_create" header="Ngày tạo" />
                <Columnz field="creator" header="Người tạo" />
                <Columnz
                    header="Trạng thái"
                    body={(e) =>
                        StatusBody({
                            e,
                            route: '/work_management',
                            updateAction: updateCategoryApi,
                        })
                    }
                />
            </DataTablez>
        </>
    )
}
