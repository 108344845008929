import { DropdownForm, FormUpdate, InputForm } from '@/components'
import { removePropObject } from '@/utils'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { addServiceRequestApi, updateServiceRequestApi } from '../api'
import { useDetailServiceRequest } from '../utils'

const UpdateServiceRequest = () => {
    const { id } = useParams()
    const serviceRequestDetail = useDetailServiceRequest(id)
    const [infos, setInfos] = useState({
        nameServiceRequestPlace: '',
        statusServiceRequestPlace: '',
        codeServiceRequestPlace: '',
    })

    useEffect(() => {
        if (serviceRequestDetail.id) setInfos({ ...infos, ...serviceRequestDetail, password: '' })
    }, [serviceRequestDetail])

    const handleData = () => {
        let info = { ...infos, status: infos.status ? 1 : 0 }
        if (Number(id)) info = { ...removePropObject(info, serviceRequestDetail), id: id }
        return info
    }

    return (
        <FormUpdate
            checkId={Number(id)}
            title="tòa nhà"
            handleData={handleData}
            route="/building_management"
            actions={{ add: addServiceRequestApi, update: updateServiceRequestApi }}
            refreshObjects={[setInfos]}
        >
            <div className="card bg-color">
                <div style={{ maxWidth: '1200px' }}>
                    <InputForm
                        id="nameServiceRequestPlace"
                        value={infos.nameServiceRequestPlace}
                        onChange={(e) => setInfos({ ...infos, nameServiceRequestPlace: e.target.value })}
                        label="Tên tòa nhà (*)"
                        required
                    />
                    <InputForm
                        id="statusServiceRequestPlace"
                        value={infos.statusServiceRequestPlace}
                        onChange={(e) => setInfos({ ...infos, statusServiceRequestPlace: e.target.value })}
                        label="Địa chỉ (*)"
                        required
                    />
                    <DropdownForm
                        value={infos.codeServiceRequestPlace}
                        onChange={(e) => setInfos({ ...infos, codeServiceRequestPlace: e.target.value })}
                        label="Mã tòa nhà (*)"
                    />
                </div>
            </div>
        </FormUpdate>
    )
}

export default UpdateServiceRequest
