import {
    CalendarForm,
    Columnz,
    DataTablez,
    Dialogz,
    FormUpdate,
    GridForm,
    HeaderListForm,
    InputForm,
    Inputz,
    RenderHeader,
    useGetParams,
} from '@/components'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { removePropObject } from '@/utils'
import { useCountPromotion, useDetailPromotion, useListPromotion } from '../utils'
import { addPromotionApi, deletePromotionApi, updatePromotionApi } from '../api'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo tên"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
        </GridForm>
    )
}

export default function ListPromotionManage() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListPromotion({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountPromotion({ status: undefined, ...params, first: undefined }) || 0
    const [visible, setVisible] = useState(false)

    const { id } = useParams()
    const promotionManageDetail = useDetailPromotion(id)
    const [infos, setInfos] = useState({
        name: '',
        service: '',
        time: '',
        condition: '',
        month_promotion: '',
    })

    useEffect(() => {
        if (promotionManageDetail.id)
            setInfos({ ...infos, ...promotionManageDetail, status: promotionManageDetail.status === 0 ? false : true })
    }, [promotionManageDetail])

    const handleData = () => {
        let info = { ...infos }
        if (Number(id)) info = { ...removePropObject(info, promotionManageDetail), id: id }
        return info
    }

    const RenderDialog = () => {
        return (
            <Dialogz title={'Khuyến Mãi'} visible={visible} setVisible={setVisible}>
                <FormUpdate
                    checkId={Number(id)}
                    // title="Khuyến mãi"
                    handleData={handleData}
                    route="/promotion_management"
                    actions={{ add: addPromotionApi, update: updatePromotionApi }}
                    refreshObjects={[setInfos]}
                >
                    <InputForm
                        id="name"
                        value={infos.name}
                        onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                        label="Tên khuyến mãi (*)"
                        required
                    />
                    <InputForm
                        id="service"
                        value={infos.service}
                        onChange={(e) => setInfos({ ...infos, service: e.target.value })}
                        label="Dịch vụ (*)"
                        required
                    />
                    <CalendarForm
                        id="time"
                        value={infos.time}
                        onChange={(e) => setInfos({ ...infos, time: e.target.value })}
                        showIcon
                        label="Thời gian (*)"
                    />
                    <InputForm
                        id="condition"
                        value={infos.condition}
                        onChange={(e) => setInfos({ ...infos, condition: e.target.value })}
                        label="Điều kiện khuyến mãi (*)"
                        required
                    />
                    <InputForm
                        id="condition"
                        value={infos.condition}
                        onChange={(e) => setInfos({ ...infos, condition: e.target.value })}
                        label="Số tháng khuyến mãi (*)"
                        required
                    />
                    <InputForm
                        id="month_promotion"
                        value={infos.month_promotion}
                        onChange={(e) => setInfos({ ...infos, namePromomonth_promotiontionManage: e.target.value })}
                        label="Giá trị khuyến mãi (*)"
                        required
                    />
                </FormUpdate>
            </Dialogz>
        )
    }

    return (
        <>
            <RenderDialog />
            <HeaderListForm title="Quản lý khuyến mãi" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Khuyến mãi"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/promotion_management"
                setVisibledDialog={setVisible}
                actionsInfo={{ deleteAction: deletePromotionApi }}
            >
                <Columnz field="name" header="Tên KM" />
                <Columnz field="address" header="Loại KM" />
                <Columnz field="phone" header="Thời gian áp dụng" />
                <Columnz field="email" header="Giá trị khuyến mãi" />
                <Columnz field="phone" header="Người tạo" />
                <Columnz field="phone" header="Ngày tạo" />
                <Columnz field="phone" header="Trạng thái" />
            </DataTablez>
        </>
    )
}
