import { DropdownForm, FormUpdate, InputForm } from '@/components'
import { removePropObject } from '@/utils'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { addResidentOpinionsApi, updateResidentOpinionsApi } from '../api'
import { useDetailResidentOpinions } from '../utils'

const UpdateResidentOpinion = () => {
    const { id } = useParams()
    const residentOpinionsDetail = useDetailResidentOpinions(id)
    const [infos, setInfos] = useState({
        nameResidentOpinionsPlace: '',
        statusResidentOpinionsPlace: '',
        codeResidentOpinionsPlace: '',
    })

    useEffect(() => {
        if (residentOpinionsDetail.id) setInfos({ ...infos, ...residentOpinionsDetail, password: '' })
    }, [residentOpinionsDetail])

    const handleData = () => {
        let info = { ...infos, status: infos.status ? 1 : 0 }
        if (Number(id)) info = { ...removePropObject(info, residentOpinionsDetail), id: id }
        return info
    }

    return (
        <FormUpdate
            checkId={Number(id)}
            title="tòa nhà"
            handleData={handleData}
            route="/building_management"
            actions={{ add: addResidentOpinionsApi, update: updateResidentOpinionsApi }}
            refreshObjects={[setInfos]}
        >
            <div className="card bg-color">
                <div style={{ maxWidth: '1200px' }}>
                    <InputForm
                        id="nameResidentOpinionsPlace"
                        value={infos.nameResidentOpinionsPlace}
                        onChange={(e) => setInfos({ ...infos, nameResidentOpinionsPlace: e.target.value })}
                        label="Tên tòa nhà (*)"
                        required
                    />
                    <InputForm
                        id="statusResidentOpinionsPlace"
                        value={infos.statusResidentOpinionsPlace}
                        onChange={(e) => setInfos({ ...infos, statusResidentOpinionsPlace: e.target.value })}
                        label="Địa chỉ (*)"
                        required
                    />
                    <DropdownForm
                        value={infos.codeResidentOpinionsPlace}
                        onChange={(e) => setInfos({ ...infos, codeResidentOpinionsPlace: e.target.value })}
                        label="Mã tòa nhà (*)"
                    />
                </div>
            </div>
        </FormUpdate>
    )
}

export default UpdateResidentOpinion
