import { CalendarForm, Dialogz, DropdownForm, FormUpdate, HeaderListForm, InputForm, MultiRadioButton, UploadImg, UploadMultiImage } from '@/components'
import { removePropObject } from '@/utils'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { addRoleApi, deleteRoleApi, editRoleApi, updateResidentApi } from '../api'
import { useDetailResident } from '../utils'
import { removeUndefinedProps } from '@/utils'
import { genders, relationshipOwner } from '@/constants'
import { TabPanel, TabView } from 'primereact/tabview'
import { databaseDate } from '@/lib'
import { Button } from '@/uiCore'
import { listToast } from '@/constants'
import { setToast } from '@/redux/features'
import { useDispatch } from 'react-redux'
import { useListApartmentV2 } from '../../apartment_management/utils'
const initInfos = { full_name: '', address: '', cmt_number: '', cmt_address: '', cmt_province: '', email_contact: '', phone_contact: '', email: '', phone: ''}

const ListApartment = ({ data, setData, id }) => {
    const [visible, setVisible] = useState(false)
    const dispatch = useDispatch()
    const apartments = useListApartmentV2()
    const [info, setInfo] = useState({})

    const fetchData = async (params, api) => {
        const response = await api(params)
        if (response.data.status) {
            dispatch(setToast({ ...listToast[0], detail: 'Cập nhật dữ liệu thành công!' }))
        } else dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
    }

    const handleUpdate = (index) => {
        if (index || index === 0) {
            const params = { apartment_id: data[index].apartment_id, type: data[index].type, resident_id: id }
            fetchData(params, editRoleApi)
        }
    }

    const handleDelete = (index) => {
        if (index || index === 0) {
            const params = { apartment_id: data[index].apartment_id, resident_id: id }
            setData(pre => pre.filter(p => p.apartment_id !== data[index].apartment_id))
            fetchData(params, deleteRoleApi)
        }
    }

    const handleAdd = () => {
        fetchData({ ...info, resident_id: id }, addRoleApi)
        setVisible(false)
        setData(pre => [ ...pre, info ])
        setInfo({})
    }

    const handleOnChange = (index, value) => {
        const newData = data
        newData[index].type = value
        setData([...newData])
    }

    return (
        <div className='card'>
            <Dialogz header="Thông tin nhân viên" visible={visible} setVisible={setVisible} width="70vw">
                <div className='card mt-4'>
                    <div className='grid grid-form'>
                        <div className='col-12 lg:col-6'>
                            <DropdownForm
                                value={info.apartment_id}
                                options={apartments.filter(a => !data.some(d => a.id === d.apartment_id))} 
                                onChange={e => setInfo({ ...info, apartment_id: e.target.value })}
                                label="Căn hộ"
                            />
                        </div>
                        <div className='col-12 lg:col-6'>
                            <DropdownForm
                                value={info.type}
                                options={relationshipOwner}
                                onChange={e => setInfo({ ...info, type: e.target.value })}
                                label="Tình trạng"
                            />
                        </div>
                    </div>
                </div>
                <div className="justify-content-end flex gap-3">
                    <Button
                        type="button"
                        raised
                        size="small"
                        severity="danger"
                        label="Hủy"
                        icon="pi pi-times"
                        onClick={() => {
                            setVisible(false)
                            setInfo({})
                        }}
                    />
                    <Button type="button" disabled={!info.apartment_id || !(info.type || info.type === 0)} onClick={() => handleAdd()} raised size="small" icon="pi pi-save" severity="info" label="Xác nhận" />
                </div>
            </Dialogz>
            <div className='text-right'>  <Button
                type="button"
                raised
                size="small"
                severity="info"
                label="Thêm căn hộ"
                onClick={() => setVisible(true)}
            /></div>
            {data.map((d, index) => {
                return (
                    <div key={index} className='flex align-items-center'>
                        <div className='w-5'>
                            <DropdownForm
                                value={d.apartment_id}
                                label="Căn hộ"
                                options={apartments}
                                disabled
                            />
                        </div>
                        <div className='w-5'>
                            <DropdownForm
                                value={d.type}
                                options={relationshipOwner}
                                onChange={e => handleOnChange(index, e.target.value)}
                                label="Tình trạng"
                            />
                        </div>
                        <div className='w-2 flex gap-2 justify-content-end'>
                            <Button
                                type="button"
                                raised
                                size="small"
                                severity="info"
                                label="Cập nhật"
                                className="mt-2"
                                onClick={() => handleUpdate(index)}
                            />
                            <Button
                                type="button"
                                raised
                                size="small"
                                severity="danger"
                                label="Xóa"
                                className="mt-2"
                                onClick={() => handleDelete(index)}
                            />
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

const UpdateResident = () => {
    const { id } = useParams()
    const residentDetail = useDetailResident(id)
    const [infos, setInfos] = useState(initInfos)
    const [avatar, setAvatar] = useState(null)
    const [images, setImages] = useState(null)
    const [data, setData] = useState([])

    useEffect(() => {
        if (residentDetail.detailResident)
            setInfos({ ...infos, ...removeUndefinedProps(residentDetail.detailResident), birthday: residentDetail.detailResident.birthday && new Date(residentDetail.detailResident.birthday), cmt_date: residentDetail.detailResident.cmt_date && new Date(residentDetail.detailResident.cmt_date) })
        if (residentDetail.detailResident && residentDetail.detailResident.avatar) setAvatar(residentDetail.detailResident.avatar)
        if (residentDetail.detailResident && residentDetail.detailResident.cmt_image) setImages(residentDetail.detailResident.cmt_image)
        if (residentDetail.roleOfApartments) setData([...residentDetail.roleOfApartments])
    }, [residentDetail])

    const handleData = () => {
        let info = { ...infos, birthday: infos.birthday && databaseDate(infos.birthday), cmt_date: infos.cmt_date && databaseDate(infos.cmt_date) }
        if (avatar) info.avatar = avatar
        if (images) info.cmt_image = images
        if (Number(id)) info = { user_id: infos.user_id , ...removePropObject(info, residentDetail.detailResident), id: Number(id) }
        console.log(info);
        return info
    }

    return (
        <TabView>
            <TabPanel header="Thông tin cư dân">
                <FormUpdate
                    checkId={true}
                    handleData={handleData}
                    actions={{ update: updateResidentApi }}
                    route="/resident_management"
                    title="cư dân"
                    refreshObjects={[setInfos]}
                >
                    <div className="card bg-color mt-4">
                        <div className="grid grid-form">
                            <div className="col-12 lg:col-6">
                                <InputForm
                                    id="full_name"
                                    value={infos.full_name}
                                    onChange={(e) => setInfos({ ...infos, full_name: e.target.value })}
                                    label="Tên dân cư (*)"
                                    required
                                />
                                <CalendarForm
                                    value={infos.birthday}
                                    onChange={(e) => setInfos({ ...infos, birthday: e.target.value })}
                                    label="Ngày sinh"
                                />
                                <InputForm
                                    id="address"
                                    value={infos.address}
                                    onChange={(e) => setInfos({ ...infos, address: e.target.value })}
                                    label="Địa chỉ"
                                />
                                <InputForm
                                    id="cmt_number"
                                    value={infos.cmt_number}
                                    onChange={(e) => setInfos({ ...infos, cmt_number: e.target.value })}
                                    label="CMND / Hộ chiếu"
                                />
                                <CalendarForm
                                    value={infos.cmt_date}
                                    onChange={(e) => setInfos({ ...infos, cmt_date: e.target.value })}
                                    label="Ngày cấp"
                                />
                                <InputForm
                                    id="cmt_address"
                                    value={infos.cmt_address}
                                    onChange={(e) => setInfos({ ...infos, cmt_address: e.target.value })}
                                    label="Địa chỉ trên chứng minh thư"
                                />
                                <InputForm
                                    id="cmt_province"
                                    value={infos.cmt_province}
                                    onChange={(e) => setInfos({ ...infos, cmt_province: e.target.value })}
                                    label="Nơi cấp"
                                />
                                <MultiRadioButton
                                    id="gender"
                                    value={infos.gender}
                                    onChange={(e) => setInfos({ ...infos, gender: e.target.value })}
                                    options={genders}
                                    className="w-full"
                                    label="Giới tính"
                                />
                            </div>
                            <div className="col-12 lg:col-6">
                                <InputForm
                                    id="email_contact"
                                    value={infos.email_contact}
                                    onChange={(e) => setInfos({ ...infos, email_contact: e.target.value })}
                                    label="Email liên hệ"
                                />
                                <InputForm
                                    id="phone_contact"
                                    value={infos.phone_contact}
                                    onChange={(e) => setInfos({ ...infos, phone_contact: e.target.value })}
                                    label="Điện thoại liên hệ"
                                />
                                <div className='mb-5'>
                                    <UploadImg image={avatar} setImage={setAvatar} title="Ảnh đại diện" />
                                </div>
                                <UploadImg image={images} setImage={setImages} title="Ảnh chứng minh thư" />
                            </div>
                        </div>
                    </div>
                    <HeaderListForm title="Thông tin đăng nhập" />
                    <div className="card bg-color mt-4">
                        <div className="grid grid-form">
                            <div className="col-12 lg:col-6">
                                <InputForm
                                    value={infos.email}
                                    onChange={(e) => setInfos({ ...infos, email: e.target.value })}
                                    label="Email (*)"
                                    disabled
                                />
                            </div>
                            <div className="col-12 lg:col-6">
                                <InputForm
                                    value={infos.phone}
                                    onChange={(e) => setInfos({ ...infos, phone: e.target.value })}
                                    label="Số điện thoại (*)"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>

                </FormUpdate>
            </TabPanel>
            <TabPanel header="Danh sách căn hộ">
                <ListApartment id={Number(id)} data={data} setData={setData} />
            </TabPanel>
        </TabView>
    )
}

export default UpdateResident
