import { Link, useNavigate } from 'react-router-dom'
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearUserInfo, setToast } from '@/redux/features'
import { listToast } from '@/constants'

const AppTopbar = forwardRef((props, ref) => {
    const { setVisible } = props
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector((state) => state.user) || {}
    const [menuTopbar, setMenuTopbar] = useState(false)
    const menuRef = useRef(null)

    const handleLogout = () => {
        localStorage.removeItem('token')
        dispatch(clearUserInfo())
        dispatch(setToast({ ...listToast[0], detail: 'Đăng xuất thàng công!' }))
        navigate('/auth/login')
    }

    const handleHideMenu = (e) => {
        if (menuRef.current && !menuRef.current.contains(e.target)) {
            setMenuTopbar(false)
        }
    }

    useEffect(() => {
        if (menuTopbar) {
            document.addEventListener('mousedown', handleHideMenu)
        } else {
            document.removeEventListener('mousedown', handleHideMenu)
        }

        return () => {
            document.removeEventListener('mousedown', handleHideMenu)
        }
    }, [menuTopbar])

    return (
        <div className="layout-topbar">
            <Link to="/" className="layout-topbar-logo">
                <div className="flex align-items-center">
                    <span className="text-white">
                        <b>BUILDING</b> CARE
                    </span>
                </div>
            </Link>

            <button
                type="button"
                className="p-link layout-menu-button layout-topbar-button"
                onClick={props.onMenuToggle}
            >
                <i className="pi pi-bars" />
            </button>

            <div className="layout-topbar-menu">
                <span>{user && user.user && user.user.email}</span>
                <div className="p-link layout-topbar-button">
                    <div className="avatar" onClick={() => setMenuTopbar(!menuTopbar)}>
                        <img src="/assets/img/profile.png" alt="Ảnh đại diện" height="40px" width="40px" />
                    </div>
                    {menuTopbar && (
                        <div ref={menuRef} className="menu-topbar">
                            <p className="p-link flex align-items-center gap-4" onClick={() => setVisible(true)}>
                                <i className="pi pi-info-circle" style={{ fontSize: '16px' }} /> Thông tin
                            </p>{' '}
                            <hr />
                            <Link to="/auth/change_password">
                                <p className="p-link flex align-items-center gap-4">
                                    <i className="pi pi-sync" style={{ fontSize: '16px' }} /> Đổi mật khẩu
                                </p>
                            </Link>{' '}
                            <hr />
                            <p className="p-link flex align-items-center gap-4" onClick={handleLogout}>
                                <i className="pi pi-sign-out" style={{ fontSize: '16px' }} /> Đăng xuất
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
})

export default AppTopbar
