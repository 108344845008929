import { getData, postData } from '@/lib/request'

export const listVehicleApi = (params) => getData('web2/company/getListCompany', params)
export const countVehicleApi = (params) => getData('web2/company/countCompany', params)
export const deleteVehicleApi = (params) => postData('web2/company/deleteCompany', params)
export const detailVehicleApi = (params) => getData('web2/company/getDetailCompany', params)
export const addVehicleApi = (params) => postData('/web2/company/addCompany', params)
export const updateVehicleApi = (params) => postData('/web2/company/updateCompany', params)

export const listTransportationApi = (params) => getData('web2/company/getListCompany', params)
export const countTransportationApi = (params) => getData('web2/company/countCompany', params)
export const deleteTransportationApi = (params) => postData('web2/company/deleteCompany', params)
export const detailTransportationApi = (params) => getData('web2/company/getDetailCompany', params)
export const addTransportationApi = (params) => postData('/web2/company/addCompany', params)
export const updateTransportationApi = (params) => postData('/web2/company/updateCompany', params)
