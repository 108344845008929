import React, { useState } from 'react'
import {
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    RenderHeader,
    useGetParams,
    Inputz,
    ActionBody,
    HeaderListForm,
} from '@/components'
import { status, statusDebt } from '@/constants'
import { useCountCompany, useListCompany } from '../utils'
import { TabPanel, TabView } from 'primereact/tabview'
import { deleteServiceApi } from '@/modules/debts/services_list/api'
const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-3">
            <Inputz placeholder="Dư nợ cuối kì" type="number" />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn tòa nhà"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn căn hộ"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Tên dịch vụ"
            />
            <Dropdownz
                options={statusDebt}
                value={filter.statusDebt}
                onChange={(e) => setFilter({ ...filter, statusDebt: e.target.value })}
                placeholder="Chọn loại dịch vụ"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn tình trạng"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn kì bảng kê"
            />

            {/* <div>
                <p style={{ marginTop: "40px" }}><strong>Tổng Tiền: 217,082,123</strong></p>
                <p><strong>Tổng Giảm trừ: 812,000</strong></p>
                <p><strong>Tổng Thành Tiền: 216,270,123</strong></p>
                <p><strong>Đã hạch toán: 195,046,224</strong></p>
                <p><strong>Còn nợ: 21,223,899</strong></p>
            </div> */}
        </GridForm>
    )
}
function AggregateDebtDetails() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListCompany({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountCompany({ status: undefined, ...params, first: undefined }) || 0
    return (
        <div className="card">
            <HeaderListForm title="Tổng hợp công nợ chi tiết" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                header={RenderHeader({ route: '/aggregate_debt_details' })}
                title="công nợ chi tiết"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
            >
                <Columnz field="" header="Tên KH" />
                <Columnz field="" header="Căn hộ" />
                <Columnz field="" header="Mã Sản phẩm" />
                <Columnz field="" header="Mã thu" />
                <Columnz field="" header="Dịch vụ" />
                <Columnz field="" header="Số dư đầu kỳ" />
                <Columnz field="" header="Phát sinh trong kỳ" />
                <Columnz field="" header="Thanh toán" />
                <Columnz field="" header="Số dư cuối kỳ" />
            </DataTablez>
        </div>
    )
}

export default AggregateDebtDetails
