import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDetailPermission } from '../utils'
import { updatePermissionApi, addPermissionApi } from '../api'
import { removePropObject } from '@/utils'
import { InputForm, InputSwitchForm, FormUpdate } from '@/components'
import { MultiSelectz } from './MultiSelect'
import { ListUser } from './ListUser'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { useListTool } from '@/modules/permissions/tools/utils'
import { TabPanel, TabView } from 'primereact/tabview'
import { useListUserV2 } from '@/modules/companies/users/utils'

const handlePermissions = (listTool) => {
    const company_permission = []
    const building_permission = []
    listTool = listTool || []
    listTool.forEach((l) => {
        if (l.list_tool_child && l.list_tool_child[0]) {
            l.list_tool_child.forEach((lc) => {
                const actionsJSON = lc.actions
                const actions = actionsJSON ? JSON.parse(actionsJSON) : []
                lc.children = actions
            })
        }
        if (l.type === 'building') building_permission.push(l)
        if (l.type === 'company') company_permission.push(l)
    })
    return { building_permission, company_permission }
}

const UpdatePermission = () => {
    const { id } = useParams()
    const permissionDetail = useDetailPermission(id)
    const listTool = useListTool()
    const [infos, setInfos] = useState({ name: '', status: true })
    const [data, setData] = useState([])
    const [permissionToolbar, setPermissionToolbar] = useState([])
    const [permission, setPermission] = useState([])
    const users = useListUserV2()

    useEffect(() => {
        if (permissionDetail.permission)
            setInfos({
                ...infos,
                ...permissionDetail.permission,
                status: permissionDetail.permission.status === 0 ? false : true,
            })
        if (permissionDetail.permission_tool && permissionDetail.permission_tool[0]) {
            let newPermissionToolbar = []
            permissionDetail.permission_tool.forEach((p) => {
                if (p.bdc_tool_child_id) {
                    const actionsJson = p.actions
                    const actions = actionsJson ? JSON.parse(actionsJson) : []
                    if (actions[0]) {
                        const newActions = actions.map((a) => p.bdc_tool_child_id + '-' + a) || []
                        newPermissionToolbar = [...newPermissionToolbar, ...newActions]
                    }
                }
            })
            setPermissionToolbar([...newPermissionToolbar])
        }
    }, [permissionDetail])

    useEffect(() => {
        if (
            users &&
            users[0] &&
            permissionDetail &&
            permissionDetail.permission_member &&
            permissionDetail.permission_member[0]
        ) {
            setData([...users.filter((u) => permissionDetail.permission_member.some((p) => u.id === p.user_id))])
        }
    }, [permissionDetail, users])

    const handleData = () => {
        let info = { ...infos, status: infos.status ? 1 : 0 }
        const newMembers =
            data && data[0]
                ? data.map((d) => {
                      return { user_id: d.user_id }
                  })
                : []
        const newToolbal = []
        if (permissionToolbar && permissionToolbar[0]) {
            permissionToolbar.map((p) => {
                const values = p.split('-')
                const findIndex = newToolbal.findIndex((n) => n.tool_child_id === Number(values[0]))
                if (findIndex < 0) {
                    newToolbal.push({ tool_child_id: Number(values[0]), actions: [values[1]] })
                } else {
                    if (newToolbal[findIndex]) {
                        newToolbal[findIndex].actions.push(values[1])
                    }
                }
            })
        }

        const tools = []
        const member = []
        if (Number(id)) {
            info = { ...removePropObject(info, permissionDetail), id: id }
            if (permissionDetail.permission_tool) {
                const deleteElements = permissionDetail.permission_tool.filter(
                    (d) => !newToolbal.some((n) => n.tool_child_id === d.bdc_tool_child_id),
                )
                if (deleteElements && deleteElements[0]) {
                    deleteElements.forEach((f) => {
                        tools.push({ id: f.id, deleted_at: 1 })
                    })
                }
                newToolbal.forEach((n, index) => {
                    const elements = permissionDetail.permission_tool.find(
                        (p) => p.bdc_tool_child_id === n.tool_child_id,
                    )
                    if (elements) {
                        if (JSON.stringify(n.actions) !== elements.actions) {
                            tools.push({ id: elements.id, actions: n.actions })
                        }
                    } else tools.push({ ...n })
                })
            }
            if (permissionDetail.permission_member) {
                const deleteElements = permissionDetail.permission_member.filter(
                    (d) => !newMembers.some((n) => n.user_id === d.user_id),
                )
                if (deleteElements && deleteElements[0]) {
                    deleteElements.forEach((f) => {
                        member.push({ id: f.id, deleted_at: 1 })
                    })
                }
                newMembers.forEach((n, index) => {
                    const elements = permissionDetail.permission_member.find((p) => p.user_id === n.user_id)
                    if (!elements) member.push({ ...n })
                })
            }
        }
        info = { ...info, tools: id ? tools : newToolbal, members: id ? member : newMembers }
        return info
    }

    useEffect(() => {
        if (listTool && listTool[0]) setPermission({ ...handlePermissions(listTool) })
    }, [listTool])

    return (
        <FormUpdate
            checkId={Number(id)}
            title="nhóm quyền"
            handleData={handleData}
            actions={{ add: addPermissionApi, update: updatePermissionApi }}
            route="/permission"
            refreshObjects={[setInfos, setData, setPermissionToolbar]}
        >
            <div className="card">
                <TabView>
                    <TabPanel header="Thông tin chi tiết">
                        <div className="card bg-color">
                            <div className="grid formgrid">
                                <div className="col-12 lg:col-6">
                                    <InputForm
                                        id="name"
                                        value={infos.name}
                                        onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                                        label="Tên nhóm quyền (*)"
                                    />
                                </div>
                                <div className="col-12 lg:col-6">
                                    <InputSwitchForm
                                        checked={infos.status}
                                        onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mb-4 text-left">
                            <Accordion multiple activeIndex={[0, 1]} className="mb-4">
                                <AccordionTab header="Quyền theo công ty">
                                    {permission.company_permission && permission.company_permission[0] ? (
                                        <MultiSelectz
                                            tree={permission.company_permission}
                                            permissionToolbar={permissionToolbar}
                                            setPermissionToolbar={setPermissionToolbar}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </AccordionTab>
                                <AccordionTab header="Quyền theo tòa nhà">
                                    {permission.building_permission && permission.building_permission[0] ? (
                                        <MultiSelectz
                                            tree={permission.building_permission}
                                            permissionToolbar={permissionToolbar}
                                            setPermissionToolbar={setPermissionToolbar}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </AccordionTab>
                            </Accordion>
                        </div>
                    </TabPanel>
                    <TabPanel header="Danh sách người dùng">
                        <ListUser users={users} data={data} setData={setData} />
                    </TabPanel>
                </TabView>
            </div>
        </FormUpdate>
    )
}

export default UpdatePermission
