import React, { useState } from 'react'
import { Columnz, DataTablez, TimeBody, useGetParams, HeaderListForm, Body } from '@/components'
import { deleteUserApi } from '../api'
import { useCountUser, useListUser } from '../utils'
import { Dropdownz, GridForm, Inputz } from '@/components'
import { status } from '@/constants'
import { useListExchangeV2 } from '../../exchange/utils'
import { useListGroupV2 } from '../../group/utils'

const Header = ({ setParams, exchanges, groups }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-12">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo tên, mã"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.department_id}
                options={exchanges}
                onChange={(e) => setFilter({ ...filter, department_id: e.target.value })}
                placeholder="Phòng ban"
            />
            <Dropdownz
                value={filter.group_id}
                options={groups}
                onChange={(e) => setFilter({ ...filter, group_id: e.target.value })}
                placeholder="Nhóm"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Trạng thái"
            />
        </GridForm>
    )
}

const Users = ({ group_id }) => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListUser({ status: undefined, group_id, ...params, first: undefined })
    const totalRecords = useCountUser({ status: undefined, group_id, ...params, first: undefined }) || 0
    const exchanges = useListExchangeV2()
    const groups = useListGroupV2()

    return (
        <div className={group_id ? '' : 'card'}>
            {!group_id && (
                <>
                    <HeaderListForm title="Danh sách nhân viên" />
                    <Header groups={groups} exchanges={exchanges} setParams={setParams} />
                </>
            )}
            <DataTablez
                value={data}
                title="nhân viên"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/user"
                actionsInfo={{ deleteAction: deleteUserApi }}
            >
                <Columnz field="code" header="Mã nhân viên" />
                <Columnz field="full_name" header="Tên nhân viên" />
                <Columnz field="phone" header="SĐT" />
                <Columnz field="email" header="Email" />
                <Columnz field="company_name" header="Công ty" />
                <Columnz body={(e) => Body(exchanges, e.department_id)} header="Phòng ban" />
                {/* <Columnz field="position" header="Chức vụ" /> */}
                <Columnz header="Thời gian tạo" body={(e) => TimeBody(e.created_at)} />
                <Columnz header="Thời gian cập nhật" body={(e) => TimeBody(e.updated_at)} />
                {/* <Columnz
                    header="Trạng thái"
                    body={(e) => StatusBody({ e, route: '/user', updateAction: updateUserApi })}
                /> */}
            </DataTablez>
        </div>
    )
}

export default Users
