import React, { useEffect, useState } from 'react'
import {
    ActionBody,
    RenderHeader,
    Columnz,
    DataTablez,
    TimeBody,
    StatusBody,
    useGetParams,
    Dialogz,
    FormUpdate,
    InputForm,
    InputTextareaForm,
    InputSwitchForm,
    DropdownForm,
    HeaderListForm,
} from '@/components'
import { Dropdownz, GridForm, Inputz } from '@/components'
import { status } from '@/constants'
import { Button } from 'primereact/button'
import { useParams } from 'react-router-dom'
import { useCountDirectory, useDetailDirectory, useListDirectory } from '../utils'
import { addDirectoryApi, deleteDirectoryApi, updateDirectoryApi } from '../api'
import { removePropObject } from '@/utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập nội dung tìm kiếm"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.staus}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Danh mục"
            />
            <Dropdownz
                value={filter.staus}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Phân loại"
            />
            <Dropdownz
                value={filter.staus}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Người tạo"
            />
            <Dropdownz
                value={filter.staus}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Trạng thái"
            />
        </GridForm>
    )
}

export default function Directory() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListDirectory({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountDirectory({ status: undefined, ...params, first: undefined }) || 0

    const [visible, setVisible] = useState(false)

    const { id } = useParams()
    const directoryDetail = useDetailDirectory(id)
    const [infos, setInfos] = useState({
        name_depart: '',
        type: '',
        parent_direct: '',
        phone: '',
        avatar: '',
    })
    useEffect(() => {
        if (directoryDetail.id)
            setInfos({ ...infos, ...directoryDetail, status: directoryDetail.status === 0 ? false : true })
    }, [directoryDetail])

    const handleData = () => {
        let info = { ...infos }
        if (Number(id)) info = { ...removePropObject(info, directoryDetail), id: id }
        return info
    }

    const RenderDialog = () => {
        return (
            <Dialogz title={'Cẩm nang tòa nhà'} visible={visible} setVisible={setVisible}>
                <FormUpdate
                    checkId={Number(id)}
                    // title="Cẩm nang"
                    handleData={handleData}
                    route="/building_guide"
                    actions={{ add: addDirectoryApi, update: updateDirectoryApi }}
                    refreshObjects={[setInfos]}
                >
                    <InputForm
                        id="name_depart"
                        value={infos.name_depart}
                        onChange={(e) => setInfos({ ...infos, name_depart: e.target.value })}
                        label="Tên bộ phận(*)"
                        required
                    />
                    <DropdownForm
                        value={infos.type}
                        onChange={(e) => setInfos({ ...infos, type: e.target.value })}
                        label="Phân loại"
                    />
                    <DropdownForm
                        value={infos.parent_direct}
                        onChange={(e) => setInfos({ ...infos, parent_direct: e.target.value })}
                        label="Danh mục cha"
                    />
                    <InputForm
                        id="category"
                        value={infos.phone}
                        onChange={(e) => setInfos({ ...infos, phone: e.target.value })}
                        label="Phone"
                    />
                    <InputForm
                        id="type_maintain"
                        value={infos.type_maintain}
                        onChange={(e) => setInfos({ ...infos, type_maintain: e.target.value })}
                        label="Kiểu bảo trì"
                    />
                    <InputForm
                        type="file"
                        id="file"
                        value={infos.file}
                        onChange={(e) => setInfos({ ...infos, file: e.target.value })}
                        label="Upload avatar"
                    />
                </FormUpdate>
            </Dialogz>
        )
    }

    return (
        <>
            <RenderDialog />
            <HeaderListForm title="Thông tin danh mục" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Danh mục"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/building_guide"
                setVisibledDialog={setVisible}
                actionsInfo={{ deleteAction: deleteDirectoryApi }}
            >
                <Columnz field="title" header="Tiêu đề " />
                <Columnz field="categogy" header="Danh mục" />
                <Columnz field="type" header="Phân loại" />
                <Columnz field="partner" header="Đối tác" />
                <Columnz field="creator" header="Người tạo" />
                <Columnz
                    header="Trạng thái"
                    body={(e) =>
                        StatusBody({
                            e,
                            route: '/building_guide',
                            updateAction: updateDirectoryApi,
                        })
                    }
                />
                <Columnz
                    header="Actions"
                    body={(e) =>
                        ActionBody({
                            setVisibledDialog: setVisible,
                            id: e.id,
                            route: '/building_guide',
                            deleteAction: deleteDirectoryApi,
                            params,
                            setParams,
                        })
                    }
                />
            </DataTablez>
        </>
    )
}
