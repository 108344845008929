import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDetailGroup } from '../utils'
import { addGroupApi, updateGroupApi } from '../api'
import { removePropObject } from '@/utils'
import { InputForm, FormUpdate, InputTextareaForm, DropdownForm, MultiRadioButton, InputSwitchForm } from '@/components'
import { TabView, TabPanel } from 'primereact/tabview'
import Users from '../../users/screens/Users'
import { units } from '@/constants'
import { removeUndefinedProps } from '@/utils'
import { useListExchangeV2, useListProjectV2 } from '../../exchange/utils'
import { useListUserV2 } from '../../users/utils'
import { useListBuildingV2 } from '@/modules/resident_apartments/building_management/utils'

const initInfos = {
    name: '',
    phone_number: '',
    email: '',
    address: '',
    description: '',
    code: '',
    status: true,
    type: 1,
}

const GroupInfo = (props) => {
    const { infos, setInfos } = props
    const projects = useListBuildingV2()
    const exchanges = useListExchangeV2()
    const users = useListUserV2()

    return (
        <div className="card bg-color">
            <div className="grid grid-form">
                <div className="col-12 lg:col-6">
                    <MultiRadioButton
                        className="w-full"
                        label="Đơn vị quản lý (*)"
                        options={units}
                        value={infos.type}
                        onChange={(e) => setInfos({ ...infos, type: e })}
                    />
                    <InputForm
                        id="name"
                        value={infos.name}
                        onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                        label="Tên nhóm (*)"
                        required
                    />
                    <InputForm
                        id="code"
                        value={infos.code}
                        onChange={(e) => setInfos({ ...infos, code: e.target.value })}
                        label="Mã nhóm"
                    />
                    <DropdownForm
                        id="bdc_department_id"
                        value={infos.bdc_department_id}
                        options={exchanges}
                        onChange={(e) => setInfos({ ...infos, bdc_department_id: e.target.value })}
                        label="Phòng ban"
                    />
                </div>
                <div className="col-12 lg:col-6">
                    <DropdownForm
                        id="building_id"
                        options={projects}
                        value={infos.building_id}
                        onChange={(e) => setInfos({ ...infos, building_id: e.target.value })}
                        label="Dự án"
                    />
                    <InputForm
                        id="email"
                        value={infos.email}
                        onChange={(e) => setInfos({ ...infos, email: e.target.value })}
                        label="Email (*)"
                        required
                        type="email"
                    />
                    <InputForm
                        id="phone_number"
                        value={infos.phone_number}
                        onChange={(e) => setInfos({ ...infos, phone_number: e.target.value })}
                        label="Số điện thoại (*)"
                        required
                        type="phone"
                    />
                    <DropdownForm
                        id="main_user_id"
                        value={infos.main_user_id}
                        onChange={(e) => setInfos({ ...infos, main_user_id: e.target.value })}
                        options={users}
                        optionLabel="full_name"
                        optionValue="user_id"
                        label="Trưởng nhóm (*)"
                    />
                </div>
            </div>
            <InputTextareaForm
                id="description"
                value={infos.description}
                onChange={(e) => setInfos({ ...infos, description: e.target.value })}
                label="Ghi chú"
            />
            <InputSwitchForm
                id="status"
                checked={infos.status}
                onChange={(e) => setInfos({ ...infos, status: e.target.value })}
            />
        </div>
    )
}

const UpdateGroup = () => {
    const { id } = useParams()
    const groupDetail = useDetailGroup(id)
    const [infos, setInfos] = useState(initInfos)

    useEffect(() => {
        if (groupDetail.id)
            setInfos({ ...infos, ...removeUndefinedProps(groupDetail), status: Number(groupDetail.status) !== 0 })
    }, [groupDetail])

    const handleData = () => {
        let info = { ...infos, status: infos.status ? 1 : 0 }
        if (Number(id)) info = { ...removePropObject(info, groupDetail), id: id }
        return info
    }

    return (
        <FormUpdate
            checkId={Number(id)}
            title="nhóm"
            handleData={handleData}
            route="/group"
            actions={{ add: addGroupApi, update: updateGroupApi }}
            refreshObjects={[setInfos]}
        >
            <TabView>
                <TabPanel header="Chi tiết">
                    <GroupInfo infos={infos} setInfos={setInfos} />
                </TabPanel>
                <TabPanel disabled={!Number(id)} header="Danh sách nhân sự">
                    <Users group_id={Number(id)} />
                </TabPanel>
            </TabView>
        </FormUpdate>
    )
}

export default UpdateGroup
