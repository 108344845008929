import { FormAuth, FormInput } from '@/components'
import { listToast } from '@/constants'
import { validate } from '@/lib'
import { forgotPasswordAPI } from '@/modules/auth/api'
import { setToast } from '@/redux/features'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const ForgotPassword = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')

    async function fetchData(param,type) {
        const response = await forgotPasswordAPI(param)
        if (response) setLoading(false)
        if (response.data.status) {
            localStorage.setItem('emailReset', email)
            const message = type === 'email' ? `Mã xác nhận đã được gửi đến email` : `Mã OTP đã được gửi đến số điện thoại`
            dispatch(setToast({ ...listToast[0], detail: message }))
            navigate('/auth/set_password')
        } else dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()  
        let param = {}
        let type = ''
        if (validate('email', email)) {
            param.key_search = email
            type = 'email'
        } else if (validate('phone', email)) {
            param.key_search = email
            type = 'phone'
        } else {
            setLoading(false)
            dispatch(setToast({ ...listToast[1], detail: 'Không đúng định dạng email hoặc số điện thoại!' }))
            return
        }
        setLoading(true)
        fetchData(param, type)
    }

    return (
        <FormAuth
            title="Forgot Password"
            subtitle="Back to login"
            handleSubmit={handleSubmit}
            linkSubtitle="/auth/login"
            loading={loading}
            disabled={!email}
        >
            <FormInput
                id="username"
                label="Email hoặc số điện thoại"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
        </FormAuth>
    )
}

export default ForgotPassword
