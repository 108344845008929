import React, { useEffect, useRef, useState } from 'react'
import {
    Calendarz,
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    RenderHeader,
    TimeBody,
    useGetParams,
    ActionBody,
    Inputz,
} from '@/components'
import { status } from '@/constants'
// import { useCountCompany, useListCompany } from '../utils'
// import { deleteCompanyApi, updateCompanyApi } from '../api'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'
import { Column } from 'primereact/column'
import { Chart } from 'primereact/chart'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-12">
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn loại dịch vụ"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Dịch vụ"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn tòa nhà"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Căn hộ"
            />
        </GridForm>
    )
}
function AggregateRevenue() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const [chartData, setChartData] = useState({})
    const [chartDataLine, setChartDataLine] = useState({})
    const [chartOptions, setChartOptions] = useState({})
    const [chartOptionsLine, setChartOptionsLine] = useState({})
    const [chartDataPie, setChartDataPie] = useState({})
    const [chartOptionsPie, setChartOptionsPie] = useState({})
    const [chartDataPie1, setChartDataPie1] = useState({})
    const [chartOptionsPie1, setChartOptionsPie1] = useState({})
    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement)

        const data = {
            labels: [
                'Toà 17T1',
                'Toà 17T2',
                'Toà 17T3',
                'Toà 15T',
                'Toà 34T',
                'Toà 24T1',
                'Toà 17T4',
                'Toà 17T5',
                'Toà 17T6',
                'Toà 18T1',
                'Toà Bohemia',
                'Toà Vinata Tower',
                'Toà VinaConex 1',
                'New House Xala',
                'Nhà Ga T2',
                'N05 Hoàng.Đ.T',
            ],
            datasets: [
                {
                    type: 'bar',
                    label: 'Phát sinh',
                    backgroundColor: '#FD9670',
                    data: [300, 400, 800, 700, 790, 750, 1500, 1000, 1100, 820, 700, 643, 1100, 820, 650, 1200],
                    borderColor: 'white',
                    borderWidth: 1,
                    barPercentage: 1,
                },
                {
                    type: 'bar',
                    label: 'Thanh toán',
                    backgroundColor: '#6997F8',
                    data: [200, 150, 150, 180, 350, 100, 150, 100, 150, 200, 250, 170, 100, 200, 220, 150],
                    borderWidth: 1,
                    barPercentage: 1,
                },
            ],
        }
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    position: 'top',
                },
            },
            scales: {
                y: {
                    title: {
                        display: true,
                        text: 'Trăm triệu đồng',
                    },
                },
            },
        }
        setChartData(data)
        setChartOptions(options)
    }, [])
    useEffect(() => {
        const data = {
            labels: [
                'Toà 17T1',
                'Toà 17T2',
                'Toà 17T3',
                'Toà 15T',
                'Toà 34T',
                'Toà 24T1',
                'Toà 17T4',
                'Toà 17T5',
                'Toà 17T6',
                'Toà 18T1',
                'Toà Bohemia',
                'Toà Vinata Tower',
                'Toà VinaConex 1',
                'New House Xala',
                'Nhà Ga T2',
                'N05 Hoàng.Đ.T',
            ],
            datasets: [
                {
                    type: 'line',
                    label: 'Tỉ lệ % thu công nợ',
                    borderColor: '#D34C19FF',
                    backgroundColor: '#D34C19FF',
                    borderWidth: 2,
                    fill: false,
                    tension: 0.3,
                    data: [1, 2, 3, 5, 11, 75, 42, 34, 42, 27, 55, 46, 15, 88, 74, 47],
                    steppedLine: true,
                },
            ],
        }
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    position: 'top',
                },
            },
            scales: {
                y: {
                    title: {
                        display: true,
                        text: '%',
                    },
                },
            },
        }
        setChartDataLine(data)
        setChartOptionsLine(options)
    }, [])
    useEffect(() => {
        const data = {
            labels: ['Vệ sinh', 'Kĩ thuật', 'Bảo vệ'],
            datasets: [
                {
                    data: [35, 60, 5],
                    backgroundColor: ['rgba(243, 163, 44, 1)', 'rgba(22, 91, 169, 1)', 'rgba(42, 42, 42, 1)'],
                    hoverBackgroundColor: ['rgba(243, 163, 44, .8)', 'rgba(22, 91, 169, .8)', 'rgba(42, 42, 42, .8)'],
                },
            ],
        }
        const options = {
            plugins: {
                legend: {
                    position: 'top',
                    labels: {
                        usePointStyle: true,
                    },
                },
            },
        }

        setChartDataPie(data)
        setChartOptionsPie(options)
    }, [])
    useEffect(() => {
        const data = {
            labels: ['Vệ sinh', 'Kĩ thuật', 'Bảo vệ'],
            datasets: [
                {
                    data: [70, 20, 50],
                    backgroundColor: ['rgba(243, 163, 44, 1)', 'rgba(22, 91, 169, 1)', 'rgba(42, 42, 42, 1)'],
                    hoverBackgroundColor: ['rgba(243, 163, 44, .8)', 'rgba(22, 91, 169, .8)', 'rgba(42, 42, 42, .8)'],
                },
            ],
        }
        const options = {
            plugins: {
                legend: {
                    position: 'top',
                    labels: {
                        usePointStyle: true,
                    },
                },
            },
        }

        setChartDataPie1(data)
        setChartOptionsPie1(options)
    }, [])
    return (
        <div className="card">
            <Header setParams={setParams} />
            <div class="grid text-center ">
                <div class="col-12 md:col-6 lg:col-7 ">
                    <div class="">
                        <b className="text-xl">Báo cáo doanh thu</b>
                        <Chart type="line" data={chartData} options={chartOptions} />
                    </div>
                </div>
                <div class="col-12 md:col-6 lg:col-5 ">
                    <div class="">
                        <b className="text-xl">Tỉ lệ dòng tiền</b>
                        <Chart
                            type="pie"
                            data={chartDataPie}
                            options={chartOptionsPie}
                            className="md:w-30rem lg:col-6"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AggregateRevenue
