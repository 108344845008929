import React, { useState } from 'react'
import {
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    useGetParams,
    Inputz,
    MultiSelectz,
    HeaderListForm,
} from '@/components'
import { status } from '@/constants'
import { useCountService, useListService } from '../utils'
import { deleteServiceApi, updateServiceApi } from '../api'
import { TabPanel, TabView } from 'primereact/tabview'
import { useDispatch } from 'react-redux'
import { confirmDialog } from 'primereact/confirmdialog'
import { setToast } from '@/redux/features'
import { listToast } from '@/constants'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-12">
            <Inputz placeholder="Mã Bk" type="number" />
            <Inputz placeholder="Còn nợ" type="number" />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn tòa nhà"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn căn hộ"
            />
            <MultiSelectz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn thành phố"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Dịch vụ"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Phí dịch vụ"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Loại dịch vụ"
            />
            {/* <div>
                <p style={{ marginTop: "40px" }}><strong>Tổng Tiền: 217,082,123</strong></p>
                <p><strong>Tổng Giảm trừ: 812,000</strong></p>
                <p><strong>Tổng Thành Tiền: 216,270,123</strong></p>
                <p><strong>Đã hạch toán: 195,046,224</strong></p>
                <p><strong>Còn nợ: 21,223,899</strong></p>
            </div> */}
        </GridForm>
    )
}
const ServicesList = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const [selectedProducts, setSelectedProducts] = useState([])
    const data = useListService({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountService({ status: undefined, ...params, first: undefined }) || 0
    const dispatch = useDispatch()
    const items = [
        {
            label: 'Chuyển trạng thái cần xác nhận',
            command: () => confirm(),
        },
        {
            label: 'Chuyển trạng thái chờ gửi',
            command: () => confirm(),
        },
        {
            label: 'Chờ gửi',
            command: () => confirm(),
        },
    ]

    async function accept(status) {
        const response = await updateServiceApi({})
        if (response.data.status) {
            dispatch(setToast({ ...listToast[0], detail: 'Chuyển trạng thái thành công!' }))
            if (params && setParams) {
                setParams((pre) => {
                    return { ...pre, render: !pre.render }
                })
            }
        } else dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
    }

    const confirm = (status) => {
        if (!(selectedProducts && selectedProducts[0])) {
            dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chọn căn hộ trước khi đổi trạng thái!' }))
            return
        }
        confirmDialog({
            message: 'Bạn có muốn tiếp tục thay đổi trạng thái?',
            header: 'Building Care',
            icon: 'pi pi-info-circle',
            accept: accept(status),
        })
    }

    const moreOptions = [
        { label: 'Export tổng hợp phải thu phí', icon: 'pi pi-download', severity: 'success', command: () => {} },
    ]

    return (
        <div className="card">
            <HeaderListForm title="Chi tiết bảng kê - dịch vụ" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="bảng kê dịch vụ"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/services_list"
                headerInfo={{ items, moreOptions }}
                actionsInfo={{ deleteAction: deleteServiceApi }}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
            >
                <Columnz field="" header="Mã BK" />
                <Columnz field="" header="Kì BK" />
                <Columnz field="" header="Căn Hộ" />
                <Columnz field="" header="Mã căn hộ" />
                <Columnz field="" header="Dịch vụ" />
                <Columnz field="" header="Sản phẩm" />
                <Columnz field="" header="Mã thu" />
                <Columnz field="" header="Đơn giá" />
                <Columnz field="" header="SL" />
                <Columnz field="" header="Tổng" />
                <Columnz field="" header="Giảm trừ" />
                <Columnz field="" header="Thành tiền" />
                <Columnz field="" header="Đã hạch toán" />
                <Columnz field="" header="Còn nợ" />
                <Columnz field="" header="Phiếu thu" />
                <Columnz field="" header="Ngày chốt" />
                <Columnz field="" header="Ngày lập" />
                <Columnz field="" header="Hạn thanh toán" />
                <Columnz field="" header="Thời gian" />
                <Columnz field="" header="Dự án" />
            </DataTablez>
        </div>
    )
}

export default ServicesList
