import { getData, postData } from '@/lib/request'

export const listResidentApi = (params) => getData('web2/resident_management/getListResident', params)
export const countResidentApi = (params) => getData('web2/resident_management/countResident', params)
export const checkResidentApi = (params) => getData('web2/resident_management/residentCheck', params)
export const detailResidentApi = (params) => getData('web2/resident_management/detailResident', params)
export const deleteResidentApi = (params) => postData('web2/resident_management/deleteResidents', params)
export const addResidentApi = (params) => postData('web2/resident_management/createNewResident', params)
export const addOldResidentApi = (params) => postData('web2/resident_management/createOldResident', params)
export const editRoleApi = (params) => postData('web2/resident_management/editRoleROfA', params)
export const deleteRoleApi = (params) => postData('web2/resident_management/deleteRoleROfA', params)
export const addRoleApi = (params) => postData('web2/resident_management/insertRoleRofA', params)
export const updateResidentApi = (params) => postData('web2/resident_management/editDetailResident', params, true)
