import React, { useState } from 'react'
import { Columnz, DataTablez, TimeBody, StatusBody, useGetParams, Body, HeaderListForm } from '@/components'
import { deleteExchangeApi, updateExchangeApi } from '../api'
import { useCountExchange, useListExchange } from '../utils'
import { Dropdownz, GridForm, Inputz } from '@/components'
import { status, units } from '@/constants'
import { useSelector } from 'react-redux'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter}>
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo tên, mã phòng ban"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.type}
                options={units.slice(0, -1)}
                onChange={(e) => setFilter({ ...filter, type: e.target.value })}
                placeholder="Phân loại"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Trạng thái"
            />
        </GridForm>
    )
}

const Exchanges = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListExchange({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountExchange({ status: undefined, ...params, first: undefined }) || 0
    const users = useSelector((state) => state.users)

    return (
        <div className="card">
            <HeaderListForm title="Danh sách phòng ban" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="phòng ban"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/exchange"
                actionsInfo={{ deleteAction: deleteExchangeApi }}
            >
                <Columnz body={(e) => Body(units, e.type)} header="Phân loại" />
                <Columnz field="name" header="Tên phòng ban" />
                <Columnz field="code_department" header="Mã phòng ban" />
                <Columnz field="address" header="Địa chỉ" />
                <Columnz field="phone_number" header="Hotline" />
                <Columnz field="email" header="Email liên hệ" />
                <Columnz body={(e) => Body(users.users, e.main_user_id)} header="Trưởng bộ phận dự án" />
                <Columnz header="Thời gian tạo" body={(e) => TimeBody(e.created_at)} />
                <Columnz header="Thời gian cập nhật" body={(e) => TimeBody(e.updated_at)} />
                <Columnz
                    header="Trạng thái"
                    body={(e) => StatusBody({ e, route: '/exchange', updateAction: updateExchangeApi })}
                />
            </DataTablez>
        </div>
    )
}

export default Exchanges
