import { useEffect, useState } from 'react'
import {
    countApartmentApi,
    detailApartmentApi,
    listApartmentApi,
    listGroupApartmentApi,
    countGroupApartmentApi,
    detailGroupApartmentApi,
    listApartmentV2Api,
    listOwnerApi,
} from '../api'

export const useListApartment = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listApartmentApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useListApartmentV2 = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listApartmentV2Api({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useListOwner = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listOwnerApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountApartment = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countApartmentApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailApartment = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailApartmentApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}

export const useListGroupApartment = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listGroupApartmentApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountGroupApartment = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countGroupApartmentApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailGroupApartment = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailGroupApartmentApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
